import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const link: OverrideThemeFunc<Components> = () => ({
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
    styleOverrides: {
      root: {
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
      },
    },
  },
});

export default link;
