import { SearchOffersRequest } from 'data/api/search';
import { paginationSizeVariant } from 'domain/model/constants';
import { EClientOfferType } from 'domain/model/enums';
import { EAppCategories } from 'presentation/types';

export const searchOffersDefaultParams: SearchOffersRequest = {
  page: 1,
  pageSize: paginationSizeVariant[0],
  sort: null,
  offerType: [EClientOfferType.CorpOffer, EClientOfferType.TradeOffer, EClientOfferType.BookingOffer],
  query: null,
};

export const searchTitleByClientOfferType: Record<EClientOfferType, EAppCategories> = {
  [EClientOfferType.TradeOffer]: EAppCategories.TradeOffers,
  [EClientOfferType.AspOffer]: EAppCategories.AspOffers,
  [EClientOfferType.CorpOffer]: EAppCategories.CorpOffers,
  [EClientOfferType.BookingOffer]: EAppCategories.BookingOffers,
  [EClientOfferType.ProductOffer]: EAppCategories.ProductOffers,
};
