import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import uniqBy from 'lodash.uniqby';
import { isDefined } from 'utils/array';
import { BookingPriceUnit } from 'domain/model';
import BookingOfferDetailsTabServices from '../../components/detailsTabs';
import { bookingOfferDetailsCartSelector } from '../store/selectors';
import { useContextHandlers } from '../hooks/useContextHandlers';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';

const mapServices = ({ category }: BookingPriceUnit) => {
  if (!category) {
    return null;
  }

  const { id, name } = category;
  return { id, name };
};

type BookingDetailsServicesTabProps = {
  readonly id: UUID;
};

const BookingOfferServicesTabsAdapter: FC<BookingDetailsServicesTabProps> = ({ id }) => {
  const [selectedServiceCategory, setSelectedServiceCategory] = useState<Nullable<UUID>>(null);

  const cart = useSelector(bookingOfferDetailsCartSelector);

  const { onChangeServicesSelection } = useContextHandlers();
  const { bookingOffer } = useBookingOfferDetailsData(id);

  const serviceCategories = uniqBy(bookingOffer?.services?.map(mapServices).filter(isDefined) ?? [], 'id');

  const activeServices = selectedServiceCategory
    ? bookingOffer?.services?.filter(({ category }) => category?.id === selectedServiceCategory)
    : bookingOffer?.services;

  return (
    <BookingOfferDetailsTabServices
      services={activeServices}
      serviceCategories={serviceCategories}
      cart={cart?.[id] ?? null}
      selectedId={selectedServiceCategory}
      onSelect={setSelectedServiceCategory}
      onChangeServices={onChangeServicesSelection}
    />
  );
};

export default BookingOfferServicesTabsAdapter;
