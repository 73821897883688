import { DialogActions, DialogContent } from '@mui/material';
import { FCC, ReactNode } from 'react';
import { MPDialogContentBuilder } from 'theme/ui-kit/dialog/common/contentBuilder';
import { DialogTitle } from './controls';

export type DialogueEventContentBuilderLayoutProps = {
  readonly wrapper?: FCC;
  readonly title?: ReactNode;
  readonly actions: ReactNode;
  readonly titleAsContent?: boolean;
};

export const DialogueEventContentBuilderLayout: FCC<DialogueEventContentBuilderLayoutProps> = ({
  wrapper,
  title,
  titleAsContent,
  actions,
  children: content,
}) => {
  return (
    <MPDialogContentBuilder
      wrapper={wrapper}
      title={!titleAsContent && title}
    >
      {(content || (title && titleAsContent)) && (
        <DialogContent>
          {titleAsContent && title && <DialogTitle>{title}</DialogTitle>}
          {content}
        </DialogContent>
      )}
      {actions && <DialogActions>{actions}</DialogActions>}
    </MPDialogContentBuilder>
  );
};
