import { FC } from 'react';
import moment from 'moment-timezone';
import { EDateFormat } from 'domain/model';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { BookingCartItem } from '../../details/types';
import { BookingSlotItem } from './slotItem';
import { EOrderByDateType } from 'domain/model';
import BookingSidebarSlotActions from './slotActions';
import { SlotProps } from './types';

type BookingSidebarSlotMultipleProps = SlotProps & {
  item: BookingCartItem['orderItem'];
  price: number;
};

const BookingSidebarSlotMultiple: FC<BookingSidebarSlotMultipleProps> = ({ item, price, ...actions }) => {
  return (
    <>
      <MPGrid
        container
        spacing={1}
        justifyContent='space-between'
      >
        <BookingSlotItem title='Гостей'>
          {item.slots?.map(({ qty, startDate }) => (
            <div key={`${startDate}-${qty}`}>{qty}</div>
          ))}
        </BookingSlotItem>
        <BookingSlotItem title='Даты'>
          {item.slots?.map(({ startDate }) => (
            <div key={startDate as string}>{moment(startDate).format(EDateFormat.Human)}</div>
          ))}
        </BookingSlotItem>
      </MPGrid>

      <BookingSidebarSlotActions
        unitType={EOrderByDateType.Days}
        price={price}
        slots={item.slots}
        {...actions}
      />
    </>
  );
};

export default BookingSidebarSlotMultiple;
