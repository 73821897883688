import { ButtonProps, Typography, useMediaQuery } from '@mui/material';
import { EOfferStatus, EOfferType } from 'domain/model/enums';
import { ProductAttributeType, ProductOffer } from 'domain/model/productOffer';
import { User } from 'domain/model/user';
import Splitter from 'presentation/components/common/splitter';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useMemo } from 'react';
import CodeTitle from '../../../../components/common/codeTitle/title';
import OfferClaimSubscription from '../../components/claim/subscription';
import useOfferSubscription from '../../hooks/useOfferSubscription';
import ProductOfferCartCounterContainer from '../cartCounter/container';
import ProductOfferCartButtonLabelForDetails from '../components/addToCartButton/labels/forDetails';
import makeMergedAttributes from '../components/attributes/utils/prepareModelData';
import makeGroupedByValueAttributes from '../components/attributes/utils/prepareViewData';
import SidebarAttributes from './components/attributes';
import SidebarInfo from './components/info';
import Prices from './components/prices';
import { BottomCartSection, Wrapper } from './controls';
import { ProductOfferSidebarNsi } from './types';
import { formatOneVariantAttributes } from './utils';

type ProductOfferSidebarProps = {
  readonly user: User;
  readonly cartQuantity: number;
  readonly bottomMenuRect: Nullable<DOMRect>;
  readonly product: ProductOffer;
  readonly nsi: ProductOfferSidebarNsi;
  readonly onShowPaymentDialog: () => void;
  readonly onShowDeliveryDialog: () => void;
  readonly onUpdateCart: (count: number) => void;
};

const ProductOfferSidebar = (props: ProductOfferSidebarProps) => {
  const { bottomMenuRect, product, nsi, onShowPaymentDialog, onShowDeliveryDialog } = props;

  const mergedAttributesCurrentVariant = useMemo(() => {
    if (!product) return null;
    return makeMergedAttributes([product]);
  }, [product]);

  const unitedByValueAttributesCurrentVariant = useMemo(() => {
    if (!mergedAttributesCurrentVariant) {
      return null;
    }

    return makeGroupedByValueAttributes(mergedAttributesCurrentVariant);
  }, [mergedAttributesCurrentVariant]);

  const formattedAttributes = formatOneVariantAttributes(
    unitedByValueAttributesCurrentVariant?.filter(a => a.attribute.type !== ProductAttributeType.file) ?? []
  );

  const { originalPrice, price, salePercent, producerSKU, status } = product;
  const isNotActive = status !== EOfferStatus.Active;

  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { x2 } = useGridSpacing();

  const {
    isEnabled: isSubscriptionEnabled,
    isFetching: isSubscriptionFetching,
    onSubscribe,
    onUnSubscribe,
  } = useOfferSubscription({
    offer: product,
    offerType: EOfferType.ProductOffer,
  });

  const getCartButtonSize = (): ButtonProps['size'] => {
    if (isMdUp) {
      return 'large';
    }

    if (isSmUp) {
      return 'medium';
    }

    return 'small';
  };

  const cartButtonSize = getCartButtonSize();

  const cartButton = useMemo(
    () => (
      <ProductOfferCartCounterContainer
        stretch
        product={product}
        size={cartButtonSize}
        label={ProductOfferCartButtonLabelForDetails}
      />
    ),
    [product, cartButtonSize]
  );

  const subscription = useMemo(
    () => (
      <OfferClaimSubscription
        onSubscribe={onSubscribe}
        onUnSubscribe={onUnSubscribe}
        isFetching={isSubscriptionFetching}
        enabled={isSubscriptionEnabled}
      />
    ),
    [onSubscribe, onUnSubscribe, isSubscriptionFetching, isSubscriptionEnabled]
  );

  const notFound = useMemo(() => <Typography color='warning.main'>Нет в наличии</Typography>, []);

  return (
    <Wrapper>
      <MPGrid
        container
        flexDirection='column'
      >
        <MPGrid item>
          <CodeTitle
            text='Артикул'
            code={producerSKU ?? ''}
          />
        </MPGrid>
        {isMdUp && (
          <>
            <Splitter size={isMdUp ? 1.5 : 3} />
            <SidebarAttributes attributes={formattedAttributes} />
          </>
        )}
        {isNotActive ? null : (
          <>
            <Splitter size={3} />
            <Prices
              price={price}
              salePercent={salePercent}
              originalPrice={originalPrice}
            />
          </>
        )}
        {isMdUp && (
          <MPGrid
            item
            flexDirection='column'
          >
            {isNotActive ? (
              <>
                {notFound}
                <Splitter size={2} />
                {subscription}
              </>
            ) : (
              <>
                <Splitter size={5} />
                {cartButton}
              </>
            )}
          </MPGrid>
        )}
        <Splitter size={3} />
        <SidebarInfo
          product={product}
          paymentTypes={nsi.paymentTypes}
          deliveryTypes={nsi.deliveryTypes}
          onClickPayment={onShowPaymentDialog}
          onClickDelivery={onShowDeliveryDialog}
        />
        {!isMdUp && <Splitter size={2} />}
      </MPGrid>
      {!isMdUp && (
        <BottomCartSection offset={isSmUp ? null : bottomMenuRect?.height}>
          {isNotActive ? (
            <MPGrid
              container
              spacing={x2}
            >
              <MPGrid item>{notFound}</MPGrid>
              <MPGrid item>{subscription}</MPGrid>
            </MPGrid>
          ) : (
            cartButton
          )}
        </BottomCartSection>
      )}
    </Wrapper>
  );
};

export default ProductOfferSidebar;
