import { OfferListRequest } from 'data/api';
import { EBookingOfferSortType, EOfferClientTagType, EOfferType, OfferTag, paginationSizeVariant } from 'domain/model';
import { GetOffersCountQueryParamsProps } from '../types';
import { getOffersListQueryParams } from '../utils';
import { BookingOfferListFilter } from './list/store/types';

export const cspOfferTag: OfferTag = {
  type: EOfferClientTagType.CSP,
  name: 'КСП: Отдых',
};

export const bookingOffersDefaultParams: OfferListRequest & BookingOfferListFilter = {
  ...getOffersListQueryParams(
    {
      name: null,
      categories: null,
      page: 1,
      pageSize: paginationSizeVariant[0],
      sort: [EBookingOfferSortType.Default],
    },
    EOfferType.BookingOffer
  ),
  services: null,
  rzdSocialPackage: false,
  priceRange: {
    minPrice: null,
    maxPrice: null,
  },
};

export const getBookingOffersCountQueryParams = (props: GetOffersCountQueryParamsProps) => {
  const { name, categories } = props;

  return {
    search: {
      name,
      categories,
    },
    minPrice: null,
    maxPrice: null,
  };
};
