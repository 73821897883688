import { BookingOrderItem, BookingPriceUnit } from 'domain/model/bookingOffer';
import { BookingDateSlot } from 'domain/model/order';

export enum EBookingOfferDetailsTab {
  Services = 'services',
  Description = 'description',
  Contacts = 'contacts',
}

export type BookingDetailsId = {
  readonly id: UUID;
};

export type BookingModalSlotItem = Pick<BookingDateSlot, 'qty'> & {
  readonly startDate: Nullable<Date | string>;
  readonly endDate: Nullable<Date | string>;
};

export type BookingCartItem = {
  // Редактирование позиции
  index?: number[];
  // Увеличение или уменьшение
  isIncrement?: boolean;
  // Объект массива услуг
  priceUnit: BookingPriceUnit;
  // Объект для отправки на бэкенд
  orderItem: Omit<BookingOrderItem, 'slots'> & {
    slots: BookingModalSlotItem[];
  };
};

export type Cart = {
  cart: BookingCartItem[];
};

export type UseBookingOfferDetails = {
  readonly onBack: VoidFunction;
  readonly onClearOfferCart: VoidFunction;
  readonly onChangeServicesSelection: (
    props: Pick<BookingCartItem, 'isIncrement' | 'priceUnit'> & {
      priceItemId: UUID;
    }
  ) => void;
  readonly onDeleteService: (index: number[]) => void;
  readonly onUpdateModal: (index: number[]) => void;
  readonly onApplyModalChanges: (props?: BookingCartItem) => void;
  readonly tryHandleOrder: () => void;
  readonly handleOrder: (userPhone: string) => void;
  readonly isOrderCreating: boolean;
  readonly errorCreateOrder: unknown;
  readonly customerComment: string;
  readonly changeCustomerComment: (value: string) => void;
};

export type SidebarProps = {
  readonly customerComment: string;
  readonly isLoading: boolean;
  readonly cart: Nullable<BookingCartItem[]>;
  readonly onOrder: VoidFunction;
  readonly onChangeCustomerComment: (value: string) => void;
};
