import { useAuthentication } from '@mp-npm/mp-auth-client';
import { AppAuthProvider } from 'appProviders';
import HttpClient from 'data/network/http';
import { SecurityToken } from 'domain/model/user';
import SentryClient from 'integration/sentry/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authProviderSetState, authProviderSetToken } from './store/slice';

const InnerProvider = () => {
  const dispatch = useDispatch();

  const { initialized, isAuthenticated, authService, token, onResetPassword } = useAuthentication<SecurityToken>();

  useEffect(() => {
    dispatch(authProviderSetState({ isLoading: !initialized, isAuthenticated }));
  }, [dispatch, initialized, isAuthenticated]);

  useEffect(() => {
    dispatch(authProviderSetToken({ token: authService?.token ?? null, parsed: token }));
  }, [dispatch, authService.token, token]);

  useEffect(() => {
    HttpClient.getInstance().setAuthService(authService, onResetPassword);
    SentryClient.getInstance().setAuthService(authService);
  }, [authService, onResetPassword]);

  return null;
};

const AuthProvider = () => {
  return (
    <AppAuthProvider>
      <InnerProvider />
    </AppAuthProvider>
  );
};

export default AuthProvider;
