import { useUserFeedbackMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { UserFeedBackForm } from 'domain/model';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { getPersonFullName } from 'presentation/utils';
import { FC, useEffect, useState } from 'react';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import ContentLoader from '../../../components/common/loader';
import useValidation from '../../../hooks/validation/useValidation';
import { MPButton } from '../../../theme/ui-kit/button';
import { OnChangeObjectAttribute } from '../../../types';
import AboutFeedBack from './component';
import AboutFeedbackLayout from './layout';
import SuccessFeedBack from './success';
import { userFeedBackValidation } from './validation';

const Layout = AboutFeedbackLayout;

type AboutFeedbackContainerProps = {
  readonly open: boolean;
  readonly onClose: () => void;
};

const emptyData: UserFeedBackForm = { name: '', email: '', message: '', attachment: null };

const AboutFeedbackContainer: FC<AboutFeedbackContainerProps> = props => {
  const { open, onClose } = props;

  const { user } = useAuthUser();

  const [feedBackData, setFeedBackData] = useState<UserFeedBackForm>(emptyData);
  const [validateOnChangeFeedBack, setValidateOnChangeFeedBack] = useState<boolean>(false);

  const [userFeedback, { error: userFeedbackError, isSuccess: isSent, isLoading: isExecuting, reset: resetSent }] =
    useUserFeedbackMutation();

  const { validationResult, validate, resetValidationResult } = useValidation<UserFeedBackForm>({
    object: feedBackData,
    validateOnChange: validateOnChangeFeedBack,
    rules: userFeedBackValidation,
  });

  const onCloseInternal = () => {
    setValidateOnChangeFeedBack(false);
    setFeedBackData(emptyData);
    resetSent();
    resetValidationResult();
    onClose();
  };

  const onChangeAttribute: OnChangeObjectAttribute<UserFeedBackForm> = (name, value) => {
    setFeedBackData(prevFeedBackData => {
      return prevFeedBackData ? { ...prevFeedBackData, [name]: value } : prevFeedBackData;
    });
  };

  const onSend = () => {
    if (!validate()) {
      setValidateOnChangeFeedBack(true);
    } else {
      userFeedback(feedBackData);
    }
  };

  useEffect(() => {
    if (userFeedbackError) {
      ErrorHandler.handleHttpError(userFeedbackError);
    }
  }, [userFeedbackError]);

  useEffect(() => {
    if (open) {
      if (user) {
        setFeedBackData({ ...emptyData, name: getPersonFullName(user), email: user.email });
      } else {
        setFeedBackData({ ...emptyData, name: '', email: '' });
      }
    }
  }, [open, user]);

  useEffect(() => {
    if (isSent) {
      setValidateOnChangeFeedBack(false);
      setFeedBackData(emptyData);
      onClose();
    }
  }, [isSent, onClose]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <Layout
        open={open}
        title='Форма обратной связи'
        onClose={onCloseInternal}
        buttons={
          <ConfirmGroupButtons>
            <MPButton
              fullWidth
              color='brand'
              size='large'
              disabled={isExecuting}
              onClick={onSend}
            >
              Отправить
              {isExecuting && <ContentLoader />}
            </MPButton>
            <MPButton
              color='brand'
              fullWidth
              size='large'
              variant='text'
              disabled={isExecuting}
              onClick={onCloseInternal}
            >
              Отмена
            </MPButton>
          </ConfirmGroupButtons>
        }
      >
        <AboutFeedBack
          data={feedBackData}
          isSending={isExecuting}
          validation={validationResult}
          onChangeAttribute={onChangeAttribute}
        />
      </Layout>

      <SuccessFeedBack
        open={isSent}
        onClose={resetSent}
      />
    </>
  );
};

export default AboutFeedbackContainer;
