import axios, { CancelTokenSource } from 'axios';
import addressApi from 'data/openApi/address';
import { Address, AddressLink } from 'domain/model/address';
import { EAddressLevel } from 'domain/model/enums';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import ErrorHandler from '../../../../data/network/errorHandler';

type UseAddressSelectorProps = {
  readonly count?: number;
  readonly level?: EAddressLevel;
  readonly fromLevel?: EAddressLevel;
  readonly toLevel?: EAddressLevel;
  readonly locations?: AddressLink[];
  readonly onlyValidPostalCode?: boolean;
  readonly filterBy?: (address: Address) => boolean;
  readonly sortBy?: (address1: Address, address2: Address) => number;
};

type UseAddressSelectorResult = {
  readonly suggestions: Address[];
  readonly isLoading: boolean;
  readonly onSearch: (value: string) => void;
};

const useAddressSelector = (props: UseAddressSelectorProps): UseAddressSelectorResult => {
  const {
    count,
    onlyValidPostalCode,
    level,
    fromLevel = EAddressLevel.Value1,
    toLevel = EAddressLevel.Value11,
    locations,
    filterBy,
    sortBy,
  } = props;

  const [suggestions, setSuggestions] = useState<Address[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSearch = debounce(newValue => setSearch(newValue), 500);

  useEffect(() => {
    if (search) {
      const cancelCallback: CancelTokenSource = axios.CancelToken.source();
      const cancelToken = cancelCallback.token;

      setIsLoading(true);
      addressApi
        .addressQuery({ query: search, count, level, fromLevel, toLevel, locations, cancelToken })
        .then(({ data }) => {
          let newSuggestions = data.filter(item => !onlyValidPostalCode || item.postalCode !== null).map(item => item);

          if (filterBy) {
            newSuggestions = newSuggestions.filter(filterBy);
          }
          if (sortBy) {
            newSuggestions = newSuggestions.sort(sortBy);
          }

          setSuggestions(newSuggestions);
        })
        .catch(e => {
          ErrorHandler.handleHttpError(e, e.response);
        })
        .finally(() => {
          setIsLoading(false);
        });

      return () => {
        cancelCallback.cancel();
      };
    }
  }, [count, filterBy, fromLevel, level, locations, onlyValidPostalCode, search, sortBy, toLevel]);

  return {
    suggestions,
    isLoading,
    onSearch,
  };
};

export default useAddressSelector;
