import styled from '@emotion/styled';
import { css, DialogTitle, DialogTitleProps, IconButton } from '@mui/material';

type StyledDialogTitleProps = {
  readonly compact: boolean;
};

const DialogButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    top: 0;

    color: ${theme.palette.text.primary};
  `
);

export const CloseButton = styled(DialogButton)(
  () => css`
    right: 0;
    z-index: 2;
  `
);

export const InnerWrapper = styled.div(
  () => css`
    width: 100%;
    height: inherit;

    overflow-y: auto;

    display: flex;
    flex-direction: column;
  `
);

export const StyledDialogTitle = styled(({ compact, ...others }: DialogTitleProps & StyledDialogTitleProps) => (
  <DialogTitle {...others} />
))(
  ({ compact, theme }) => css`
    padding-top: ${compact ? theme.spacing(2) : theme.spacing(5)};
  `
);
