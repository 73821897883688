import { FormControlLabel, Radio } from '@mui/material';
import { useNsiCancelOrderCustomerTypesQuery } from 'data/api/nsi';
import { EOfferType } from 'domain/model/enums';
import ContentLoader from 'presentation/components/common/loader';
import { MPFormTextArea } from 'presentation/theme/ui-kit/input';
import { useEffect, useMemo, useState } from 'react';
import Splitter from '../../../components/common/splitter';
import { MPButton } from '../../../theme/ui-kit/button';
import { MPGrid } from '../../../theme/ui-kit/grid';
import { ModalContentWrapper, WrapperModalProps } from './contentWrapper';
import { RadioHeader, RadioList } from './controls';

type CancelModalProps = WrapperModalProps & {
  readonly isFetching: boolean;
  readonly offerType?: EOfferType;
  readonly onCancelOrder: (id: UUID, comment: string) => void;
};

export const CancelOrderModal: React.FCC<CancelModalProps> = ({
  isFetching,
  isOpen,
  offerType,
  onClose,
  onCancelOrder,
}) => {
  const [radioValue, setRadioValue] = useState<UUID | null>(null);
  const [comment, setComment] = useState('');

  const { data: radioList } = useNsiCancelOrderCustomerTypesQuery();

  const filteredRadioList = radioList?.filter(
    ({ offerType: radioType }) => !offerType || !radioType || radioType === offerType
  );

  const isShowCommentInput = useMemo(() => {
    const index = filteredRadioList?.findIndex(radio => radio.id === radioValue);

    return filteredRadioList && index === filteredRadioList.length - 1;
  }, [radioValue, filteredRadioList]);

  const isCancelDisabled = !radioValue || (isShowCommentInput && !comment) || isFetching;

  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue(e.target.value);
  };

  const onChangeComment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const onCancelOrderClick = () => {
    if (radioValue) onCancelOrder(radioValue, isShowCommentInput ? comment : '');
  };

  useEffect(() => {
    // clear state when modal closing
    if (!isOpen) {
      setRadioValue(null);
      setComment('');
    }
  }, [isOpen]);

  return (
    <ModalContentWrapper
      isOpen={isOpen}
      onClose={onClose}
    >
      <RadioHeader variant='body1'>Укажите причину отмены:</RadioHeader>

      <RadioList
        value={radioValue}
        onChange={onRadioChange}
        id='radio-error-text'
      >
        {filteredRadioList?.map(radio => (
          <FormControlLabel
            control={<Radio color='primary' />}
            value={radio.id}
            label={radio.name}
            key={radio.id}
          />
        ))}
      </RadioList>

      {isShowCommentInput && (
        <>
          <Splitter size={1} />
          <MPFormTextArea
            label='Укажите причину'
            helperText={!comment ? 'Причина не может быть пустой' : ''}
            slotProps={{ htmlInput: { maxLength: 1000 } }}
            multiline
            rows={4}
            value={comment}
            onChange={onChangeComment}
          />
        </>
      )}

      <Splitter size={4} />

      <MPGrid
        container
        spacing={2}
        flexDirection='column'
      >
        <MPGrid item>
          <MPButton
            fullWidth
            size='large'
            color='brand'
            disabled={isCancelDisabled}
            onClick={onCancelOrderClick}
          >
            Подтвердить отмену заказа
            {isFetching && <ContentLoader size={20} />}
          </MPButton>
        </MPGrid>
        <MPGrid item>
          <MPButton
            fullWidth
            size='large'
            variant='text'
            color='brand'
            disabled={isFetching}
            onClick={onClose}
          >
            Нет
          </MPButton>
        </MPGrid>
      </MPGrid>
    </ModalContentWrapper>
  );
};
