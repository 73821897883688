import { Text } from '../../components/controls';

const AboutCspHaveToPayInfo = () => {
  return (
    <Text>
      С каждой второй и последующей путёвок в рамках одного года, работодатель удержит из вашей зарплаты 13% налог от
      размера компенсации.
    </Text>
  );
};

export default AboutCspHaveToPayInfo;
