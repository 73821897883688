import { Typography } from '@mui/material';
import { EPaymentType } from 'domain/model/enums';
import { FC } from 'react';
import ClaimMessage from '../message';

type OfferClaimBalanceNotEnoughProps = {
  readonly paymentType: EPaymentType;
};

export const OfferClaimBalanceNotEnough: FC<OfferClaimBalanceNotEnoughProps> = ({ paymentType }) => {
  let currencyLabel = 'баланса';
  switch (paymentType) {
    case EPaymentType.AspRzdRub:
      currencyLabel = 'рублей АСП';
      break;
    case EPaymentType.BenefitRzdPoint:
      currencyLabel = 'баллов';
      break;
    case EPaymentType.Rub:
    case EPaymentType.Free:
      break;
  }

  return (
    <ClaimMessage type='warn'>
      <Typography
        variant='body2'
        color='black'
      >
        У вас недостаточно {currencyLabel}
      </Typography>
    </ClaimMessage>
  );
};
