import { Text } from '../../components/controls';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from './controls';
import { getCspOffersListRoute } from 'features/offer/csp/routes';

const AboutCspWhatObjectsInfo = () => {
  const linkObjects = useMemo(
    () => (
      <RouterLink
        target='_blank'
        to={getCspOffersListRoute()}
      >
        <Link
          underline={'none'}
          color='primary'
        >
          по ссылке
        </Link>
      </RouterLink>
    ),
    []
  );

  return <Text>Вы можете выбрать один из объектов перейдя {linkObjects}.</Text>;
};

export default AboutCspWhatObjectsInfo;
