import { Tab, useMediaQuery } from '@mui/material';
import { AspOffer } from 'domain/model/aspOffer';
import { PartnerDesk } from 'domain/model/partner';
import { TabsWrapper } from 'presentation/components/common/wrappers/tabs';
import { PartnerDeskDetailsTabAboutCompany } from 'presentation/features/partnerDesk/details/tabs';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OfferDetailsTabContacts from '../../../components/detailsTabs/contacts';
import OfferDetailsTabDescription from '../../../components/detailsTabs/description';
import { OfferTabs } from '../../../components/tabs/controls';
import { EAspOfferDetailsTab } from '../utils';

type AspOfferTabsProps = {
  readonly tab: EAspOfferDetailsTab;
  readonly onChange: (event: React.SyntheticEvent, newTab: EAspOfferDetailsTab) => void;
  readonly aspOffer: AspOffer;
  readonly partnerDesk: PartnerDesk;
};

const AspOfferTabs = (props: AspOfferTabsProps) => {
  const { onChange, tab, partnerDesk, aspOffer } = props;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <MPGrid
      container
      spacing={isMdUp ? 3 : 2}
    >
      <MPGrid
        item
        xs
      >
        <TabsWrapper>
          <OfferTabs
            value={tab}
            textColor='secondary'
            variant='scrollable'
            onChange={onChange}
            scrollButtons={false}
          >
            <Tab
              label='Описание'
              value={EAspOfferDetailsTab.Description}
            />
            {partnerDesk.contacts && partnerDesk.contacts?.length > 0 && (
              <Tab
                label='Контакты'
                value={EAspOfferDetailsTab.Contacts}
              />
            )}
            {partnerDesk.aboutCompany && (
              <Tab
                label='О компании'
                value={EAspOfferDetailsTab.AboutCompany}
              />
            )}
          </OfferTabs>
        </TabsWrapper>
      </MPGrid>
      <MPGrid
        item
        zero={12}
      >
        {tab === EAspOfferDetailsTab.Description && <OfferDetailsTabDescription description={aspOffer.description} />}
        {tab === EAspOfferDetailsTab.Contacts && partnerDesk.contacts && (
          <OfferDetailsTabContacts contacts={partnerDesk.contacts} />
        )}
        {tab === EAspOfferDetailsTab.AboutCompany && partnerDesk.aboutCompany && (
          <PartnerDeskDetailsTabAboutCompany about={partnerDesk.aboutCompany} />
        )}
      </MPGrid>
    </MPGrid>
  );
};

export default AspOfferTabs;
