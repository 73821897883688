import { Text } from '../../components/controls';
const AboutCspCommonInfo = () => {
  return (
    <>
      <Text>
        Это элемент социального пакета, предоставляемый с целью повышения привлекательности рабочих мест и мотивации
        работников к труду. КСП предоставляет работнику возможность компенсировать в пределах определенного лимита свои
        затраты на социальные услуги, включенные в перечень, утвержденный ОАО «РЖД».
      </Text>
    </>
  );
};

export default AboutCspCommonInfo;
