import AboutTextSectionsContainer from '../textSections/container';
import AboutCspCommonInfo from './info/common';
import AboutCspWhatIsItInfo from './info/whatIsIt';
import AboutCspHowIsFamilyInfo from './info/howIsFamily';
import AboutCspWhatCategoryInfo from './info/whatCategory';
import AboutCspWhatNeedDoInfo from './info/whatNeedDo';
import AboutCspWhatSizeCostInfo from './info/whatSizeCost';
import AboutCspCanIGetInfo from './info/canIGet';
import AboutCspWhatObjectsInfo from './info/whatObjects';
import AboutCspWhenSubmitInfo from './info/whenSubmit';
import AboutCspIsThereTaxInfo from './info/IsThereTax';
import { NumberedText } from '../components/textNumber';
import AboutCspHaveGettingInfo from './info/iHaveGetting';
import AboutCspHowOftenUpdateInfo from './info/howOftenUpdate';
import AboutCspCanIGetPayoutInfo from './info/canIGetPayout';
import AboutCspCanReceivingKSPInfo from './info/canReceivingKSP';
import AboutCspHaveToPayInfo from './info/haveToPay';
import AboutCspHowCanILoseInfo from './info/howСanIlose';

enum ESection {
  Common = 'common',
  WhatIsIt = 'WhatIsIt',
  HowIsFamily = 'HowIsFamily',
  WhatCategory = 'WhatCategory',
  WhatNeedDo = 'WhatNeedDo',
  WhatSizeCost = 'WhatSizeCost',
  CanIGet = 'CanIGet',
  WhatObjects = 'whatObjects',
  WhenSubmit = 'WhenSubmit',
  IsThereTax = 'IsThereTax',
  IHaveGetting = 'IHaveGetting',
  HowOftenUpdate = 'HowOftenUpdate',
  CanIGetPayout = 'CanIGetPayout',
  CanReceivingKSP = 'CanReceivingKSP',
  HaveToPay = 'HaveToPay',
  HowCanILose = 'HowCanILose',
}

const AboutCspContainer = () => {
  return (
    <AboutTextSectionsContainer
      title='Компенсируемый социальный пакет'
      sections={[
        {
          id: ESection.Common,
          name: <NumberedText number={1}>Что такое компенсируемый социальный пакет</NumberedText>,
          component: AboutCspCommonInfo,
        },
        {
          id: ESection.WhatIsIt,
          name: <NumberedText number={2}>Что такое КСП на оздоровление и отдых?</NumberedText>,
          component: AboutCspWhatIsItInfo,
        },
        {
          id: ESection.HowIsFamily,
          name: (
            <NumberedText number={3}>
              Кто относится к членам семьи работника при предоставлении КСП на оздоровление?
            </NumberedText>
          ),
          component: AboutCspHowIsFamilyInfo,
        },
        {
          id: ESection.WhatCategory,
          name: (
            <NumberedText number={4}>
              Какой категории работников предоставляется КСП на оздоровление и отдых?
            </NumberedText>
          ),
          component: AboutCspWhatCategoryInfo,
        },
        {
          id: ESection.WhatNeedDo,
          name: (
            <NumberedText number={5}>
              Что нужно сделать, чтобы получить возможность компенсировать путевку на оздоровление по КСП?
            </NumberedText>
          ),
          component: AboutCspWhatNeedDoInfo,
        },
        {
          id: ESection.WhatSizeCost,
          name: <NumberedText number={6}>Какой размер стоимости путевки мне могут компенсировать?</NumberedText>,
          component: AboutCspWhatSizeCostInfo,
        },
        {
          id: ESection.CanIGet,
          name: (
            <NumberedText number={7}>
              Если я получаю КСП на оздоровление и отдых, могу ли я получить КСП по другим категориям?
            </NumberedText>
          ),
          component: AboutCspCanIGetInfo,
        },
        {
          id: ESection.WhatObjects,
          name: <NumberedText number={8}>На какие объекты я могу поехать с семьей отдыхать?</NumberedText>,
          component: AboutCspWhatObjectsInfo,
        },
        {
          id: ESection.WhenSubmit,
          name: (
            <NumberedText number={9}>
              Когда надо подавать заявление на путевку работнику и члену его семьи?
            </NumberedText>
          ),
          component: AboutCspWhenSubmitInfo,
        },
        {
          id: ESection.IsThereTax,
          name: <NumberedText number={10}>Взимается ли 13% налога с путевки?</NumberedText>,
          component: AboutCspIsThereTaxInfo,
        },

        {
          id: ESection.IHaveGetting,
          name: (
            <NumberedText number={11}>
              Если я уже получаю другой вид КСП, могу ли я получить компенсацию на оздоровление и отдых?
            </NumberedText>
          ),
          component: AboutCspHaveGettingInfo,
        },
        {
          id: ESection.HowOftenUpdate,
          name: <NumberedText number={12}>Как часто обновляется список доступных мест отдыха на Витрине?</NumberedText>,
          component: AboutCspHowOftenUpdateInfo,
        },
        {
          id: ESection.CanIGetPayout,
          name: <NumberedText number={13}>Могу ли я одновременно получать КСП и государственные выплаты?</NumberedText>,
          component: AboutCspCanIGetPayoutInfo,
        },
        {
          id: ESection.CanReceivingKSP,
          name: (
            <NumberedText number={14}>Может ли получение КСП повлиять на размер выплат от государства?</NumberedText>
          ),
          component: AboutCspCanReceivingKSPInfo,
        },
        {
          id: ESection.HaveToPay,
          name: (
            <NumberedText number={15}>
              Нужно ли платить налог при получении компенсации на оздоровление и отдых?
            </NumberedText>
          ),
          component: AboutCspHaveToPayInfo,
        },
        {
          id: ESection.HowCanILose,
          name: <NumberedText number={16}>По каким причинам я могу потерять право на КСП?</NumberedText>,
          component: AboutCspHowCanILoseInfo,
        },
      ]}
    />
  );
};

export default AboutCspContainer;
