import { CorpOfferShort } from 'domain/model/corpOffer';
import { InfoWrapper } from './controls';
import { LogoWrapper } from '../../../components/listItem';
import { CardCaption } from '../../../components/listItem/controls';
import { LogoImageView } from 'components/common/images/cover';

type CorpOfferListItemInfoProps = Pick<CorpOfferShort, 'partner'>;

const CorpOfferListItemInfo = (props: CorpOfferListItemInfoProps) => {
  const { partner } = props;

  const { name: partnerName, logo } = partner ?? {};

  return (
    <InfoWrapper>
      <CardCaption
        variant='body2'
        color='textSecondary'
      >
        {partnerName}
      </CardCaption>
      {logo?.path && (
        <LogoWrapper>
          <LogoImageView id={logo.path} />
        </LogoWrapper>
      )}
    </InfoWrapper>
  );
};

export default CorpOfferListItemInfo;
