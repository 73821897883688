import { ESocialPackageStatus } from 'domain/model';
import { CspAccountBalance } from 'domain/model/user';
import { BalanceSlotMainBar } from 'features/user/details/finances/layout/controls';
import { FC } from 'react';
import { UserFinancesBalanceLayout } from '../layout';
import {
  UserFinanceBalanceSlotActionsCspAdapter,
  UserFinanceBalanceSlotTitleAdapter,
  UserFinanceBalanceSlotValueAdapter,
} from './balanceSlot';
import { UserFinanceBalanceSlotExpireCspAdapter } from './balanceSlot/expireCsp';

type UserFinanceBalanceCspAdapterProps = {
  readonly balance: CspAccountBalance;
};

export const UserFinanceBalanceCspAdapter: FC<UserFinanceBalanceCspAdapterProps> = props => {
  const { balance } = props;

  const isDisabled = balance.source.status === ESocialPackageStatus.Upcoming;

  return (
    <UserFinancesBalanceLayout
      slots={[
        <UserFinanceBalanceSlotExpireCspAdapter
          key='expire'
          balance={balance}
          disabled={isDisabled}
        />,
        <BalanceSlotMainBar key='mainBar'>
          <UserFinanceBalanceSlotTitleAdapter
            balance={balance}
            disabled={isDisabled}
          />
          <UserFinanceBalanceSlotValueAdapter
            balance={balance}
            disabled={isDisabled}
          />
          <UserFinanceBalanceSlotActionsCspAdapter balance={balance} />
        </BalanceSlotMainBar>,
      ]}
    />
  );
};
