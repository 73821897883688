import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FC, useMemo } from 'react';
import { getPartnerPersonalAccountUrl, getSupportEmail } from '../../../../utils';
import Feedback from './feedback';
import HeaderLink from './link';

export enum EHeaderLink {
  Products = 'products',
  Partner = 'partner',
  Support = 'support',
  Feedback = 'feedback',
}

type HeaderLinks = {
  readonly isAuthenticated?: boolean;
  readonly reverseDirection?: boolean;
};

const HeaderLinks: FC<HeaderLinks> = ({ isAuthenticated, reverseDirection }) => {
  const linkComponents = useMemo(() => {
    const result = [];

    if (isAuthenticated && getPartnerPersonalAccountUrl()) {
      result.push({
        type: EHeaderLink.Partner,
        component: (
          <HeaderLink
            text='Личный кабинет партнёра'
            target='_blank'
            href={getPartnerPersonalAccountUrl()}
            component='a'
          />
        ),
      });
    }

    if (getSupportEmail()) {
      result.push({
        type: EHeaderLink.Support,
        component: (
          <HeaderLink
            text={getSupportEmail()}
            href={`mailto:${getSupportEmail()}`}
            target='_blank'
            component='a'
          />
        ),
      });
    }

    if (isAuthenticated) {
      result.push({
        type: EHeaderLink.Feedback,
        component: <Feedback />,
      });
    }

    return reverseDirection ? result.reverse() : result;
  }, [isAuthenticated, reverseDirection]);

  return (
    <MPGrid
      container
      spacing={4}
      alignItems='center'
    >
      {linkComponents.map(({ component }, index) => (
        <MPGrid
          key={index}
          item
        >
          {component}
        </MPGrid>
      ))}
    </MPGrid>
  );
};

export default HeaderLinks;
