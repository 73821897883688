import { useGetUserBannersQuery } from 'data/api/user';
import { EBannerPartition, EBannerPlace } from 'domain/model/enums';
import CorpLinkProxy from 'presentation/components/common/links/proxy';
import BannersMainTopLayout from 'presentation/features/banners/mainTop/layout';
import { ArrowLeftIcon, ArrowRightIcon } from 'presentation/media/icons';
import { normalizedLink } from 'presentation/utils';
import { useRef } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import useUserLocalSettings from '../../../hooks/useUserLocalSettings';
import { bannersAutoplayDelay, bannersPerView, bannersSpaceBetween, getBannerTopPlaceByPartition } from '../utils';
import { ButtonNext, ButtonPrev, Carousel, Image, ImageWrapper } from './controls';
import { useMediaQuery } from '@mui/material';

type BannersMainTopContainerProps = {
  readonly guid: UUID;
  readonly partition: EBannerPartition;
};

const Layout = BannersMainTopLayout;

const BannersMainTopContainer = ({ guid, partition }: BannersMainTopContainerProps) => {
  const place: EBannerPlace = getBannerTopPlaceByPartition(partition);

  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const buttonPrevRef = useRef<Nullable<HTMLButtonElement>>(null);
  const buttonNextRef = useRef<Nullable<HTMLButtonElement>>(null);

  const {
    settings: { city },
  } = useUserLocalSettings();
  const cityId: Nullable<UUID> = city?.fiasId ?? null;

  const { data: banners } = useGetUserBannersQuery(
    { guid, place, cityId },
    {
      refetchOnMountOrArgChange: false,
    }
  );

  const isBannersEmpty = !banners || banners.length === 0;

  return !isBannersEmpty ? (
    <Layout>
      <Carousel
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={bannersSpaceBetween}
        slidesPerView={bannersPerView}
        loop={banners.length > 1}
        autoplay={{
          delay: bannersAutoplayDelay,
        }}
        navigation={{
          prevEl: buttonPrevRef.current,
          nextEl: buttonNextRef.current,
        }}
        pagination={{
          clickable: false,
        }}
        onResize={swiper => {
          swiper.navigation.init();
        }}
      >
        {banners.map(({ id, url, desktopFormatImage, name }) => (
          <SwiperSlide key={id}>
            <CorpLinkProxy link={url ? normalizedLink(url) : ''}>
              {linkByProxy => (
                <ImageWrapper
                  target='_blank'
                  href={linkByProxy}
                >
                  {desktopFormatImage && (
                    <Image
                      src={desktopFormatImage.path}
                      alt={name}
                    />
                  )}
                </ImageWrapper>
              )}
            </CorpLinkProxy>
          </SwiperSlide>
        ))}
        {banners.length > 1 && isLgUp && (
          <>
            <ButtonPrev ref={buttonPrevRef}>
              <ArrowLeftIcon
                color='secondary'
                fontSize='large'
              />
            </ButtonPrev>
            <ButtonNext ref={buttonNextRef}>
              <ArrowRightIcon
                color='secondary'
                fontSize='large'
              />
            </ButtonNext>
          </>
        )}
      </Carousel>
    </Layout>
  ) : null;
};

export default BannersMainTopContainer;
