import { Dispatch, FC, SetStateAction } from 'react';
import { Divider } from '@mui/material';
import moment from 'moment-timezone';
import { isDefined } from 'utils/array';
import { EDateFormat } from 'domain/model';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPMultipleCalendarPicker } from 'presentation/theme/ui-kit/picker';
import CountInput from 'presentation/components/common/countInput';
import Splitter from 'presentation/components/common/splitter';
import { BookingModalSlotItem } from '../../details/types';
import { countWidth, TableHeader } from './constants';

const mapDates = ({ startDate }: BookingModalSlotItem) => startDate as Date;

type BookingOfferModalMultipleProps = {
  readonly slots: BookingModalSlotItem[];
  readonly setSlots: Dispatch<SetStateAction<BookingModalSlotItem[]>>;
};

const BookingOfferModalMultiple: FC<BookingOfferModalMultipleProps> = ({ slots, setSlots }) => {
  const dates = slots.map(mapDates);

  const handleChangeDates = (dates: Nullable<Date[]>) => {
    setSlots(
      state =>
        dates?.map(date => {
          const existedValue = state.find(
            ({ startDate }) => moment(startDate).format(EDateFormat.Server) === moment(date).format(EDateFormat.Server)
          );
          return existedValue || { startDate: date, endDate: date, qty: 1 };
        }) ?? []
    );
  };

  const handleChangeCount = (index: number) => {
    return (qty: number) => {
      setSlots(state => {
        return state
          .map((slot, stateIndex) => {
            if (stateIndex !== index) return slot;
            return qty ? { ...slot, qty } : null;
          })
          .filter(isDefined);
      });
    };
  };

  return (
    <>
      <MPMultipleCalendarPicker
        limitPastSelection
        showOtherDays={false}
        value={dates}
        onChange={handleChangeDates}
      />

      {!!dates.length && (
        <>
          {TableHeader}
          {slots?.map(({ startDate, qty }, index) => {
            const date = moment(startDate).format(EDateFormat.Human);
            const onChange = handleChangeCount(index);

            return (
              <>
                <MPGrid
                  key={date}
                  container
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <MPGrid item>{date}</MPGrid>
                  <MPGrid
                    item
                    width={countWidth}
                  >
                    <CountInput
                      isInputDisabled
                      min={0}
                      size='micro'
                      value={qty}
                      onIncrement={onChange}
                      onDecrement={onChange}
                    />
                  </MPGrid>
                </MPGrid>
                <Splitter size={1.5} />
                <MPGrid
                  item
                  xs={12}
                >
                  <Divider />
                </MPGrid>
                {index < slots.length && <Splitter size={1.5} />}
              </>
            );
          })}
        </>
      )}
    </>
  );
};

export default BookingOfferModalMultiple;
