import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getBaseEndpoint } from 'openApi/utils';

export const getQueryErrorByAxiosError = (axiosError: unknown) => {
  const err = axiosError as AxiosError;
  return {
    data: undefined,
    error: {
      status: err.response?.status,
      data: err.response?.data,
      request: { responseURL: err.request?.responseURL },
    },
  };
};

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, AxiosResponse> =>
  async ({ baseURL, url, method, params, data, headers, transformResponse, signal: originalSignal }, { signal }) => {
    const baseUrl = baseURL ?? getBaseEndpoint();
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        params,
        data,
        headers,
        transformResponse,
        signal: originalSignal ?? signal,
      });
      return {
        data: result,
      };
    } catch (axiosError) {
      return getQueryErrorByAxiosError(axiosError);
    }
  };
