import ErrorHandler from 'data/network/errorHandler';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BookingSidebar from '../../components/sidebar';
import UserPrompt from '../../components/userPrompt';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOfferDetailsCartSelector } from '../store/selectors';

type BookingOfferSidebarAdapterProps = {
  readonly id: UUID;
};

const BookingOfferSidebarAdapter: FC<BookingOfferSidebarAdapterProps> = ({ id }) => {
  const { bookingOffer } = useBookingOfferDetailsData(id);

  const [confirmDeleteId, setConfirmDeleteId] = useState([-1]);

  const cart = useSelector(bookingOfferDetailsCartSelector);

  const {
    onUpdateModal,
    onDeleteService,
    isOrderCreating,
    errorCreateOrder,
    customerComment,
    tryHandleOrder,
    changeCustomerComment,
  } = useContextHandlers();

  useEffect(() => {
    if (errorCreateOrder) {
      ErrorHandler.handleHttpError(errorCreateOrder);
    }
  }, [errorCreateOrder]);

  if (!bookingOffer) {
    return null;
  }

  const handleDeleteItem = (index: number[]) => {
    setConfirmDeleteId([-1]);
    onDeleteService(index);
  };

  return (
    <>
      <BookingSidebar
        isLoading={isOrderCreating}
        offerId={bookingOffer.code}
        cart={cart?.[id] ?? null}
        customerComment={customerComment}
        onSelectItem={onUpdateModal}
        onDeleteItem={setConfirmDeleteId}
        onOrder={tryHandleOrder}
        onChangeCustomerComment={changeCustomerComment}
      />

      <UserPrompt
        isVisible={confirmDeleteId[0] > -1}
        title='Удалить услугу?'
        text='Услуга будет удалена из корзины'
        onProceed={() => handleDeleteItem(confirmDeleteId)}
        onCancel={() => setConfirmDeleteId([-1])}
      />
    </>
  );
};

export default BookingOfferSidebarAdapter;
