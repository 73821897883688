import { IconProps, Typography, TypographyProps } from '@mui/material';
import { EBalanceType } from 'domain/model/enums';
import { getCspRubCurrencyLabel } from 'features/user/utils';
import { PaymentIcon } from 'presentation/components/common/paymentIcon';
import { toCurrency, toRubCurrency } from 'presentation/utils/currency';
import { FC } from 'react';
import { Wrapper } from './controls';

type UserBalanceShortProps = Pick<TypographyProps, 'variant' | 'component' | 'onClick' | 'color'> & {
  readonly type: EBalanceType;
  readonly value: Nullable<number>;
  readonly hideIcon?: boolean;
  readonly disabled?: boolean;
};

export const UserBalanceValue: FC<UserBalanceShortProps> = ({
  value,
  type,
  hideIcon,
  onClick,
  disabled = false,
  ...others
}) => {
  let func = toCurrency;
  switch (type) {
    case EBalanceType.Bonuses:
      func = toCurrency;
      break;
    case EBalanceType.ASP:
      func = toRubCurrency;
      break;
    case EBalanceType.CSP:
      func = getCspRubCurrencyLabel;
      break;
  }

  let iconSize: IconProps['fontSize'];
  switch (others.variant) {
    case 'h2':
      iconSize = 'medium';
      break;
    default:
      iconSize = 'small';
      break;
  }

  return (
    value !== null && (
      <Wrapper onClick={onClick}>
        {!hideIcon && (
          <PaymentIcon
            color={disabled ? 'secondary' : 'inherit'}
            type={type}
            fontSize={iconSize}
          />
        )}
        <Typography
          variant='body2'
          color={disabled ? 'secondary' : 'inherit'}
          component='div'
          {...others}
        >
          {func(value)}
        </Typography>
      </Wrapper>
    )
  );
};
