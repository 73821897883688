import { Typography } from '@mui/material';
import { EOrderByDateType } from 'domain/model';
import { EDateFormat } from 'domain/model';
import moment from 'moment-timezone';
import AccordionWithIcon from 'presentation/components/common/accordionWithIcon';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { toRubCurrency } from 'presentation/utils/currency';
import { FC, useEffect, useState } from 'react';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { BookingCartItem, BookingModalSlotItem } from '../../details/types';
import BookingOfferModalMultiple from './calendarMultiple';
import BookingOfferModalNumber from './calendarNumber';
import BookingOfferModalPeriod from './calendarPeriod';
import { DaysHelperText, PeriodHelperText } from './constants';
import { PriceWrapper, StyledConfirmDialog } from './controls';

type BookingOfferCalendarItemProps = Omit<BookingCartItem, 'isIncrement'> & {
  readonly isVisible: boolean;
  readonly onClose: (props?: BookingCartItem) => void;
};

const BookingOfferModal: FC<BookingOfferCalendarItemProps> = ({ isVisible, index, orderItem, priceUnit, onClose }) => {
  const { name, priceItems, orderByDateType } = priceUnit || {};
  const { priceItem, qty, slots } = orderItem || {};

  const [calendarSlots, setCalendarSlots] = useState<BookingModalSlotItem[]>(
    slots?.map(({ startDate, endDate, qty }) => ({
      qty,
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
    })) ?? []
  );
  const [bookingQty, setBookingQty] = useState(qty || 1);

  const item = priceItems?.find(({ id }) => id === priceItem.id);

  useEffect(() => {
    if (isVisible) {
      return;
    }

    setCalendarSlots([]);
    setBookingQty(1);
  }, [isVisible]);

  useEffect(() => {
    if (!qty) {
      return;
    }

    setBookingQty(qty);
  }, [qty]);

  useEffect(() => {
    if (!slots) {
      return;
    }

    setCalendarSlots(slots);
  }, [slots]);

  if (!isVisible || !item) {
    return null;
  }

  const handleSubmit = () => {
    onClose({
      index,
      priceUnit,
      orderItem: {
        priceItem,
        qty: bookingQty,
        slots: calendarSlots.map(({ startDate, endDate, qty }) => ({
          qty,
          startDate: moment(startDate).format(EDateFormat.Server),
          endDate: moment(endDate).format(EDateFormat.Server),
        })),
      },
    });
  };

  const { name: itemName, price, unit } = item;

  const isPeriod = orderByDateType === EOrderByDateType.Period;
  const hasCalendar = orderByDateType !== EOrderByDateType.None;

  const hasSelection =
    (hasCalendar &&
      ((isPeriod && calendarSlots?.[0]?.startDate && calendarSlots?.[0]?.endDate) ||
        (!isPeriod && calendarSlots?.length))) ||
    (qty && qty > 0);

  return (
    <StyledConfirmDialog
      title={name}
      open={isVisible}
      maxWidth='xs'
      fullScreenBreakpoint='sm'
      onClose={() => onClose()}
      buttons={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={handleSubmit}
            disabled={!hasSelection}
          >
            {typeof index === 'undefined' ? 'Добавить' : 'Изменить'}
          </MPButton>
          <MPButton
            fullWidth
            color='brand'
            variant='text'
            size='large'
            onClick={() => onClose()}
          >
            Отмена
          </MPButton>
        </ConfirmGroupButtons>
      }
    >
      <MPGrid
        container
        spacing={1}
        justifyContent='space-between'
        mb={1}
        flexWrap='nowrap'
      >
        <MPGrid item>{itemName}</MPGrid>
        <MPGrid
          item
          whiteSpace='nowrap'
          flexWrap='nowrap'
        >
          <PriceWrapper>
            <Typography
              variant='subtitle1'
              mr={0.5}
            >
              {toRubCurrency(price)}
            </Typography>
            <span>{unit?.name?.toLocaleLowerCase() ?? ''}</span>
          </PriceWrapper>
        </MPGrid>
      </MPGrid>

      {hasCalendar && (
        <>
          <AccordionWithIcon
            title={`Выбор ${isPeriod ? 'периода' : 'по датам'}`}
            description={isPeriod ? PeriodHelperText : DaysHelperText}
          />
          {isPeriod && (
            <BookingOfferModalPeriod
              slots={calendarSlots}
              index={index?.[1] ?? 0}
              setSlots={setCalendarSlots}
            />
          )}
          {!isPeriod && (
            <BookingOfferModalMultiple
              slots={calendarSlots}
              setSlots={setCalendarSlots}
            />
          )}
        </>
      )}

      {!hasCalendar && (
        <BookingOfferModalNumber
          count={bookingQty}
          onChange={setBookingQty}
        />
      )}
    </StyledConfirmDialog>
  );
};

export default BookingOfferModal;
