import { combineReducers } from '@reduxjs/toolkit';
import assistant from 'presentation/features/assistant/store/slice';
import auth from 'presentation/features/auth/reducers';
import cart from 'presentation/features/cart/reducers';
import catalog from 'presentation/features/catalog/store/slice';
import collection from 'presentation/features/cms/collection/store/slice';
import favorites from 'presentation/features/favorite/reducers';
import footerRegister from 'presentation/features/footerRegister/store/slice';
import historyExtensions from 'presentation/features/general/historyExtension/store/slice';
import lazyWrapper from 'presentation/features/general/lazyContentWrapper/store/slice';
import locationDetector from 'presentation/features/general/locationDetector/store/slice';
import notifier from 'presentation/features/general/notifier/store/slice';
import pageable from 'presentation/features/general/pageable/reducers';
import header from 'presentation/features/header/store/slice';
import aspOffer from 'presentation/features/offer/asp/reducers';
import bookingOffer from 'presentation/features/offer/booking/reducers';
import corpOffer from 'presentation/features/offer/corp/reducers';
import productOffer from 'presentation/features/offer/product/reducers';
import tradeOffer from 'presentation/features/offer/trade/reducers';
import partnerDesk from 'presentation/features/partnerDesk/reducers';
import search from 'presentation/features/search/reducers';
import sectionLinks from 'presentation/features/sectionLinks/store/slice';
import cms from 'presentation/screen/cms/reducers';
import { api } from '../api';

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  notifier,
  catalog,
  productOffer,
  tradeOffer,
  aspOffer,
  corpOffer,
  bookingOffer,
  partnerDesk,
  historyExtensions,
  locationDetector,
  favorites,
  cart,
  pageable,
  collection,
  lazyWrapper,
  header,
  sectionLinks,
  footerRegister,
  cms,
  search,
  auth,
  assistant,
});
