import { BookingOfferShort } from 'domain/model/bookingOffer';
import { LogoWrapper } from '../../../components/listItem';
import { InfoWrapper } from './controls';
import { AddressHelper } from '../../../../../../utils/address';
import { CardCaption } from 'presentation/features/offer/components/listItem/controls';
import { LogoImageView } from 'components/common/images/cover';

const BookingListItemInfo = (props: BookingOfferShort) => {
  const { address, partner } = props;

  const logo = partner?.logo;

  return (
    <InfoWrapper>
      {address && (
        <CardCaption
          variant='body2'
          color='textSecondary'
        >
          {new AddressHelper(address).getLocalitySimpleName()}
        </CardCaption>
      )}
      {logo?.path && (
        <LogoWrapper>
          <LogoImageView id={logo.path} />
        </LogoWrapper>
      )}
    </InfoWrapper>
  );
};

export default BookingListItemInfo;
