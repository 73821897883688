import { AxiosResponse } from 'axios';
import apiDefinition from 'data/openApi';
import {
  AppOptionTyped,
  ECorpOfferPromotionType,
  EDeliveryType,
  EOrderItemStatus,
  EOrderStatus,
  EProductOrderPaymentType,
  ETradeOfferPromotionType,
  PersonalPromotionType,
  TradePublicPromotionType,
} from 'domain/model';
import { api } from './index';

export const nsiApi = api.injectEndpoints({
  endpoints: builder => ({
    nsiGeneralOrderStatuses: builder.query<AppOptionTyped<EOrderStatus>[], void>({
      queryFn: () =>
        Promise.resolve({
          data: Object.values(GENERAL_ORDER_STATUS),
        }),
      keepUnusedDataFor: 24 * 60 * 60,
    }),
    nsiBookingOrderStatuses: builder.query<AppOptionTyped<EOrderStatus>[], void>({
      queryFn: () =>
        Promise.resolve({
          data: Object.values(BOOKING_ORDER_STATUS),
        }),
      keepUnusedDataFor: 24 * 60 * 60,
    }),
    nsiOrderItemStatuses: builder.query<AppOptionTyped<EOrderItemStatus>[], void>({
      queryFn: () =>
        Promise.resolve({
          data: Object.values(ORDER_ITEM_STATUS),
        }),
      keepUnusedDataFor: 24 * 60 * 60,
    }),
    nsiPromotionTypes: builder.query<AppOptionTyped<PersonalPromotionType | TradePublicPromotionType>[], void>({
      queryFn: () =>
        Promise.resolve({
          data: [...Object.values(PERSONAL_PROMOTION_TYPE), ...Object.values(PUBLIC_PROMOTION_TYPE)],
        }),
      keepUnusedDataFor: 24 * 60 * 60,
    }),
    nsiProductOrderPaymentTypes: builder.query<AppOptionTyped<EProductOrderPaymentType>[], void>({
      queryFn: () =>
        Promise.resolve({
          data: Object.values(PRODUCT_ORDER_PAYMENT_TYPE),
        }),
      keepUnusedDataFor: 24 * 60 * 60,
    }),
    nsiDeliveryTypes: builder.query<AppOptionTyped<EDeliveryType>[], void>({
      queryFn: () =>
        Promise.resolve({
          data: Object.values(DELIVERY_TYPE),
        }),
      keepUnusedDataFor: 24 * 60 * 60,
    }),
    nsiCancelOrderTypes: builder.query<AppOptionTyped<UUID>[], void>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: () => apiDefinition.nsi.cancelOrderReasonTypes({}),
      keepUnusedDataFor: 24 * 60 * 60,
    }),
    nsiCancelOrderCustomerTypes: builder.query<AppOptionTyped<UUID>[], void>({
      transformResponse: (response: AxiosResponse) => response.data.sort(sortByOther),
      query: () => apiDefinition.nsi.cancelOrderCustomerReasonTypes({}),
      keepUnusedDataFor: 24 * 60 * 60,
    }),
  }),
});

export const {
  useNsiGeneralOrderStatusesQuery,
  useNsiBookingOrderStatusesQuery,
  useNsiCancelOrderTypesQuery,
  useNsiDeliveryTypesQuery,
  useNsiOrderItemStatusesQuery,
  useNsiProductOrderPaymentTypesQuery,
  useNsiPromotionTypesQuery,
  useNsiCancelOrderCustomerTypesQuery,
} = nsiApi;

export const GENERAL_ORDER_STATUS: { [name in EOrderStatus]: AppOptionTyped<EOrderStatus> } = {
  [EOrderStatus.New]: {
    id: EOrderStatus.New,
    name: 'Принят',
  },
  [EOrderStatus.Confirmed]: {
    id: EOrderStatus.Confirmed,
    name: 'Подтверждён',
  },
  [EOrderStatus.Returned]: {
    id: EOrderStatus.Returned,
    name: 'Возвращён',
  },
  [EOrderStatus.Cancelled]: {
    id: EOrderStatus.Cancelled,
    name: 'Отменен',
  },
  [EOrderStatus.Given]: {
    id: EOrderStatus.Given,
    name: 'Выполнен',
  },
  [EOrderStatus.Active]: {
    id: EOrderStatus.Active,
    name: 'Выполняется',
  },
  [EOrderStatus.Sent]: {
    id: EOrderStatus.Sent,
    name: 'Отправлен',
  },
  [EOrderStatus.Paid]: {
    id: EOrderStatus.Paid,
    name: 'Оплачен',
  },
  [EOrderStatus.PartiallyReturned]: {
    id: EOrderStatus.PartiallyReturned,
    name: 'Частично возвращён',
  },
} as const;

export const BOOKING_ORDER_STATUS: { [name in EOrderStatus]: AppOptionTyped<EOrderStatus> } = {
  ...GENERAL_ORDER_STATUS,
  [EOrderStatus.New]: {
    id: EOrderStatus.New,
    name: 'Создан',
  },
  [EOrderStatus.Confirmed]: {
    id: EOrderStatus.Confirmed,
    name: 'Принят',
  },
} as const;

const ORDER_ITEM_STATUS: { [name in EOrderItemStatus]: AppOptionTyped<EOrderItemStatus> } = {
  [EOrderItemStatus.New]: {
    id: EOrderItemStatus.New,
    name: 'Принят',
  },
  [EOrderItemStatus.Confirmed]: {
    id: EOrderItemStatus.Confirmed,
    name: 'Подтверждён',
  },
  [EOrderItemStatus.Returned]: {
    id: EOrderItemStatus.Returned,
    name: 'Возвращён',
  },
  [EOrderItemStatus.Cancelled]: {
    id: EOrderItemStatus.Cancelled,
    name: 'Отменен',
  },
  [EOrderItemStatus.Given]: {
    id: EOrderItemStatus.Given,
    name: 'Выполнен',
  },
  [EOrderItemStatus.Changed]: {
    id: EOrderItemStatus.Changed,
    name: 'Состав изменён',
  },
  [EOrderItemStatus.PartiallyReceived]: {
    id: EOrderItemStatus.PartiallyReceived,
    name: 'Частично получен',
  },
} as const;

const PERSONAL_PROMOTION_TYPE: { [name in PersonalPromotionType]: AppOptionTyped<PersonalPromotionType> } = {
  [ETradeOfferPromotionType.Promocode]: {
    id: ETradeOfferPromotionType.Promocode,
    name: 'Промокод',
  },
  [ETradeOfferPromotionType.Voucher]: {
    id: ETradeOfferPromotionType.Voucher,
    name: 'Ваучер',
  },
  [ETradeOfferPromotionType.AccessCode]: {
    id: ETradeOfferPromotionType.AccessCode,
    name: 'Код доступа',
  },
  [ETradeOfferPromotionType.Asp]: {
    id: ETradeOfferPromotionType.Asp,
    name: 'Сертификат',
  },
  [ETradeOfferPromotionType.Digift]: {
    id: ETradeOfferPromotionType.Digift,
    name: 'Сертификат',
  },
  [ECorpOfferPromotionType.CorpCertificate]: {
    id: ECorpOfferPromotionType.CorpCertificate,
    name: 'Сертификат',
  },
  [ECorpOfferPromotionType.ExternalCorpCertificate]: {
    id: ECorpOfferPromotionType.ExternalCorpCertificate,
    name: 'Документ',
  },
};

const PUBLIC_PROMOTION_TYPE: { [name in TradePublicPromotionType]: AppOptionTyped<TradePublicPromotionType> } = {
  [ETradeOfferPromotionType.PublicPromocode]: {
    id: ETradeOfferPromotionType.PublicPromocode,
    name: 'Публичный промокод',
  },
  [ETradeOfferPromotionType.ReferralLink]: {
    id: ETradeOfferPromotionType.ReferralLink,
    name: 'Реферальная ссылка',
  },
  [ETradeOfferPromotionType.Widget]: {
    id: ETradeOfferPromotionType.Widget,
    name: 'Виджет',
  },
};

const PRODUCT_ORDER_PAYMENT_TYPE: { [name in EProductOrderPaymentType]: AppOptionTyped<EProductOrderPaymentType> } = {
  [EProductOrderPaymentType.Cash]: {
    id: EProductOrderPaymentType.Cash,
    name: 'Наличными',
  },
  [EProductOrderPaymentType.Card]: {
    id: EProductOrderPaymentType.Card,
    name: 'Банковской картой',
  },
  [EProductOrderPaymentType.CashOrCard]: {
    id: EProductOrderPaymentType.CashOrCard,
    name: 'Наличными и банковской картой',
  },
  [EProductOrderPaymentType.Invoice]: {
    id: EProductOrderPaymentType.Invoice,
    name: 'Онлайн (выставление счета)',
  },
  [EProductOrderPaymentType.Other]: {
    id: EProductOrderPaymentType.Other,
    name: 'Другое',
  },
};

const DELIVERY_TYPE: { [name in EDeliveryType]: AppOptionTyped<EDeliveryType> } = {
  [EDeliveryType.Delivery]: {
    id: EDeliveryType.Delivery,
    name: 'Доставка',
  },
  [EDeliveryType.Self]: {
    id: EDeliveryType.Self,
    name: 'Только самовывоз',
  },
  [EDeliveryType.SelfOrDelivery]: {
    id: EDeliveryType.SelfOrDelivery,
    name: 'Доставка и самовывоз',
  },
  [EDeliveryType.Other]: {
    id: EDeliveryType.Other,
    name: 'Другое',
  },
};

/** Ставит причину отмены "Другое" (без offerType) в конец списка */
export const sortByOther = ({ offerType }: AppOptionTyped<string>) => {
  if (offerType) return -1;
  return 1;
};
