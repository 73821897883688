import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { BookingOffersSearchListLocationState } from '../../types';
import {
  bookingOfferIsFilterFetchingSelector,
  bookingOfferListNoDataNoFilterSelector,
  bookingOfferListNoDataSelector,
} from '../store/selectors';

type BookingOfferListStubAdapterProps = Pick<BookingOffersSearchListLocationState, 'categoryId'>;

export const BookingOfferListStubAdapter: FC<BookingOfferListStubAdapterProps> = ({ categoryId }) => {
  const noData = useSelector(bookingOfferListNoDataSelector);
  const noFilterNoData = useSelector(bookingOfferListNoDataNoFilterSelector);
  const isFilterFetching = useSelector(bookingOfferIsFilterFetchingSelector);

  if ((!categoryId && noFilterNoData) || (categoryId && !isFilterFetching && noData)) {
    return (
      <div>
        <Typography variant='body2'>Ничего не найдено.</Typography>
        <Splitter size={1} />
        <Typography variant='body2'>Попробуйте изменить параметры фильтра</Typography>
      </div>
    );
  }

  return null;
};
