import { bookingOfferSortType, EBookingOfferSortType } from 'domain/model';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import OffersSortSelect from '../../../components/sortSelect';
import { BookingOffersSearchListLocationState } from '../../types';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { bookingOfferListIsNewSearchFetchingSelector, bookingOfferListNoDataSelector } from '../store/selectors';

const items = Object.values(bookingOfferSortType);

type BookingOfferListSortSelectAdapterProps = Pick<BookingOffersSearchListLocationState, 'sort'> & {
  readonly filterCount: number;
};

export const BookingOfferListSortSelectAdapter: FC<BookingOfferListSortSelectAdapterProps> = ({
  sort,
  filterCount,
}) => {
  const { onChangeSort } = useContextHandlers();

  const noData = useSelector(bookingOfferListNoDataSelector);
  const isNewSearchFetching = useSelector(bookingOfferListIsNewSearchFetchingSelector);

  return (noData && !filterCount) || isNewSearchFetching ? null : (
    <OffersSortSelect<EBookingOfferSortType>
      value={sort}
      items={items}
      defaultValue={EBookingOfferSortType.Default}
      onChange={onChangeSort}
    />
  );
};
