import { FC, Fragment } from 'react';
import moment from 'moment-timezone';
import { EDateFormat, EOrderByDateType } from 'domain/model';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { BookingCartItem } from '../../details/types';
import { BookingSlotItem } from './slotItem';
import BookingSidebarSlotActions from './slotActions';
import Splitter from '../../../../../components/common/splitter';
import { SlotProps } from './types';

type BookingSidebarSlotRandeProps = SlotProps & {
  item: BookingCartItem['orderItem'];
  price: number;
};

const BookingSidebarSlotRange: FC<BookingSidebarSlotRandeProps> = ({ item, price, ...actions }) => {
  return item.slots?.map((slot, slotIndex) => (
    <Fragment key={`${slot.startDate}-${slot.endDate}`}>
      <MPGrid
        container
        spacing={1}
        justifyContent='space-between'
      >
        <BookingSlotItem title='Прибытие'>{moment(slot.startDate).format(EDateFormat.Human)}</BookingSlotItem>
        <BookingSlotItem title='Выезд'>{moment(slot.endDate).format(EDateFormat.Human)}</BookingSlotItem>
      </MPGrid>
      <MPGrid
        container
        spacing={1}
      >
        <BookingSlotItem title='Гостей'>{slot.qty}</BookingSlotItem>
      </MPGrid>

      <BookingSidebarSlotActions
        unitType={EOrderByDateType.Period}
        price={price}
        slots={[slot]}
        slotIndex={slotIndex}
        {...actions}
      />

      <Splitter size={2} />
    </Fragment>
  ));
};

export default BookingSidebarSlotRange;
