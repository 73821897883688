import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { LandingWindow } from 'domain/model';
import { ApiCancellable } from '../types';
import { createCancelToken } from '../utils';

type WindowProps = ApiCancellable;

type WindowTestProps = ApiCancellable;

export type UserLanding = {
  readonly window: (props: WindowProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, LandingWindow>>;
  readonly windowTest: (props: WindowTestProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, LandingWindow>>;
};

const landing: UserLanding = {
  window: props => {
    const { signal } = props;

    return {
      url: `/customers/current/containers`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  windowTest: props => {
    const { signal } = props;
    const params = new URLSearchParams();
    params.append('page', '0');
    params.append('size', '99999');

    return {
      url: `/customers/current/containers`,
      cancelToken: signal && createCancelToken(axios, signal),
      method: 'GET',
      params,
    };
  },
};

export default landing;
