import { AppOption } from 'domain/model';
import { EDateFormat } from 'domain/model/formats';
import { BookingOrderShort } from 'domain/model/order';
import { SeeAndSunIcon } from 'presentation/media/icons';
import { toRubCurrency } from '../../../../../utils/currency';
import { utcToLocalTimeZone } from '../../../../../utils/date';
import OrderListItemLayout from '../layout';
import { getRequisites, getStatusElement, getStatusName } from './utils';

type BookingOfferOrderAdapterProps = {
  readonly order: BookingOrderShort;
  readonly orderStatuses: AppOption[];
  readonly onClick: () => void;
};

const BookingOfferOrderAdapter = (props: BookingOfferOrderAdapterProps) => {
  const { order, orderStatuses, onClick } = props;

  const date = utcToLocalTimeZone(order.createdAt).format(EDateFormat.Human);

  const requisites = order.number && getRequisites(order.number, date);
  const statusName = getStatusName(orderStatuses, order.status);

  const cost = toRubCurrency(order.cost);

  return (
    <OrderListItemLayout
      status={getStatusElement(statusName, order)}
      name={order.offer?.name ?? ''}
      cost={cost}
      icon={SeeAndSunIcon}
      requisites={requisites}
      onClick={onClick}
    />
  );
};

export default BookingOfferOrderAdapter;
