import { CorpOffer } from 'domain/model/corpOffer';
import { Moment } from 'moment-timezone';
import React from 'react';
import { OfferActivationState } from '../../../hooks/useOfferActivations';
import { OfferActivation } from 'domain/model';

export type CorpOfferClaimContextType = {
  readonly corpOffer: Nullable<CorpOffer>;
  readonly endDate: Nullable<Moment>;
  readonly earnPointsUrl: string;
  readonly userBalance: Nullable<number>;
  readonly isFetching: boolean;
  readonly activation: OfferActivationState;
  readonly isSubscriptionEnabled: boolean;
  readonly isSubscriptionFetching: boolean;
  readonly isSubscriptionSupported: boolean;
  readonly isActivationReceivedNow: boolean;
  readonly onSubscribe: () => void;
  readonly onUnSubscribe: () => void;
  readonly onTakeActivation: (result: OfferActivation) => void;
  readonly onActivate: () => Promise<Nullable<OfferActivation>>;
  readonly isTaking: boolean;
  readonly onCopy: () => void;
  readonly isTaked: boolean;
  readonly needCopy: boolean;
  readonly onNeedCopy: (value: boolean) => void;
};

const CorpOfferClaimContext = React.createContext<CorpOfferClaimContextType>(
  null as unknown as CorpOfferClaimContextType
);

export default CorpOfferClaimContext;
