import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { CategoryTree, CorpOfferShort, EOfferStatus } from 'domain/model';
import { ApiCancellable, ApiRequestPageable } from '../types';
import { createCancelToken, getUserQueryParams } from '../utils';

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type CategoriesUsedProps = ApiCancellable & {
  readonly query?: Nullable<string>;
  readonly statuses?: Nullable<EOfferStatus[]>;
  readonly partnerId?: Nullable<UUID>;
  readonly onlyLeafCategories?: Nullable<boolean>;
};

type CorpOffersProps = ApiRequestPageable &
  ApiCancellable & {
    readonly query?: Nullable<string>;
    readonly categories?: Nullable<UUID[]>;
    readonly statuses?: Nullable<EOfferStatus[]>;
    readonly partnerId?: Nullable<UUID>;
    readonly favorite?: Nullable<boolean>;
  };

type CorpOffersCollectionProps = ApiCancellable & {
  readonly id: UUID;
};

type CorpOffersCountProps = Omit<CorpOffersProps, 'page' | 'pageSize' | 'sort'>;

export type UserCorpOffer = {
  readonly all: (props: CorpOffersProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, CorpOfferShort[]>>;
  readonly collection: (
    props: CorpOffersCollectionProps
  ) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, CorpOfferShort[]>>;
  readonly count: (props: CorpOffersCountProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, number>>;
  readonly one: (props: OneProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, CorpOfferShort>>;
  readonly categoriesUsed: (props: CategoriesUsedProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, CategoryTree>>;
};

const corpOffer: UserCorpOffer = {
  all: props => {
    const { categories, statuses, partnerId, signal, ...queryParams } = props;

    const params = getUserQueryParams(queryParams);

    if (categories) {
      categories.forEach(item => params.append('category', item));
    }

    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }

    if (partnerId) {
      params.append('partnerId', partnerId);
    }

    return {
      url: `/customers/current/corp-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },

  collection: props => {
    const { id, signal } = props;

    return {
      url: `/customers/current/corp-offers/collections/${id}`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },

  count: props => {
    const { categories, statuses, partnerId, signal, ...queryParams } = props;

    const params = getUserQueryParams(queryParams);

    params.append('resultType', 'count');

    if (categories) {
      categories.forEach(item => params.append('category', item));
    }

    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }

    if (partnerId) {
      params.append('partnerId', partnerId);
    }

    return {
      url: `/customers/current/corp-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  one: ({ id }) => {
    return { url: `/customers/current/corp-offers/${id}`, method: 'GET' };
  },
  categoriesUsed: ({ query, statuses, partnerId, onlyLeafCategories, signal }) => {
    const params = new URLSearchParams();

    if (query) {
      params.append('q', query);
    }

    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }

    if (partnerId) {
      params.append('partnerId', partnerId);
    }

    if (onlyLeafCategories !== null && onlyLeafCategories !== undefined) {
      params.append('onlyLeafCategories', onlyLeafCategories.toString());
    }

    return {
      url: '/customers/current/corp-offers/categories',
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
};

export default corpOffer;
