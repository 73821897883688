import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { CorpOffer, EOfferActivateError, EOfferActivationStatus } from 'domain/model';
import { createEvent as createEventNeedFillProfile } from 'features/user/events/needFillProfile';
import { FCC, useCallback, useEffect, useState } from 'react';
import OfferNdflHelpDialog from '../../../components/dialogs/ndfl';
import OfferUnavailableDialog from '../../../components/dialogs/unavailable';
import CorpOfferGetCertDialog from '../../details/dialogs/getCert';
import CorpOfferGetNewCertDialog from '../../details/dialogs/getNewCert';
import useCorpOfferClaimContext from '../provider/useCorpOfferClaimContext';
import CorpOfferClaimDialogsContext, { CorpOfferClaimDialogsContextType } from './context';

type CorpOfferClaimDialogsProviderProps = {
  readonly corpOffer: CorpOffer;
  readonly onShowAllOffers: () => void;
};

const CorpOfferClaimDialogsProvider: FCC<CorpOfferClaimDialogsProviderProps> = props => {
  const { children, corpOffer, onShowAllOffers } = props;
  const { activation, onActivate } = useCorpOfferClaimContext();
  const { publish } = useBusinessEventBus();

  const [ndflHelpDialogVisible, setNdflHelpDialogVisible] = useState<boolean>(false);
  const [unavailableDialogVisible, setUnavailableDialogVisible] = useState<boolean>(false);
  const [certificateDialogVisible, setCertificateDialogVisible] = useState<boolean>(false);
  const [newCertificateDialogVisible, setNewCertificateDialogVisible] = useState<boolean>(false);

  const onOpenNdflHelpDialog = useCallback(() => setNdflHelpDialogVisible(true), [setNdflHelpDialogVisible]);
  const onCloseNdflHelpDialog = useCallback(() => setNdflHelpDialogVisible(false), [setNdflHelpDialogVisible]);

  const onOpenUnavailableDialog = useCallback(() => setUnavailableDialogVisible(true), [setUnavailableDialogVisible]);
  const onCloseUnavailableDialog = useCallback(() => setUnavailableDialogVisible(false), [setUnavailableDialogVisible]);

  const onOpenCertificateDialog = useCallback(() => setCertificateDialogVisible(true), [setCertificateDialogVisible]);
  const onCloseCertificateDialog = useCallback(() => setCertificateDialogVisible(false), [setCertificateDialogVisible]);

  const onOpenNewCertificateDialog = useCallback(
    () => setNewCertificateDialogVisible(true),
    [setNewCertificateDialogVisible]
  );
  const onCloseNewCertificateDialog = useCallback(
    () => setNewCertificateDialogVisible(false),
    [setNewCertificateDialogVisible]
  );

  const onActivateCertificate = useCallback(() => {
    onCloseCertificateDialog();
    onCloseNewCertificateDialog();
    onActivate();
  }, [onActivate, onCloseCertificateDialog, onCloseNewCertificateDialog]);

  const openCertificateDialog = useCallback(() => {
    if (
      activation.lastActivation?.status === EOfferActivationStatus.Approved ||
      activation.lastActivation?.status === EOfferActivationStatus.Given
    ) {
      onOpenNewCertificateDialog();
    } else {
      onOpenCertificateDialog();
    }
  }, [activation.lastActivation?.status, onOpenNewCertificateDialog, onOpenCertificateDialog]);

  useEffect(() => {
    if (activation.activationError?.type === EOfferActivateError.InvalidUserStatus) {
      publish(createEventNeedFillProfile({ message: activation.activationError.message }));
    }
  }, [activation.activationError, publish]);

  const value: CorpOfferClaimDialogsContextType = {
    openUnavailableDialog: onOpenUnavailableDialog,
    openNdflHelpDialog: onOpenNdflHelpDialog,
    openCertificateDialog,
    onShowAllOffers,
  };

  return (
    <CorpOfferClaimDialogsContext.Provider value={value}>
      {children}
      <OfferNdflHelpDialog
        open={ndflHelpDialogVisible}
        description={corpOffer.ndflDescription}
        onClose={onCloseNdflHelpDialog}
      />
      <OfferUnavailableDialog
        open={unavailableDialogVisible}
        onShowOtherOffers={onShowAllOffers}
        onClose={onCloseUnavailableDialog}
      />
      <CorpOfferGetCertDialog
        open={certificateDialogVisible}
        price={corpOffer.price}
        offerName={corpOffer.name}
        offerEndDate={corpOffer.endDate}
        activationExpiryDays={corpOffer.activationExpiryDays ?? 0}
        onGetCertificate={onActivateCertificate}
        onClose={onCloseCertificateDialog}
      />
      <CorpOfferGetNewCertDialog
        open={newCertificateDialogVisible}
        price={corpOffer.price}
        offerEndDate={corpOffer.endDate}
        activationExpiryDays={corpOffer.activationExpiryDays ?? 0}
        onGetCertificate={onActivateCertificate}
        onClose={onCloseNewCertificateDialog}
      />
    </CorpOfferClaimDialogsContext.Provider>
  );
};

export default CorpOfferClaimDialogsProvider;
