import { BusinessEvent, useBusinessEventBus } from '@privilege-frontend/eventBus';
import { AxiosResponse } from 'axios';
import { api } from 'data/api';
import {
  useActivateUserOfferMutation,
  useGetUserOfferActivationsQuery,
  useGetUserOfferAvailabilityQuery,
} from 'data/api/user';
import { businessErrorCode } from 'data/network/constants';
import ErrorHandler from 'data/network/errorHandler';
import { ServerErrorResponse } from 'data/network/types';
import {
  CorpOffer,
  EBalanceType,
  ECorpOfferPromotionType,
  EOfferActivateError,
  EOfferActivationStatus,
  ETradeOfferPromotionType,
  OfferActivation,
  TradeOffer,
} from 'domain/model';
import { useAuth } from 'features/auth/provider/useAuth';
import useCurrentUserBalanceByType from 'features/user/current/hooks/useBalanceByType';
import {
  currentUserIsEmailVerifiedSelector,
  currentUserIsStatusEnabledSelector,
} from 'features/user/current/store/selectors';
import { createEvent as createEventConfirmEmail } from 'features/user/events/confirmEmail';
import { createEvent as createEventConfirmPhone } from 'features/user/events/confirmPhone';
import { createEvent as createEventNeedFillProfile } from 'features/user/events/needFillProfile';
import { useWebAnalytics } from 'features/webAnalytics';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notifier from '../../../../system/notifier';
import { OfferActivationError } from '../types';
import { getOfferLastActivation } from '../utils';

type UseUserOfferActivationsProps = {
  readonly offerId: UUID;
  readonly balanceType?: EBalanceType;
  readonly offerPrice?: Nullable<number>;
  readonly isActivationAllowed?: boolean;
  readonly offerRefetch: () => void;
  readonly onTakeActivation: (activation: OfferActivation) => void;
};

type ActivateOptions = {
  readonly needConfirmPhone?: boolean;
};

export type OfferActivationState = {
  readonly isLoadActivationsFetching: boolean;
  readonly isLoadActivationsFailed: boolean;
  readonly isReactivationSupported: boolean;
  readonly activationsReload: () => void;

  readonly unavailabilityReasons: EOfferActivateError[];
  readonly isActivationAvailable: boolean;
  readonly isUserBalanceNotEnough: boolean;

  readonly userActivationAttempt: (offer: CorpOffer | TradeOffer) => void;
  readonly activateOffer: (
    offer: CorpOffer | TradeOffer,
    options?: ActivateOptions
  ) => Promise<Nullable<OfferActivation>>;
  readonly activationError: Nullable<OfferActivationError>;
  readonly activationIsFetching: boolean;

  readonly activations: OfferActivation[];

  readonly isLastActivationPending: boolean;
  readonly lastActivation: Nullable<OfferActivation>;
  readonly lastReceivedActivation: Nullable<OfferActivation>;
};

const useOfferActivations = (props: UseUserOfferActivationsProps): OfferActivationState => {
  const { offerId, offerPrice, balanceType, offerRefetch, isActivationAllowed = true, onTakeActivation } = props;

  const dispatch = useDispatch();
  const { publishFlow } = useBusinessEventBus();
  const { isAuthenticated, login } = useAuth();
  const { webAnalytics } = useWebAnalytics();
  const [activationError, setActivationError] = useState<Nullable<OfferActivationError>>(null);

  const balance = useCurrentUserBalanceByType(balanceType ?? null);
  const isUserEmailVerified = useSelector(currentUserIsEmailVerifiedSelector);
  const isUserStatusEnabled = useSelector(currentUserIsStatusEnabledSelector);

  const {
    data: activations = [],
    error: activationsError,
    isFetching: activationsIsFetching,
    refetch: activationsRefetch,
  } = useGetUserOfferActivationsQuery(
    { id: offerId },
    {
      refetchOnMountOrArgChange: true,
      skip: !isActivationAllowed,
    }
  );

  const {
    data: unavailabilityReasonsData = [],
    error: availabilityError,
    isFetching: availabilityIsFetching,
    refetch: availabilityRefetch,
  } = useGetUserOfferAvailabilityQuery(
    { offerId },
    {
      refetchOnMountOrArgChange: true,
      skip: !isActivationAllowed,
    }
  );

  const [activateOfferInternal, activateOfferResult] = useActivateUserOfferMutation();

  const unavailabilityReasons = useMemo<EOfferActivateError[]>(() => {
    const result = [...unavailabilityReasonsData];
    if (isUserEmailVerified === false) {
      result.push(EOfferActivateError.EmailNotVerified);
    }
    if (isUserStatusEnabled === false) {
      result.push(EOfferActivateError.InvalidUserStatus);
    }
    return result;
  }, [isUserEmailVerified, isUserStatusEnabled, unavailabilityReasonsData]);

  const { isLoading: activationIsFetching, data: lastReceivedActivation = null } = activateOfferResult;
  const lastActivation = getOfferLastActivation(activations) ?? null;
  const isLastActivationPending = lastActivation?.status === EOfferActivationStatus.Pending;

  const parseActivationError = useCallback(
    (error: ServerErrorResponse) => {
      const type = error.code as EOfferActivateError;
      const message = error.message;
      switch (type) {
        case EOfferActivateError.PromotionFreshOut:
          ErrorHandler.handleBusinessError(error);
          offerRefetch();
          availabilityRefetch();
          break;
        default:
          setActivationError({
            type,
            message,
          });
      }
    },
    [availabilityRefetch, offerRefetch]
  );

  const parseActivationErrorResponse = useCallback(
    (response: AxiosResponse<ServerErrorResponse>) => {
      switch (response.status) {
        case businessErrorCode:
          if (!response.data.code) {
            setActivationError({
              type: null,
            });
            ErrorHandler.handleHttpError(response);
          } else {
            parseActivationError(response.data);
          }
          return;
        case 500:
          setActivationError({
            type: null,
          });
          return;
        default: {
          ErrorHandler.handleHttpError(response);
        }
      }
    },
    [parseActivationError]
  );

  const userActivationAttempt = useCallback<OfferActivationState['userActivationAttempt']>(
    offer => {
      switch (offer.promotionType) {
        case ETradeOfferPromotionType.ExternalCorpCertificate:
        case ECorpOfferPromotionType.ExternalCorpCertificate:
          if (offer.partnerId) {
            webAnalytics.openPartnerUrl(offer.partnerId);
          }
          webAnalytics.offerJumpToPartnerSite(offer.id);
          webAnalytics.offerActivate(offer.id);
          break;
        case ETradeOfferPromotionType.CorpCertificate:
        case ECorpOfferPromotionType.CorpCertificate:
          webAnalytics.offerActivate(offer.id);
          break;
        case ETradeOfferPromotionType.AccessCode:
        case ETradeOfferPromotionType.Promocode:
        case ETradeOfferPromotionType.Voucher:
        case ETradeOfferPromotionType.Asp:
        case ETradeOfferPromotionType.Digift:
          if (lastActivation) {
            webAnalytics.tradeOfferReactivate(offer.id);
          } else {
            webAnalytics.tradeOfferActivate(offer.id);
          }
          webAnalytics.offerActivate(offer.id);
          break;
        case ETradeOfferPromotionType.PublicPromocode:
        case ETradeOfferPromotionType.Widget:
        case ETradeOfferPromotionType.ReferralLink:
          webAnalytics.tradeOfferActivate(offer.id);
          webAnalytics.offerActivate(offer.id);
          break;
        case null:
          break;
      }
    },
    [webAnalytics, lastActivation]
  );

  const activateOfferImmediately = useCallback(
    async (offer: CorpOffer | TradeOffer) => {
      let callActivate: boolean = false;
      switch (offer.promotionType) {
        case ETradeOfferPromotionType.ExternalCorpCertificate:
        case ECorpOfferPromotionType.ExternalCorpCertificate:
          callActivate = false;
          break;
        case ETradeOfferPromotionType.CorpCertificate:
        case ECorpOfferPromotionType.CorpCertificate:
          callActivate = true;
          break;
        case ETradeOfferPromotionType.AccessCode:
        case ETradeOfferPromotionType.Promocode:
        case ETradeOfferPromotionType.Voucher:
          callActivate = true;
          break;
        case ETradeOfferPromotionType.PublicPromocode:
        case ETradeOfferPromotionType.Widget:
        case ETradeOfferPromotionType.ReferralLink:
          callActivate = true;
          break;
        case ETradeOfferPromotionType.Asp:
        case ETradeOfferPromotionType.Digift:
          callActivate = true;
          break;
        case null:
          break;
      }

      let result = null;
      if (callActivate) {
        setActivationError(null);
        try {
          result = await activateOfferInternal({ id: offer.id }).unwrap();
          if (result) {
            onTakeActivation(result);
          }
          offerRefetch();
          activationsRefetch();
        } catch (e) {
          parseActivationErrorResponse(e as AxiosResponse<ServerErrorResponse>);
        }
      }

      return result;
    },
    [activateOfferInternal, offerRefetch, activationsRefetch, onTakeActivation, parseActivationErrorResponse]
  );

  const checkActivationAvailability = useCallback(
    (offer: CorpOffer | TradeOffer, options?: ActivateOptions): boolean => {
      if (!unavailabilityReasons.length && !options) {
        return true;
      }

      const isEmailNotVerified = unavailabilityReasons.indexOf(EOfferActivateError.EmailNotVerified) !== -1;
      const isInvalidUserStatus = unavailabilityReasons.indexOf(EOfferActivateError.InvalidUserStatus) !== -1;

      if (isEmailNotVerified || isInvalidUserStatus || options?.needConfirmPhone) {
        const events: BusinessEvent<any, any>[] = [];

        if (isInvalidUserStatus) {
          //если сценарий тупиковый, то просто добавляем ивенты
          if (isEmailNotVerified) {
            events.push(createEventConfirmEmail({}));
          }
          if (isInvalidUserStatus) {
            events.push(createEventNeedFillProfile({}));
          }
        } else {
          //если сценарий не тупиковый

          if (isEmailNotVerified) {
            events.push(createEventConfirmEmail({}));
          }

          if (options?.needConfirmPhone) {
            events.push(createEventConfirmPhone({}));
          }

          if (events.length) {
            /**
             * для последнего ивента добавляем ожидание выполнения, чтобы после успеха выполнить автоматическую активацию
             */
            const onSuccess = (result: boolean | string) => {
              if (result) {
                //дождемся пока отработают все активные запросы, которые могут быть связаны с ивентами
                Promise.all(dispatch(api.util.getRunningQueriesThunk())).then(() => {
                  activateOfferImmediately(offer);
                });
              }
            };

            events[events.length - 1] = {
              ...events[events.length - 1],
              onSuccess,
            };
          }
        }

        publishFlow(events);
        return false;
      }
      return true;
    },
    [unavailabilityReasons, dispatch, activateOfferImmediately, publishFlow]
  );

  const beforeActivateOffer = useCallback(
    (offer: CorpOffer | TradeOffer, options?: ActivateOptions): boolean => {
      if (!isAuthenticated) {
        login();
        return false;
      }
      return checkActivationAvailability(offer, options);
    },
    [checkActivationAvailability, isAuthenticated, login]
  );

  const activateOffer = useCallback<OfferActivationState['activateOffer']>(
    async (offer, options) => {
      const canContinue = beforeActivateOffer(offer, options);
      if (!canContinue) {
        return null;
      }

      return activateOfferImmediately(offer);
    },
    [beforeActivateOffer, activateOfferImmediately]
  );

  const activationsReload = useCallback<OfferActivationState['activationsReload']>(() => {
    activationsRefetch();
    availabilityRefetch();
  }, [activationsRefetch, availabilityRefetch]);

  const isLoadActivationsFetching = activationsIsFetching || availabilityIsFetching;
  const isLoadActivationsFailed = !!activationsError || !!availabilityError;

  useEffect(() => {
    if (isLoadActivationsFailed) {
      Notifier.getInstance().addError('Ошибка при получении. Попробуйте ещё раз позже');
    }
  }, [isLoadActivationsFailed]);

  const isActivationAvailable =
    !unavailabilityReasons.some(reason => reason === EOfferActivateError.InappropriateTargeting) &&
    activationError?.type !== EOfferActivateError.InappropriateTargeting;
  const isReactivationSupported = !unavailabilityReasons.some(
    reason => reason === EOfferActivateError.OfferActivationAlreadyExist
  );

  const isUserBalanceNotEnough = !balance?.value || balance?.value < (offerPrice ?? 0);

  return {
    isLoadActivationsFetching,
    isLoadActivationsFailed,
    isReactivationSupported,
    activationsReload,

    unavailabilityReasons,
    isActivationAvailable,
    isUserBalanceNotEnough,

    userActivationAttempt,
    activateOffer,
    activationError,
    activationIsFetching,
    activations,

    isLastActivationPending,
    lastActivation,
    lastReceivedActivation,
  };
};

export default useOfferActivations;
