import { useGetBookingOfferCategoriesQuery, useGetBookingOfferCategoriesUsedQuery } from 'data/api/bookingOffer';
import ErrorHandler from 'data/network/errorHandler';
import { Category } from 'domain/model';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { bookingOfferListCategoryIdSelector } from '../store/selectors';

const useCategoriesData = () => {
  const categoryId = useSelector(bookingOfferListCategoryIdSelector);

  const {
    data: categories,
    isFetching: isCategoriesFetching,
    error: categoriesFetchError,
  } = useGetBookingOfferCategoriesQuery({});

  const {
    data: categoriesUsedData,
    error: categoriesUsedFetchError,
    isFetching: isCategoriesUsedFetching,
  } = useGetBookingOfferCategoriesUsedQuery({
    //запрашиваем листовые только если находимся внутри категории
    onlyLeafCategories: !!categoryId,
  });

  useEffect(() => {
    if (categoriesFetchError) {
      ErrorHandler.handleHttpError(categoriesFetchError);
    }
    if (categoriesUsedFetchError) {
      ErrorHandler.handleHttpError(categoriesUsedFetchError);
    }
  }, [categoriesFetchError, categoriesUsedFetchError]);

  const isFetching = isCategoriesFetching || isCategoriesUsedFetching;
  let selectedCategory: Nullable<Category> = null;
  let subCategories: Nullable<Category[]> = null;

  //если категория указана, то ищем из набора используемых
  if (categoryId) {
    subCategories = categoriesUsedData?.length
      ? categoriesUsedData.filter(category => category.parentId === categoryId)
      : null;
    selectedCategory = categoriesUsedData?.find(category => category.id === categoryId) ?? null;
  } else {
    //иначе берём все рутовые
    subCategories = categoriesUsedData?.length
      ? categoriesUsedData.filter(category => !category.parentId).sort((c1, c2) => c1.name.localeCompare(c2.name))
      : null;
  }

  return {
    categories,
    subCategories,
    selectedCategory,
    isCategoriesFetching: isFetching,
    categoriesFetchError,
  };
};

export default useCategoriesData;
