import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

const backdrops: OverrideThemeFunc<Components> = () => ({
  MuiBackdrop: {
    styleOverrides: {
      root: {
        background: 'rgba(0, 0, 0, 0.30)',
        backdropFilter: 'blur(2.5px)',
      },
      invisible: {
        background: 'inherit',
        backdropFilter: 'none',
      },
    },
  },
});

export default backdrops;
