import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { OfferListRequest } from 'data/api';
import { EBookingOfferSortType } from 'domain/model';
import { PriceRangeFilter } from 'domain/model';
import { bookingOffersDefaultParams } from '../../utils';
import { bookingOfferListFilterFetch } from './fetch';
import { BookingOfferListState, EBookingOfferListRequestType } from './types';
import { getAdjustedPrice, getRequestTypes } from './utils';

type Reducer<T> = CaseReducer<BookingOfferListState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<BookingOfferListState> & {
  bookingOffersStartSession: Reducer<{ guid: UUID }>;
  bookingOffersSetPageSize: Reducer<number>;
  bookingOffersSetPage: Reducer<number>;
  bookingOffersSetSort: Reducer<EBookingOfferSortType[]>;
  bookingOffersSetSearch: Reducer<OfferListRequest['search']>;
  bookingOffersSetFilterServices: Reducer<Nullable<UUID[]>>;
  bookingOffersSetFilterPriceRange: Reducer<PriceRangeFilter>;
  bookingOffersSetCompensation: Reducer<boolean>;
  bookingOffersSetIsNewFetching: Reducer<boolean>;
  bookingOffersSetIsNewSearchFetching: Reducer<boolean>;
  bookingOffersSetIsDefaultServiceSet: Reducer<boolean>;
};

const { priceRange, services, rzdSocialPackage, ...defaultData } = bookingOffersDefaultParams;

const slice = createSlice<BookingOfferListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    isNewFetching: true,
    isNewSearchFetching: true,
    isDefaultServiceSet: false,
    categoryId: null,
    data: defaultData,
    filter: {
      services,
      priceRange,
      rzdSocialPackage,
    },
    services: null,
    filterData: {
      rzdSocialPackage,
      priceRange,
      services: null,
      leavingServiceId: null,
      isFetched: false,
      isFailed: false,
      isCancelled: false,
      isFetching: true,
    },
  },
  reducers: {
    bookingOffersStartSession: (state, { payload }) => {
      const { guid } = payload;
      state.guid = guid;
    },
    bookingOffersSetPageSize: (state, { payload }) => {
      state.data.pageSize = payload;
    },
    bookingOffersSetSort: (state, { payload }) => {
      state.data.sort = payload;
    },
    bookingOffersSetSearch: (state, { payload }) => {
      state.data.search = payload;
    },
    bookingOffersSetFilterServices: (state, { payload }) => {
      state.filter.services = payload;
    },
    bookingOffersSetFilterPriceRange: (state, { payload }) => {
      state.filter.priceRange = payload;
      state.data.minPrice = payload.minPrice;
      state.data.maxPrice = payload.maxPrice;
    },
    bookingOffersSetCompensation: (state, { payload }) => {
      state.filter.rzdSocialPackage = payload;
    },
    bookingOffersSetPage: (state, { payload }) => {
      state.data.page = payload;
    },
    bookingOffersSetIsNewFetching: (state, { payload }) => {
      state.isNewFetching = payload;
    },
    bookingOffersSetIsNewSearchFetching: (state, { payload }) => {
      state.isNewSearchFetching = payload;
    },
    bookingOffersSetIsDefaultServiceSet: (state, { payload }) => {
      state.isDefaultServiceSet = payload;
    },
    bookingOffersSetCategoryId: (state, { payload }) => {
      state.categoryId = payload;
      if (payload) {
        state.data.search.categories = [payload];
      } else {
        state.data.search.categories = null;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingOfferListFilterFetch.pending, (state, action) => {
        state.filterData = {
          ...state.filterData,
          leavingServiceId:
            action.meta.arg.requestType === EBookingOfferListRequestType.All ? null : state.filterData.leavingServiceId,
          isFetching: true,
          isFetched: false,
          isFailed: false,
        };
      })
      .addCase(
        bookingOfferListFilterFetch.fulfilled,
        (
          state,
          { payload: { leavingServiceId, requestType, allServices, services, priceRange, priceRangeRequested } }
        ) => {
          const { isAll, isReset, isServices, isCompensation } = getRequestTypes(
            requestType as EBookingOfferListRequestType
          );

          const currentFilterDataRange = { ...state.filterData.priceRange };

          state.filterData = {
            isFetching: false,
            isFetched: true,
            isFailed: false,
            leavingServiceId: isAll ? leavingServiceId : state.filterData.leavingServiceId,
            priceRange: isAll || isServices || isReset || isCompensation ? priceRange : state.filterData.priceRange,
            services,
            rzdSocialPackage,
          };

          // Обновление данных стейта в момент isFetched:true для корректного выполнения запроса rtk списка карточек

          if (isAll && allServices) {
            state.services = allServices;
            const adjustedPriceRange = priceRangeRequested ?? priceRange;
            // Здесь и условие ниже
            // Обновление в стейте фильтров state.filter.* при наличии категории Проживание для корректного выполнения запроса карточек,
            // т.к. rtk привязан к дублированным аргументам в стейте для последующего соответствия данных кэшам rtk в селекторах
            state.filter.priceRange = {
              minPrice: getAdjustedPrice('minPrice', priceRange, adjustedPriceRange),
              maxPrice: getAdjustedPrice('maxPrice', priceRange, adjustedPriceRange),
            };
          }

          if (
            isAll &&
            leavingServiceId &&
            !state.isDefaultServiceSet &&
            (!state.filter.services || state.filter.services.length === 0)
          ) {
            state.filter.services = [leavingServiceId];
          }

          // Синхронизация фильтра цен с обновлённым диапазоном
          if (isCompensation || isServices || isReset) {
            const { minPrice: selectedMin, maxPrice: selectedMax } = state.data;
            const { minPrice: filterMin, maxPrice: filterMax } = state.filter.priceRange;

            // Ничего не выбрано, замена
            if (!selectedMin || !selectedMax) {
              state.filter.priceRange = priceRange;
            } else {
              if (priceRange.minPrice && selectedMin < priceRange.minPrice) {
                state.filter.priceRange.minPrice = priceRange.minPrice;
              }

              if (priceRange.maxPrice && selectedMax > priceRange.maxPrice) {
                state.filter.priceRange.maxPrice = priceRange.maxPrice;
              }

              // Выравнивание активных выбранных значений, если предыдущие равны минимуму/максимуму
              if (filterMin === currentFilterDataRange.minPrice) {
                state.filter.priceRange.minPrice = state.filterData.priceRange.minPrice;
              }
              if (filterMax === currentFilterDataRange.maxPrice) {
                state.filter.priceRange.maxPrice = state.filterData.priceRange.maxPrice;
              }
            }
          }

          // если значения за пределами допустимых - заменим на пределы
          if (priceRange.minPrice !== null && (state.filter.priceRange.minPrice ?? 0) < priceRange.minPrice) {
            state.filter.priceRange.minPrice = priceRange.minPrice;
          }
          if (priceRange.maxPrice !== null && (state.filter.priceRange.maxPrice ?? 0) > priceRange.maxPrice) {
            state.filter.priceRange.maxPrice = priceRange.maxPrice;
          }
          if ((state.filter.priceRange.minPrice ?? 0) > (state.filter.priceRange.maxPrice ?? 0)) {
            state.filter.priceRange.minPrice = priceRange.minPrice;
          }
        }
      )
      .addCase(bookingOfferListFilterFetch.rejected, state => {
        state.filterData = {
          isFetching: false,
          isFetched: false,
          isFailed: true,
          priceRange,
          rzdSocialPackage,
          services: null,
          allServices: null,
          leavingServiceId: null,
        };
      });
  },
});

export const {
  bookingOffersSetPageSize,
  bookingOffersSetPage,
  bookingOffersStartSession,
  bookingOffersSetSort,
  bookingOffersSetSearch,
  bookingOffersSetIsNewFetching,
  bookingOffersSetIsNewSearchFetching,
  bookingOffersSetIsDefaultServiceSet,
  bookingOffersSetCategoryId,
  bookingOffersSetFilterServices,
  bookingOffersSetFilterPriceRange,
  bookingOffersSetCompensation,
} = slice.actions;

export default slice.reducer;
