import { CorpOfferShort } from 'domain/model/corpOffer';
import { EOfferStatus, EOfferType } from 'domain/model/enums';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CmsCorpOfferCollection } from '../corpOffers';
import { corpOffersCollectionSelector } from '../store/selectors';
import { collectionSetState } from '../store/slice';
import { CmsOfferCollectionAdapterProps } from '../types';

/**
 * основной адаптер для коллекции КП
 */
export const CmsCorpOfferCollectionCommonAdapter = (props: CmsOfferCollectionAdapterProps) => {
  const { id, guid } = props;

  const { webAnalytics } = useWebAnalytics();
  const dispatch = useDispatch();

  const pageSize = 24;
  const collectionState = useSelector(corpOffersCollectionSelector(guid, id));
  const visibleCount = collectionState?.visibleCount ?? pageSize;
  const statuses: Nullable<EOfferStatus[]> = [EOfferStatus.Active];

  const onLoadMore = useCallback(() => {
    dispatch(collectionSetState({ guid, offerType: EOfferType.CorpOffer, id, visibleCount: visibleCount + pageSize }));
  }, [dispatch, guid, id, visibleCount, pageSize]);

  const onShowCard = useCallback(
    (offer: TradeOfferShort | CorpOfferShort) => webAnalytics.offerShow(offer.id),
    [webAnalytics]
  );

  return (
    <CmsCorpOfferCollection
      {...props}
      statuses={statuses}
      visibleCount={visibleCount}
      onLoadMore={onLoadMore}
      onShowCard={onShowCard}
    />
  );
};
