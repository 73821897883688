import { AxiosResponse } from 'axios';
import {
  AspOffer,
  AspOfferShort,
  Category,
  CategoryTree,
  EOfferStatus,
  ETradeOfferSortType,
  Pageable,
} from 'domain/model';
import { categoriesBuildTree } from 'presentation/utils/category';
import apiDefinition from '../openApi';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import { api, EAspOfferServicesTag, OfferListRequest, OffersCountRequest, OffersCountResponse } from './index';

export type AspOfferSearchRequest = {
  readonly search: {
    readonly name: Nullable<string>;
  };
  readonly pageSize: number;
};

export type AspOfferCategoriesUsedRequest = {
  readonly name?: Nullable<string>;
  readonly onlyLeafCategories?: Nullable<boolean>;
};

export type AspOfferCategoriesUsedTreeRequest = {
  readonly guid: UUID;
};

export type GetAspOfferDetailsRequest = {
  readonly id: UUID;
};

const defaultAspOfferActiveStatuses: EOfferStatus[] = [EOfferStatus.Active];
const defaultAspOfferValidStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming];

export const aspOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getAspOfferCategories: builder.query<Category[], { ids?: UUID[] }>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ ids }) => {
        return apiDefinition.aspOffer.categories({ ids });
      },
    }),
    getAspOfferCategoriesUsed: builder.query<Category[], AspOfferCategoriesUsedRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ name, onlyLeafCategories }) => {
        return apiDefinition.user.asp.categoriesUsed({
          query: name,
          statuses: defaultAspOfferValidStatuses,
          onlyLeafCategories,
        });
      },
    }),
    getAspOfferCategoriesUsedTree: builder.query<CategoryTree, AspOfferCategoriesUsedTreeRequest>({
      transformResponse: (response: AxiosResponse) => categoriesBuildTree(response.data),
      query: () => {
        return apiDefinition.user.asp.categoriesUsed({
          statuses: defaultAspOfferValidStatuses,
          onlyLeafCategories: false,
        });
      },
    }),
    getAspOfferList: builder.query<Pageable<AspOfferShort>, OfferListRequest>({
      transformResponse: (response: AxiosResponse): Pageable<AspOfferShort> => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ search, page, pageSize, sort = [] }) => {
        const { name, categories, statuses = defaultAspOfferActiveStatuses, partnerId } = search;
        return apiDefinition.user.asp.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          query: name,
          statuses,
          categories,
          partnerId,
        });
      },
      providesTags: [EAspOfferServicesTag.List],
    }),
    getAspOffersCount: builder.query<number, OffersCountRequest>({
      transformResponse: (response: AxiosResponse<OffersCountResponse[]>): number => {
        return response.data[0]?.count ?? 0;
      },
      query: ({ search }) => {
        const { name, categories } = search;
        return apiDefinition.user.asp.count({
          query: name,
          statuses: defaultAspOfferValidStatuses,
          categories,
        });
      },
    }),
    getAspOfferSearch: builder.query<Pageable<AspOffer>, AspOfferSearchRequest>({
      keepUnusedDataFor: 0,
      transformResponse: (response: AxiosResponse): Pageable<AspOffer> => {
        const pageable = getPageableFromResponseHeaders(response);

        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ search, pageSize }) => {
        const { name } = search;

        return apiDefinition.user.asp.all({
          page: 1,
          pageSize,
          query: name,
          statuses: defaultAspOfferValidStatuses,
          sort: [ETradeOfferSortType.Default],
        });
      },
    }),
    getAspOfferDetails: builder.query<AspOffer, GetAspOfferDetailsRequest>({
      query: ({ id }) => apiDefinition.user.asp.one({ id }),
      transformResponse: (response: AxiosResponse) => response.data,
      providesTags: (result, error, args) => [
        { type: EAspOfferServicesTag.Details, id: args.id },
        EAspOfferServicesTag.Details,
      ],
    }),
  }),
});

export const {
  useGetAspOfferListQuery,
  useGetAspOffersCountQuery,
  useGetAspOfferSearchQuery,
  useGetAspOfferCategoriesQuery,
  useGetAspOfferCategoriesUsedQuery,
  useGetAspOfferCategoriesUsedTreeQuery,
  useGetAspOfferDetailsQuery,
} = aspOfferApi;
