import { Tab, useMediaQuery } from '@mui/material';
import { CorpOffer } from 'domain/model/corpOffer';
import { EPartnerType } from 'domain/model/enums';
import { PartnerDesk } from 'domain/model/partner';
import { TabsWrapper } from 'presentation/components/common/wrappers/tabs';
import { PartnerDeskDetailsTabAboutCompany } from 'presentation/features/partnerDesk/details/tabs';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import OfferDetailsTabContacts from '../../../components/detailsTabs/contacts';
import OfferDetailsTabDescription from '../../../components/detailsTabs/description';
import { OfferTabs } from '../../../components/tabs/controls';
import { ECorpOfferDetailsTab } from '../utils';

type CorpOfferTabsProps = {
  readonly tab: ECorpOfferDetailsTab;
  readonly onChange: (event: React.SyntheticEvent, newTab: ECorpOfferDetailsTab) => void;
  readonly corpOffer: CorpOffer;
  readonly partnerDesk: PartnerDesk;
};

const CorpOfferTabs = (props: CorpOfferTabsProps) => {
  const { tab, corpOffer, partnerDesk, onChange } = props;

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <MPGrid
      container
      spacing={isMdUp ? 3 : 2}
    >
      <MPGrid
        item
        zero={12}
      >
        <TabsWrapper>
          <OfferTabs
            value={tab}
            textColor='secondary'
            variant='scrollable'
            onChange={onChange}
          >
            <Tab
              label='Описание'
              value={ECorpOfferDetailsTab.Description}
            />
            {partnerDesk.contacts && corpOffer?.partner?.type !== EPartnerType.InnerPartner && (
              <Tab
                label='Контакты'
                value={ECorpOfferDetailsTab.Contacts}
              />
            )}
            {partnerDesk.aboutCompany && corpOffer?.partner?.type !== EPartnerType.InnerPartner && (
              <Tab
                label='О компании'
                value={ECorpOfferDetailsTab.AboutCompany}
              />
            )}
          </OfferTabs>
        </TabsWrapper>
      </MPGrid>
      <MPGrid
        item
        zero={12}
      >
        {tab === ECorpOfferDetailsTab.Description && <OfferDetailsTabDescription description={corpOffer.description} />}
        {tab === ECorpOfferDetailsTab.Contacts &&
          corpOffer?.partner?.type !== EPartnerType.InnerPartner &&
          partnerDesk.contacts && <OfferDetailsTabContacts contacts={partnerDesk.contacts} />}
        {tab === ECorpOfferDetailsTab.AboutCompany &&
          corpOffer?.partner?.type !== EPartnerType.InnerPartner &&
          partnerDesk.aboutCompany && <PartnerDeskDetailsTabAboutCompany about={partnerDesk.aboutCompany} />}
      </MPGrid>
    </MPGrid>
  );
};

export default CorpOfferTabs;
