import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { EOfferStatus, ProductAttributeValue } from 'domain/model';
import { ApiCancellable, ApiRequestPageable } from '../types';
import { createCancelToken, getUserQueryParams } from '../utils';

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type CategoriesUsedProps = ApiCancellable & {
  readonly query?: Nullable<string>;
  readonly statuses?: Nullable<EOfferStatus[]>;
  readonly partnerId?: Nullable<UUID>;
  readonly city?: Nullable<string>;
  readonly onlyLeafCategories?: Nullable<boolean>;
  readonly collectionId?: Nullable<UUID>;
};

type ProductOfferVariationsRequest = ApiCancellable & {
  readonly productDeskId: Nullable<UUID>;
};

type ProductOffersProps = ApiRequestPageable &
  ApiCancellable & {
    readonly query?: Nullable<string>;
    readonly categories?: Nullable<UUID[]>;
    readonly statuses?: Nullable<EOfferStatus[]>;
    readonly favorite?: Nullable<boolean>;
    readonly attributeValues?: Nullable<ProductAttributeValue[]>;
    readonly minPrice?: Nullable<number>;
    readonly maxPrice?: Nullable<number>;
    readonly partnerId?: Nullable<UUID>;
    readonly brands?: Nullable<string[]>;
    readonly collectionId?: Nullable<string>;
  };

type ProductOffersCountProps = Omit<ProductOffersProps, keyof ApiRequestPageable>;

export type UserProductApi = {
  readonly all: (props: ProductOffersProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
  readonly variations: (props: ProductOfferVariationsRequest) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
  readonly count: (props: ProductOffersCountProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
  readonly categoriesUsed: (props: CategoriesUsedProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
  readonly one: (props: OneProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
};

const productOffer: UserProductApi = {
  all: props => {
    const { categories, statuses, signal, maxPrice, minPrice, brands, partnerId, collectionId, ...queryParams } = props;

    const params = getUserQueryParams(queryParams);

    if (brands) {
      brands.forEach(brand => params.append('brands', brand));
    }

    if (partnerId) {
      params.append('partnerId', partnerId);
    }

    if (maxPrice) {
      params.append('maxPrice', maxPrice.toString(10));
    }

    if (minPrice) {
      params.append('minPrice', minPrice.toString(10));
    }

    if (collectionId) {
      params.append('collectionId', collectionId);
    }

    if (categories) {
      categories.forEach(item => params.append('category', item));
    }

    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }

    return {
      url: `/customers/current/product-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },

  variations: props => {
    const { productDeskId, signal } = props;

    const params = new URLSearchParams();

    if (productDeskId) {
      params.append('productDeskId', productDeskId);
    }

    return {
      url: `/customers/current/product-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },

  count: props => {
    const { categories, statuses, signal, maxPrice, minPrice, brands, partnerId, collectionId, ...queryParams } = props;

    const params = getUserQueryParams(queryParams);

    params.append('resultType', 'count');

    if (brands) {
      brands.forEach(brand => params.append('brands', brand));
    }

    if (partnerId) {
      params.append('partnerId', partnerId);
    }

    if (maxPrice) {
      params.append('maxPrice', maxPrice.toString(10));
    }

    if (minPrice) {
      params.append('minPrice', minPrice.toString(10));
    }

    if (collectionId) {
      params.append('collectionId', collectionId);
    }

    if (categories) {
      categories.forEach(item => params.append('category', item));
    }

    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }

    return {
      url: `/customers/current/product-offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  /** Получение товара по id **/
  one: ({ id }) => {
    return {
      url: `/customers/current/product-offers/${id}`,
      method: 'GET',
    };
  },
  /** Получение списка категорий товаров по фильтрам для покупателя **/
  categoriesUsed: ({ query, statuses, partnerId, city, onlyLeafCategories, signal }) => {
    const params = new URLSearchParams();

    if (query) {
      params.append('q', query);
    }

    if (statuses) {
      statuses.forEach(item => params.append('status', item));
    }

    if (partnerId) {
      params.append('partnerId', partnerId);
    }

    if (city) {
      params.append('places', city);
    }

    if (onlyLeafCategories !== null && onlyLeafCategories !== undefined) {
      params.append('onlyLeafCategories', onlyLeafCategories.toString());
    }

    return {
      url: '/customers/current/product-offers/categories',
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
};

export default productOffer;
