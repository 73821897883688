import { OfferListRequest } from 'data/api';
import { BookingOffersListCategoryId } from 'data/openApi/booking';
import { Fetchable } from 'data/store/types';
import { BookingServicesItem } from 'domain/model';
import { PriceRangeFilter } from 'domain/model';

export type BookingOfferListFilter = {
  readonly services: Nullable<UUID[]>;
  readonly rzdSocialPackage: Nullable<boolean>;
  readonly priceRange: PriceRangeFilter;
};

export enum EBookingOfferListRequestType {
  /** Первичная загрузка фильтра */
  All,
  /** Изменение категории услуг */
  Services,
  /** Изменение цены */
  Price,
  /** Изменение признака КСП */
  Compensation,
  /** Сброс */
  Reset,
}

export type BookingOfferListFilterData = Omit<BookingOfferListFilter, 'services'> &
  Partial<BookingOffersListCategoryId> & {
    /** Текущий зависимый набор услуг */
    readonly services: Nullable<BookingServicesItem[]>;
    /** Id категории Проживание */
    readonly leavingServiceId: Nullable<UUID>;
    /** Тип набора запроса */
    readonly requestType?: EBookingOfferListRequestType;
    /** Полный список услуг для текущей категории */
    readonly allServices?: Nullable<BookingServicesItem[]>;
    /** Наличие и значение первичного фильтра */
    readonly priceRangeRequested?: Nullable<PriceRangeFilter>;
  };

export type BookingOfferListState = {
  readonly guid: Nullable<UUID>;
  // флаг загрузки новой страницы при смене категории
  readonly isNewFetching: boolean;
  // флаг загрузки новой страницы при смене параметров поиска (по имени)
  readonly isNewSearchFetching: boolean;
  // флаг установки категории Проживание
  readonly isDefaultServiceSet: boolean;
  // id текущей категории
  readonly categoryId: Nullable<UUID>;
  // пользовательские аргументы
  readonly data: OfferListRequest & Partial<PriceRangeFilter>;
  // выбранные пользователем (или за него) значения фильтра
  readonly filter: BookingOfferListFilter;
  // данные запросов категорий услуг и диапазона цен
  readonly filterData: Fetchable & BookingOfferListFilterData;
  //  список всех услуг текущей категории */
  readonly services: Nullable<BookingServicesItem[]>;
};
