import { CategoriesApi, CustomersApi } from '@privilege-frontend/api';
import { getQueryErrorByAxiosError } from 'api/utils';
import {
  Category,
  CategoryTree,
  EListResultTypes,
  EOfferStatus,
  EPaymentType,
  ETradeOfferSortType,
  Pageable,
  TradeOffer,
  TradeOfferShort,
} from 'domain/model';
import { categoriesBuildTree } from 'presentation/utils/category';
import { getBaseEndpoint, getPageableFromResponseHeaders } from '../openApi/utils';
import { api, ETradeOfferServicesTag, OfferListRequest, OffersCountRequest } from './index';

export type TradeOfferSearchRequest = {
  readonly search: {
    readonly name: Nullable<string>;
  };
  readonly pageSize: number;
};

export type TradeOfferCategoriesUsedRequest = {
  readonly name?: Nullable<string>;
  readonly onlyLeafCategories?: Nullable<boolean>;
};

export type TradeOfferCategoriesUsedTreeRequest = {
  readonly guid: UUID;
};

export type GetTradeOfferDetailsRequest = {
  readonly id: UUID;
};

const defaultTradeOfferActiveStatuses: EOfferStatus[] = [EOfferStatus.Active];
const defaultTradeOfferValidStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming];
const defaultTradeOfferPaymentTypes: EPaymentType[] = [EPaymentType.Free];

export const tradeOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getTradeOfferCategories: builder.query<Category[], { ids?: UUID[] }>({
      queryFn: async ({ ids }, { signal }) => {
        try {
          const { data } = await CategoriesApi.getTradeOfferCategories(
            getBaseEndpoint(),
            {
              category: ids,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getTradeOfferCategoriesUsed: builder.query<Category[], TradeOfferCategoriesUsedRequest>({
      queryFn: async ({ name, onlyLeafCategories }, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerTradeOfferCategories(
            getBaseEndpoint(),
            {
              q: name,
              status: defaultTradeOfferValidStatuses,
              paymentType: defaultTradeOfferPaymentTypes,
              onlyLeafCategories,
            },
            signal
          );
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getTradeOfferCategoriesUsedTree: builder.query<CategoryTree, TradeOfferCategoriesUsedTreeRequest>({
      queryFn: async (_, { signal }) => {
        try {
          const { data } = await CustomersApi.listCustomerTradeOfferCategories(
            getBaseEndpoint(),
            {
              status: defaultTradeOfferValidStatuses,
              paymentType: defaultTradeOfferPaymentTypes,
              onlyLeafCategories: false,
            },
            signal
          );
          return {
            data: categoriesBuildTree(data),
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getTradeOfferList: builder.query<Pageable<TradeOfferShort>, OfferListRequest>({
      queryFn: async ({ search, page, pageSize, sort = [] }, { signal }) => {
        const { name, categories, statuses = defaultTradeOfferActiveStatuses, partnerId } = search;
        try {
          const response = await CustomersApi.listCustomerTradeOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: page * pageSize,
              sort,
              q: name,
              status: statuses,
              category: categories,
              partnerId,
              paymentType: defaultTradeOfferPaymentTypes,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<TradeOfferShort> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: [ETradeOfferServicesTag.List],
    }),
    getTradeOffersCount: builder.query<number, OffersCountRequest>({
      queryFn: async ({ search }, { signal }) => {
        const { name, categories } = search;
        try {
          const { data } = await CustomersApi.listCustomerTradeOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.Count,
              page: 0,
              size: 1,
              q: name,
              status: defaultTradeOfferValidStatuses,
              category: categories,
              paymentType: defaultTradeOfferPaymentTypes,
            },
            signal
          );
          return {
            data: data?.[0]?.count ?? 0,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getTradeOfferSearch: builder.query<Pageable<TradeOfferShort>, TradeOfferSearchRequest>({
      keepUnusedDataFor: 0,
      queryFn: async ({ search, pageSize }, { signal }) => {
        const { name } = search;
        try {
          const response = await CustomersApi.listCustomerTradeOffersTyped(
            getBaseEndpoint(),
            {
              resultType: EListResultTypes.List,
              page: 0,
              size: pageSize,
              sort: [ETradeOfferSortType.Default],
              q: name,
              status: defaultTradeOfferValidStatuses,
              paymentType: defaultTradeOfferPaymentTypes,
            },
            signal
          );
          const pageable = getPageableFromResponseHeaders(response);
          const data: Pageable<TradeOfferShort> = {
            data: response.data,
            totalCount: pageable.totalCount,
            pageCount: pageable.pageCount,
            page: pageable.page,
          };
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
    }),
    getTradeOfferDetails: builder.query<TradeOffer, GetTradeOfferDetailsRequest>({
      queryFn: async ({ id }, { signal }) => {
        try {
          const { data } = await CustomersApi.getCustomerTradeOfferById(getBaseEndpoint(), id, signal);
          return {
            data,
          };
        } catch (error) {
          return getQueryErrorByAxiosError(error);
        }
      },
      providesTags: (result, error, args) => [
        { type: ETradeOfferServicesTag.Details, id: args.id },
        ETradeOfferServicesTag.Details,
      ],
    }),
  }),
});

export const {
  useGetTradeOfferListQuery,
  useGetTradeOffersCountQuery,
  useGetTradeOfferSearchQuery,
  useGetTradeOfferCategoriesQuery,
  useGetTradeOfferCategoriesUsedQuery,
  useGetTradeOfferCategoriesUsedTreeQuery,
  useGetTradeOfferDetailsQuery,
} = tradeOfferApi;
