import axios, { AxiosResponse } from 'axios';
import { BookingServicesItem, PriceRangeFilter } from 'domain/model';
import { ApiCancellable } from './types';
import { addAdditionalQueryParams, BookingOffersBaseProps, BookingOffersRequestServices } from './user/bookingOffer';
import { createCancelToken, getBaseEndpoint } from './utils';

export type BookingOffersListCategoryId = {
  categoryId: UUID;
};

export type BookingOffersListServicesProps = ApiCancellable &
  BookingOffersListCategoryId &
  Pick<BookingOffersBaseProps, 'query' | 'partnerId' | 'minPrice' | 'maxPrice' | 'isRzdSocialPackage'>;

export type BookingOffersListPriceRangeProps = ApiCancellable &
  BookingOffersListCategoryId &
  Pick<BookingOffersBaseProps, 'query' | 'isRzdSocialPackage'> &
  BookingOffersRequestServices;

type OrderOfferApi = {
  readonly serviceCategories: (props: BookingOffersListServicesProps) => Promise<AxiosResponse<BookingServicesItem[]>>;
  readonly priceRange: (props: BookingOffersListPriceRangeProps) => Promise<AxiosResponse<PriceRangeFilter>>;
};

/**
 * АПИ фильтров букинга
 */
const booking: OrderOfferApi = {
  serviceCategories: ({ signal, query, categoryId, partnerId, minPrice, maxPrice, isRzdSocialPackage }) => {
    const params = new URLSearchParams();
    addAdditionalQueryParams(params, { categories: [categoryId], query, partnerId });

    if (typeof minPrice === 'number') {
      params.append('minPrice', String(minPrice));
    }

    if (typeof maxPrice === 'number') {
      params.append('maxPrice', String(maxPrice));
    }

    if (isRzdSocialPackage) {
      params.append('isRzdSocialPackage', String(isRzdSocialPackage));
    }

    return axios.get(`${getBaseEndpoint()}/customers/current/booking-services/categories`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  priceRange: ({ signal, query, categoryId, services, isRzdSocialPackage }) => {
    const params = new URLSearchParams();
    addAdditionalQueryParams(params, { categories: [categoryId], query });

    if (services) {
      services.forEach(item => params.append('serviceCategory', item));
    }

    if (isRzdSocialPackage) {
      params.append('isRzdSocialPackage', String(isRzdSocialPackage));
    }

    return axios.get(`${getBaseEndpoint()}/customers/current/booking-price-range`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
};

export default booking;
