import OfferListLayout from 'presentation/layouts/offerList';
import { memo } from 'react';
import { BookingOffersSearchListLocationState } from '../types';
import {
  BookingOfferListAsideAdapter,
  BookingOfferListControlAdapter,
  BookingOfferListDataAdapter,
  BookingOfferListLoaderAdapter,
  BookingOfferListStubAdapter,
  BookingOffersHeaderAdapter,
} from './adapters';
import { BookingOfferListContext } from './context';
import useBookingFilter from './hooks/useBookingFilter';
import useBookingOfferListHandlers from './hooks/useBookingOfferList';

const BookingOfferListContainer = memo((props: BookingOffersSearchListLocationState) => {
  const { categoryId, services, rzdSocialPackage, minPrice, maxPrice, guid } = props;

  useBookingFilter(props);

  const handlers = useBookingOfferListHandlers(props);

  const filterCount =
    (services?.length ?? 0) +
    ((minPrice && typeof minPrice !== 'undefined') || (maxPrice && typeof maxPrice !== 'undefined') ? 1 : 0) +
    (rzdSocialPackage ? 1 : 0);

  return (
    <BookingOfferListContext.Provider value={handlers}>
      <OfferListLayout
        header={<BookingOffersHeaderAdapter />}
        aside={
          <BookingOfferListAsideAdapter
            categoryId={categoryId}
            filterCount={filterCount}
          />
        }
        controls={
          <BookingOfferListControlAdapter
            {...props}
            filterCount={filterCount}
          />
        }
        stub={<BookingOfferListStubAdapter categoryId={categoryId} />}
        loader={<BookingOfferListLoaderAdapter {...props} />}
      >
        <BookingOfferListDataAdapter
          guid={guid}
          categoryId={categoryId}
        />
      </OfferListLayout>
    </BookingOfferListContext.Provider>
  );
});

export default BookingOfferListContainer;
