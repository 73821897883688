import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { AspOffer } from 'domain/model/aspOffer';
import { EOfferActivateError } from 'domain/model/enums';
import { createEvent as createEventNeedFillProfile } from 'features/user/events/needFillProfile';
import { FCC, useEffect } from 'react';
import useDialogInHistory from '../../../../../hooks/useDialogInHistory';
import OfferNdflHelpDialog from '../../../components/dialogs/ndfl';
import OfferUnavailableDialog from '../../../components/dialogs/unavailable';
import AspOfferHelpDialog from '../../components/dialogs/help';
import useAspOfferClaimContext from '../provider/useAspOfferClaimContext';
import { EAspOfferClaimDialogTag } from '../types';
import AspOfferClaimDialogsContext, { AspOfferClaimDialogsContextType } from './context';

type AspOfferClaimDialogsProviderProps = {
  readonly aspOffer: AspOffer;
  readonly onShowAllOffers: () => void;
};

const AspOfferClaimDialogsProvider: FCC<AspOfferClaimDialogsProviderProps> = props => {
  const { children, aspOffer, onShowAllOffers } = props;
  const { activation } = useAspOfferClaimContext();
  const { publish } = useBusinessEventBus();

  const {
    open: isHelpDialogOpened,
    onOpen: onOpenHelpDialog,
    onClose: onCloseHelpDialog,
  } = useDialogInHistory({ tag: EAspOfferClaimDialogTag.Help });

  const {
    open: isUnavailableDialogOpened,
    onOpen: onOpenUnavailableDialog,
    onClose: onCloseUnavailableDialog,
  } = useDialogInHistory({ tag: EAspOfferClaimDialogTag.Unavailable });

  const {
    open: isNdflDialogOpened,
    onOpen: onOpenNdflDialog,
    onClose: onCloseNdflDialog,
  } = useDialogInHistory({ tag: EAspOfferClaimDialogTag.Ndfl });

  useEffect(() => {
    if (activation.activationError?.type === EOfferActivateError.InvalidUserStatus) {
      publish(createEventNeedFillProfile({ message: activation.activationError.message }));
    }
  }, [activation.activationError, publish]);

  const value: AspOfferClaimDialogsContextType = {
    openUnavailableDialog: onOpenUnavailableDialog,
    openHelpDialog: onOpenHelpDialog,
    openNdflDialog: onOpenNdflDialog,
    onShowAllOffers,
  };

  return (
    <AspOfferClaimDialogsContext.Provider value={value}>
      {children}
      <OfferNdflHelpDialog
        open={isNdflDialogOpened}
        description={'Текст про НДФЛ'}
        onClose={onCloseNdflDialog}
      />
      <OfferUnavailableDialog
        open={isUnavailableDialogOpened}
        onShowOtherOffers={onShowAllOffers}
        onClose={onCloseUnavailableDialog}
      />
      {aspOffer.promotionType && (
        <AspOfferHelpDialog
          promotionType={aspOffer.promotionType}
          open={isHelpDialogOpened}
          onClose={onCloseHelpDialog}
        />
      )}
    </AspOfferClaimDialogsContext.Provider>
  );
};

export default AspOfferClaimDialogsProvider;
