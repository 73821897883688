import { Text } from '../../components/controls';

const AboutCspWhatIsItInfo = () => {
  return (
    <>
      <Text>
        Это элемент социального пакета, предоставляемый работнику по решению комиссии по распределению путёвок,
        возможность частичной компенсации санаторно-курортного лечения работника и членов его семьи (супруги и дети) на
        объектах санаторно-курортного лечения, определенных компанией.
      </Text>
    </>
  );
};

export default AboutCspWhatIsItInfo;
