import { EOfferStatus } from 'domain/model/enums';
import OfferClaimHelp from '../../../../components/claim/help';
import { dialogContent } from '../../../components/dialogs/help/constants';
import useAspOfferClaimDialogs from '../../dialogProvider/useDialogs';
import useAspOfferClaimContext from '../../provider/useAspOfferClaimContext';

const HelpAdapter = () => {
  const { aspOffer, activation } = useAspOfferClaimContext();
  const { openHelpDialog } = useAspOfferClaimDialogs();

  if (!aspOffer) {
    return null;
  }

  const text = aspOffer.promotionType ? dialogContent[aspOffer.promotionType].title : null;

  const isVisible = aspOffer.status === EOfferStatus.Active && !activation.isLastActivationPending;

  return isVisible ? (
    <OfferClaimHelp
      text={text}
      onClick={openHelpDialog}
    />
  ) : null;
};

export default HelpAdapter;
