import { AxiosResponse } from 'axios';
import {
  Category,
  CategoryTree,
  CorpOffer,
  CorpOfferShort,
  ECorpOfferSortType,
  EOfferStatus,
  Pageable,
} from 'domain/model';
import { categoriesBuildTree } from 'presentation/utils/category';
import apiDefinition from '../openApi';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import { api, ECorpOfferServicesTag, OfferListRequest, OffersCountRequest, OffersCountResponse } from './index';

export type CorpOfferSearchRequest = {
  readonly search: {
    readonly name: Nullable<string>;
  };
  readonly pageSize: number;
};

export type CorpOfferCategoriesUsedRequest = {
  readonly name?: Nullable<string>;
  readonly onlyLeafCategories?: Nullable<boolean>;
};

export type CorpOfferCategoriesUsedTreeRequest = {
  readonly guid: UUID;
};

export type GetCorpOfferDetailsRequest = {
  readonly id: UUID;
};

const defaultCorpOfferActiveStatuses: EOfferStatus[] = [EOfferStatus.Active];
const defaultCorpOfferValidStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming];

const corpOfferCategoriesTransformer = (categories: Category[]): Category[] =>
  categories; /*.filter(item => !!item.parentId)*/

export const corpOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getCorpOfferCategories: builder.query<Category[], { ids?: UUID[] }>({
      transformResponse: (response: AxiosResponse<Category[]>) => corpOfferCategoriesTransformer(response.data),
      query: ({ ids }) => {
        return apiDefinition.corpOffer.categories({ ids });
      },
    }),
    getCorpOfferCategoriesUsed: builder.query<Category[], CorpOfferCategoriesUsedRequest>({
      transformResponse: (response: AxiosResponse<Category[]>) => corpOfferCategoriesTransformer(response.data),
      query: ({ name, onlyLeafCategories }) => {
        return apiDefinition.user.corp.categoriesUsed({
          query: name,
          statuses: defaultCorpOfferValidStatuses,
          onlyLeafCategories,
        });
      },
    }),
    getCorpOfferCategoriesUsedTree: builder.query<CategoryTree, CorpOfferCategoriesUsedTreeRequest>({
      transformResponse: (response: AxiosResponse<Category[]>) => categoriesBuildTree(response.data),
      query: () => {
        return apiDefinition.user.corp.categoriesUsed({
          statuses: defaultCorpOfferValidStatuses,
          onlyLeafCategories: false,
        });
      },
    }),
    getCorpOfferList: builder.query<Pageable<CorpOfferShort>, OfferListRequest>({
      transformResponse: (response: AxiosResponse): Pageable<CorpOfferShort> => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ search, page, pageSize, sort }) => {
        const { name, categories, statuses = defaultCorpOfferActiveStatuses, partnerId } = search;
        return apiDefinition.user.corp.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          query: name,
          statuses,
          categories,
          partnerId,
        });
      },
      providesTags: [ECorpOfferServicesTag.List],
    }),
    getCorpOffersCount: builder.query<number, OffersCountRequest>({
      transformResponse: (response: AxiosResponse<OffersCountResponse[]>): number => {
        return response.data[0]?.count ?? 0;
      },
      query: ({ search }) => {
        const { name, categories } = search;
        return apiDefinition.user.corp.count({
          query: name,
          statuses: defaultCorpOfferValidStatuses,
          categories,
        });
      },
    }),
    getCorpOfferSearch: builder.query<Pageable<CorpOfferShort>, CorpOfferSearchRequest>({
      keepUnusedDataFor: 0,
      transformResponse: (response: AxiosResponse): Pageable<CorpOfferShort> => {
        const pageable = getPageableFromResponseHeaders(response);

        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ search, pageSize }) => {
        const { name } = search;

        return apiDefinition.user.corp.all({
          page: 1,
          pageSize,
          query: name,
          statuses: defaultCorpOfferValidStatuses,
          sort: [ECorpOfferSortType.Default],
        });
      },
    }),
    getCorpOfferDetails: builder.query<CorpOffer, GetCorpOfferDetailsRequest>({
      query: ({ id }) => apiDefinition.user.corp.one({ id }),
      transformResponse: (response: AxiosResponse) => response.data,
      providesTags: (result, error, args) => [
        { type: ECorpOfferServicesTag.Details, id: args.id },
        ECorpOfferServicesTag.Details,
      ],
    }),
  }),
});

export const {
  useGetCorpOfferListQuery,
  useGetCorpOffersCountQuery,
  useGetCorpOfferSearchQuery,
  useGetCorpOfferCategoriesQuery,
  useGetCorpOfferCategoriesUsedQuery,
  useGetCorpOfferCategoriesUsedTreeQuery,
  useGetCorpOfferDetailsQuery,
} = corpOfferApi;
