import { Link as MUILink } from '@mui/material';
import { BookingOfferShort } from 'domain/model/bookingOffer';
import { OfferTags } from 'domain/model/offer';
import { cspOfferTag } from 'features/offer/booking/utils';
import useTechConfig from 'hooks/useTechConfig';
import OfferListItem, { OfferListItemProps } from 'presentation/features/offer/components/listItem';
import { EAppFeature, ERenderSource } from 'presentation/types';
import { forwardRef, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { getBookingOffersDetailsRoute } from '../../routes';
import BookingListItemInfo from './info';
import BookingListItemPrice from './price';

export type BookingOfferListItemProps = {
  readonly offer: BookingOfferShort;
  readonly favorite?: ReactNode;
  readonly direction?: OfferListItemProps['direction'];
  readonly renderFrom?: ERenderSource;
};

const BookingOfferListItem = forwardRef<any, BookingOfferListItemProps>((props, ref) => {
  const { offer, favorite, direction, renderFrom } = props;

  const { id, images, name, rzdSocialPackage } = offer;

  const { hasFeature } = useTechConfig();

  const isCspTagDisplay = !!rzdSocialPackage && hasFeature(EAppFeature.Csp);

  const finalTags: OfferTags = [];
  if (isCspTagDisplay) {
    finalTags.push(cspOfferTag);
  }

  return (
    <MUILink
      ref={ref}
      component={Link}
      underline='none'
      to={getBookingOffersDetailsRoute({ id, from: renderFrom })}
    >
      <OfferListItem
        images={images}
        description={name ?? ''}
        header={offer.displayPriceItem ? <BookingListItemPrice priceItem={offer.displayPriceItem} /> : null}
        favorite={favorite}
        info={<BookingListItemInfo {...offer} />}
        direction={direction}
        tags={finalTags}
      />
    </MUILink>
  );
});

export default BookingOfferListItem;
