import activation from './activation';
import address from './address';
import aspOffer from './aspOffer';
import booking from './booking';
import cms from './cms';
import corpOffer from './corpOffer';
import files from './files';
import notification from './notification';
import nsi from './nsi';
import partner from './partner';
import product from './productOffer';
import tradeOffer from './tradeOffer';
import user from './user';
import userEvent from './userEvent';

const apiDefinition = {
  files,
  user,
  tradeOffer,
  corpOffer,
  partner,
  notification,
  userEvent,
  activation,
  cms,
  product,
  address,
  booking,
  nsi,
  aspOffer,
};

export default apiDefinition;
