/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { Override } from '../../types.ts';

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

/** Ссылка рекламной кампании */
export interface GenMainAdCampaignLink {
  /**
   * ID рекламной кампании
   * @format uuid
   */
  id: string;
}

/** Основные данные рекламных кампаний */
export interface GenMainAdCampaignData {
  /** Наименование рекламной кампании */
  name: string;
  /** Ссылка на партнера */
  partner: GenMainPartnerLink;
  /**
   * Дата начала
   * @format date
   */
  startDate: string;
  /**
   * Дата окончания
   * @format date
   */
  endDate: string;
  /**
   * Бюджет компании
   * @format int64
   * @min 1
   */
  budget: number;
  /**
   * Список предложений(предложения должны быть одного типа)
   * @maxItems 4
   * @minItems 1
   */
  offers: GenMainOfferLink[];
}

/** список кампаний либо их количество */
export type GenMainAdCampaignShortResponse = GenMainBaseAdCampaignShortResponse &
  (
    | GenMainBaseAdCampaignShortResponseDiscriminatorMapping<'Customer', GenMainAdCampaignShort>
    | GenMainBaseAdCampaignShortResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** Рекламная кампания */
export type GenMainAdCampaignShort = Override<
  GenMainAdCampaignLink & GenMainAdCampaignData & GenMainDiscriminatorObject,
  {
    /** Партнёр */
    partner: GenMainPartnerView | null;
    /**
     * Тип рекламной кампании
     * * SEARCH - Поиск
     * * CATALOG - Каталог
     */
    type: GenMainAdCampaignType;
    /**
     * Тип предложения
     * * TRADE_OFFER - торговое предложение
     * * CORP_OFFER - корпоративное предложение
     * * PRODUCT_OFFER - товарное предложение
     * * BOOKING_OFFER - Объект Досуга и Отдыха
     */
    offerType: GenMainOfferType;
    /**
     * Статус рекламной кампании
     * * ACTIVE - Активно
     * * UPCOMING - Предстоящее
     * * PAUSED - Приостановлено
     * * ARCHIVED - Архив
     */
    status: GenMainAdCampaignStatus;
    /**
     * Количество предложений в кампании
     * @format int64
     */
    offersCount: number;
    /**
     * Количество показов
     * @format int64
     */
    showCount: number;
    /**
     * Количество просмотров
     * @format int64
     */
    viewCount: number;
  }
>;

/** Рекламная кампания */
export type GenMainAdCampaign = Override<
  GenMainAdCampaignShort,
  {
    /**
     * список предложений
     * @maxItems 4
     */
    offers: GenMainOfferFull[] | null;
    /**
     * список ключевых слов в продвижении
     * @maxItems 20
     */
    keywords: string[] | null;
  }
>;

/**
 * Тип рекламной кампании
 * * SEARCH - Поиск
 * * CATALOG - Каталог
 */
export enum GenMainAdCampaignType {
  Search = 'SEARCH',
  Catalog = 'CATALOG',
}

/**
 * Статус рекламной кампании
 * * ACTIVE - Активно
 * * UPCOMING - Предстоящее
 * * PAUSED - Приостановлено
 * * ARCHIVED - Архив
 */
export enum GenMainAdCampaignStatus {
  Active = 'ACTIVE',
  Upcoming = 'UPCOMING',
  Paused = 'PAUSED',
  Archived = 'ARCHIVED',
}

/** Запрос на создание рекламной кампании */
export type GenMainCreateAdCampaignRequest = GenMainBaseCreateAdCampaignRequest &
  (
    | GenMainBaseCreateAdCampaignRequestDiscriminatorMapping<
        'CreateAdCatalogCampaignRequest',
        GenMainCreateAdCatalogCampaignRequest
      >
    | GenMainBaseCreateAdCampaignRequestDiscriminatorMapping<
        'CreateAdSearchCampaignRequest',
        GenMainCreateAdSearchCampaignRequest
      >
  );

/** Запрос на создание рекламной кампании по каталогу */
export type GenMainCreateAdCatalogCampaignRequest = GenMainDiscriminatorObject & GenMainAdCampaignData;

/** Запрос на создание рекламной кампании по поиску */
export type GenMainCreateAdSearchCampaignRequest = Override<
  GenMainDiscriminatorObject & GenMainAdCampaignData,
  {
    /**
     * список ключевых слов в продвижении
     * @maxItems 20
     * @minItems 1
     */
    keywords: string[];
  }
>;

/** Действия с объектом - рекламной кампанией */
export type GenMainAdCampaignCommand = GenMainBaseAdCampaignCommand &
  (
    | GenMainBaseAdCampaignCommandDiscriminatorMapping<'AdCampaignPauseCommand', GenMainAdCampaignPauseCommand>
    | GenMainBaseAdCampaignCommandDiscriminatorMapping<'AdCampaignArchiveCommand', GenMainAdCampaignArchiveCommand>
    | GenMainBaseAdCampaignCommandDiscriminatorMapping<'AdCampaignRenewCommand', GenMainAdCampaignRenewCommand>
    | GenMainBaseAdCampaignCommandDiscriminatorMapping<
        'AdSearchCampaignUpdateCommand',
        GenMainAdSearchCampaignUpdateCommand
      >
    | GenMainBaseAdCampaignCommandDiscriminatorMapping<
        'AdCatalogCampaignUpdateCommand',
        GenMainAdCatalogCampaignUpdateCommand
      >
  );

/** Команда на обновление рекламной кампании по поиску */
export type GenMainAdSearchCampaignUpdateCommand = GenMainCreateAdSearchCampaignRequest;

/** Команда на обновление рекламной кампании по каталогу */
export type GenMainAdCatalogCampaignUpdateCommand = GenMainCreateAdCatalogCampaignRequest;

/** Команда на приостановку рекламной кампании */
export type GenMainAdCampaignPauseCommand = GenMainDiscriminatorObject;

/** Команда на архивацию рекламной кампании */
export type GenMainAdCampaignArchiveCommand = GenMainDiscriminatorObject;

/** Команда на возобновление приостановленной рекламной кампании */
export type GenMainAdCampaignRenewCommand = GenMainDiscriminatorObject;

/** Класс для структур с наследованием */
export interface GenMainDiscriminatorObject {
  /** дискриминатор - техническое поле */
  discriminator: string | null;
}

/**
 * ID фин транзакции
 * @format uuid
 */
export type GenMainTransactionId = string;

/**
 * ID пользователя
 * @format uuid
 */
export type GenMainUserId = string;

/** ссылка на пользователя */
export interface GenMainUserLink {
  /** ID пользователя */
  id: GenMainUserId;
}

/**
 * Статус пользователя: ENABLED - активен + заполнены все обязательные поля NOT_FILLED - не заполнен профиль DISABLED - заблокирован DELETED - удалён
 * @default "ENABLED"
 */
export enum GenMainUserStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Deleted = 'DELETED',
  NotFilled = 'NOT_FILLED',
}

/** Основные данные пользователя, доступные изменению */
export interface GenMainMutableUserData {
  /** Телефон. Указывается в формате ITU-T E.164, например 79000000000 */
  phone: string | null;
  /** Фамилия */
  lastName: string | null;
  /** Имя */
  firstName: string | null;
  /** Отчество */
  middleName: string | null;
  /** структура для ссылки и описания ресурса(файла) */
  photo: GenMainFileLink | null;
  /**
   * Дата рождения
   * @format date
   */
  birthDate: string | null;
  /** Пол */
  gender: GenMainGender | null;
  /** Ссылка на объект адреса */
  locality: GenMainAddressObjectLink | null;
}

/** Основные данные пользователя */
export type GenMainUserData = Override<
  GenMainMutableUserData,
  {
    /**
     * email
     * @format email
     */
    email: string;
  }
>;

/** Пользователь(сокращённая информация) */
export type GenMainUserShort = Override<
  UtilRequiredKeys<GenMainUserLink, 'id'> & GenMainUserData,
  {
    /** Статус пользователя: ENABLED - активен + заполнены все обязательные поля NOT_FILLED - не заполнен профиль DISABLED - заблокирован DELETED - удалён */
    status: GenMainUserStatus;
    /** Адрес */
    locality: GenMainAddress | null;
    /**
     * id учетной записи в kc
     * @format uuid
     */
    accountId: string | null;
    /** Табельный номер сотрудника */
    pernr: string | null;
    /** Подтвержден ли email пользователя */
    emailVerified: boolean | null;
    /** Дорога */
    road: GenMainRoad | null;
    /**
     * Есть ли у пользователя счет в АИС Льготы
     * (на данный момент - получил ли пользователь бонус за регистрацию)
     * @default false
     */
    hasBenefit: boolean | null;
    /**
     * Есть ли у пользователя компенсируемый соц. пакет РЖД
     * @default false
     */
    hasRzdSocialPackage: boolean | null;
    /** Организация клиент */
    org: GenMainClientOrgShort | null;
  }
>;

/** Пользователь */
export type GenMainUser = Override<
  GenMainAuditEntity & GenMainUserShort & GenMainDiscriminatorObject,
  {
    /** Роли пользователя */
    roles: string[] | null;
    /** Табельный номер сотрудника корпорации */
    pernr: string | null;
    /** Настройки уведомлений */
    notificationSettings: GenMainNotificationSetting[] | null;
    /** Адрес */
    location: GenMainAddress | null;
    /**
     * Дата последнего входа в систему
     * @format date-time
     */
    lastLoggedInAt: string | null;
  }
>;

/** Команда на изменение/подтверждение почты */
export interface GenMainUserChangeOrConfirmEmailCommand {
  /**
   * новый email пользователя или его неподтвержденный email
   * @format email
   */
  email: string;
}

/** Команда на подтверждение кода */
export interface GenMainSubmitOtpCodeCommand {
  /** код подтверждения */
  code: string;
}

/** Ответ на запрос изменения почту */
export interface GenMainUserChangeOrConfirmEmailResponse {
  /** Новый, еще неподтвержденный email */
  newEmail: string | null;
  /** Текущий email пользователя */
  currentEmail: string | null;
  /**
   * Время, после которого можно будет отправить следующий запрос на изменение почты(переотправку кода)
   * @format date-time
   */
  nextAttempt: string | null;
  /**
   * Идентификатор кода
   * @format uuid
   */
  otpId: string;
  /**
   * время жизни кода в секундах
   * @format int64
   */
  otpTtl: number;
}

/** список пользователей либо их количество */
export type GenMainUserResponse = GenMainBaseUserResponse &
  (
    | GenMainBaseUserResponseDiscriminatorMapping<'User', GenMainUser>
    | GenMainBaseUserResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/**
 * ID назначенного соц пакета РЖД
 * @format uuid
 */
export type GenMainSocialPackageId = string;

/** Ссылка на объект соц пакета РЖД */
export interface GenMainSocialPackageLink {
  /** ID назначенного соц пакета РЖД */
  id: GenMainSocialPackageId;
}

/** Соц. пакет РЖД */
export type GenMainRzdSocialPackage = Override<
  UtilRequiredKeys<GenMainSocialPackageLink, 'id'> & GenMainAuditEntity & GenMainDiscriminatorObject,
  {
    /**
     * Статус Соц. пакета:
     * * APPOINTED - КСП назначен в РЖД (approv = 0/1)
     * * READY - Пакет готов к активации пользователем (Можно показать пользователю, лимиты распределены и сделаны другие необходимые действия и проверки с данными пакета)
     * * UPCOMING - Предстоящий - все согласовано, пользователь согласился с пакетом, пакет ждет своей даты
     * * ACTIVE - Активный - все согласовано, пользователь согласился с пакетом, пакет активен по датам
     * * ARCHIVED - Архив - отказ пользователя, закончилась дата, отзыв пакета работодателем
     */
    status: GenMainSocialPackageStatus;
    /**
     * Дата выставления последнего статуса
     * @format date-time
     */
    lastStatusDate: string;
    /** Комментарий к последнему статусу */
    lastStatusComment: string | null;
    /** Тип причины отклонения соц пакета РЖД */
    rejectionReason: GenMainSocialPackageRejectionReasonType | null;
    /**
     * Согласие работника c соцпакетом:
     * * AGREED - согласился
     * * REFUSED - отказался
     */
    employeeAgreement: GenMainSocialPackageEmployeeAgreement | null;
    /**
     * Дата согласия/отказа(НА ВИТРИНЕ) работника от соц. пакета
     * Если из АСУТР приходит пакет с которым уже согласился работник(approv=1),
     * то при попытке админа перевести пакет в READY - он  перейдет в статус ACTIVE или UPCOMING
     * при этом employeeAgreement и employeeAgreementDate останется null.
     * Затем с фронта когда-то должно прийти согласие, которое проставит его в Витрине(но ничего не отправит в АСУТР)
     * @format date-time
     */
    employeeAgreementDate: string | null;
    /**
     * Дата оповещения пользователя со стороны Витрины о назначении соц. пакета
     * @format date-time
     */
    employeeNotificationDate: string | null;
    /** Значащая категория работников РЖД (Самонаполняющийся справочник с доп инфо) */
    employeeCategory: GenMainRzdEmployeeCategory;
    /**
     * Назначенный общий лимит в рублях (BaseSum)
     * @min 1
     * @multipleOf 0.01
     */
    maxLimit: number;
    /**
     * Дата c которой действует пакет
     * @format date
     */
    startDate: string;
    /**
     * Год действия пакета
     * Правило уникальности: у пользователя только один пакет в году
     * @format int32
     */
    year: number;
    /**
     * Дата до которой действует пакет (SignfEnd)
     * @format date
     */
    endDate: string;
    /**
     * Распределенный максимальный лимит на личный отдых
     * @min 0
     * @multipleOf 0.01
     */
    limitToSelfRest: number | null;
    /**
     * Распределенный максимальный лимит на отдых семьи
     * @min 0
     * @multipleOf 0.01
     */
    limitToFamilyRest: number | null;
    /**
     * Признак того, что в пакете есть лимиты на отдых
     * Вычисляется на уровне маппера по формуле limitToSelfRest>0 || limitToFamilyRest>0
     * @default false
     */
    hasRestLimit: boolean;
    /** Покупатель */
    employee: GenMainCustomer | null;
    /**
     * Статус АСУТР
     * @format int32
     */
    asutrStatus: number | null;
  }
>;

/**
 * Согласие работника c соцпакетом:
 * * AGREED - согласился
 * * REFUSED - отказался
 */
export enum GenMainSocialPackageEmployeeAgreement {
  Agreed = 'AGREED',
  Refused = 'REFUSED',
}

/**
 * Статус Соц. пакета:
 * * APPOINTED - КСП назначен в РЖД (approv = 0/1)
 * * READY - Пакет готов к активации пользователем (Можно показать пользователю, лимиты распределены и сделаны другие необходимые действия и проверки с данными пакета)
 * * UPCOMING - Предстоящий - все согласовано, пользователь согласился с пакетом, пакет ждет своей даты
 * * ACTIVE - Активный - все согласовано, пользователь согласился с пакетом, пакет активен по датам
 * * ARCHIVED - Архив - отказ пользователя, закончилась дата, отзыв пакета работодателем
 */
export enum GenMainSocialPackageStatus {
  Appointed = 'APPOINTED',
  Ready = 'READY',
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

/** Список значащих категорий работников РЖД */
export type GenMainRzdEmployeeCategoryResponse = GenMainBaseRzdEmployeeCategoryResponse &
  (
    | GenMainBaseRzdEmployeeCategoryResponseDiscriminatorMapping<'Counter', GenMainCounter>
    | GenMainBaseRzdEmployeeCategoryResponseDiscriminatorMapping<'RzdEmployeeCategory', GenMainRzdEmployeeCategory>
  );

/**
 * ID категории работников РЖД
 * @format uuid
 */
export type GenMainRzdEmployeeCategoryId = string;

/** Ссылка на категорию работников РЖД */
export interface GenMainRzdEmployeeCategoryLink {
  /** ID категории работников РЖД */
  id: GenMainRzdEmployeeCategoryId;
}

/** Значащая категория работников РЖД (Самонаполняющийся справочник с доп инфо) */
export type GenMainRzdEmployeeCategory = Override<
  GenMainRzdEmployeeCategoryLink & GenMainDiscriminatorObject,
  {
    /** Код группы значимой категории работника (Signf) */
    code: string;
    /** Название категории */
    name: string | null;
  }
>;

/** список соцпакетов либо их количество */
export type GenMainRzdSocialPackageResponse = GenMainBaseRzdSocialPackageResponse &
  (
    | GenMainBaseRzdSocialPackageResponseDiscriminatorMapping<'Counter', GenMainCounter>
    | GenMainBaseRzdSocialPackageResponseDiscriminatorMapping<'RzdSocialPackage', GenMainRzdSocialPackage>
  );

/** Команды на массовые действия с соцпакетами со стороны админов */
export type GenMainSocialPackagesAdminAction = GenMainBaseSocialPackagesAdminAction &
  (
    | GenMainBaseSocialPackagesAdminActionDiscriminatorMapping<
        'SocialPackagesAllocateLimitCommand',
        GenMainSocialPackagesAllocateLimitCommand
      >
    | GenMainBaseSocialPackagesAdminActionDiscriminatorMapping<
        'SocialPackagesActivationCommand',
        GenMainSocialPackagesActivationCommand
      >
    | GenMainBaseSocialPackagesAdminActionDiscriminatorMapping<
        'SocialPackagesArchiveCommand',
        GenMainSocialPackagesArchiveCommand
      >
    | GenMainBaseSocialPackagesAdminActionDiscriminatorMapping<
        'SocialPackagesBackToAppointedCommand',
        GenMainSocialPackagesBackToAppointedCommand
      >
  );

/** Действия с соцпакетом со стороны админов */
export type GenMainSocialPackageAdminAction = GenMainBaseSocialPackageAdminAction &
  (
    | GenMainBaseSocialPackageAdminActionDiscriminatorMapping<
        'SocialPackageAllocateLimitCommand',
        GenMainSocialPackageAllocateLimitCommand
      >
    | GenMainBaseSocialPackageAdminActionDiscriminatorMapping<
        'SocialPackageActivationCommand',
        GenMainSocialPackageActivationCommand
      >
    | GenMainBaseSocialPackageAdminActionDiscriminatorMapping<
        'SocialPackageArchiveCommand',
        GenMainSocialPackageArchiveCommand
      >
    | GenMainBaseSocialPackageAdminActionDiscriminatorMapping<
        'SocialPackageBackToAppointedCommand',
        GenMainSocialPackageBackToAppointedCommand
      >
  );

/** Действия со своим соцпакетом со стороны работника */
export type GenMainSocialPackageEmployeeAction = GenMainBaseSocialPackageEmployeeAction &
  GenMainBaseSocialPackageEmployeeActionDiscriminatorMapping<'AgreementCommand', GenMainSocialPackageAgreementCommand>;

/** Команда на согласие/отказ работником со своим соцпакетом */
export type GenMainSocialPackageAgreementCommand = Override<
  GenMainDiscriminatorObject,
  {
    /**
     * Согласие работника c соцпакетом:
     * * AGREED - согласился
     * * REFUSED - отказался
     */
    agreementStatus: GenMainSocialPackageEmployeeAgreement;
  }
>;

/** Команда на обновление лимитов(можно применить в любом статусе пакета, кроме архива) */
export type GenMainSocialPackageAllocateLimitCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /**
     * Распределенный максимальный лимит на личный отдых
     * @min 0
     * @multipleOf 0.01
     */
    limitToSelfRest: number;
    /**
     * Распределенный максимальный лимит на отдых семьи
     * @min 0
     * @multipleOf 0.01
     */
    limitToFamilyRest: number;
  }
>;

/**
 * Массив id соцпакетов
 * @minItems 1
 */
export type GenMainSocialPackageIdArray = GenMainSocialPackageId[];

/** Команда на массовое обновление лимитов (можно применить в любом статусе пакета, кроме архива) */
export type GenMainSocialPackagesAllocateLimitCommand = Override<
  GenMainSocialPackageAllocateLimitCommand,
  {
    /** Массив id соцпакетов */
    socialPackageIds: GenMainSocialPackageIdArray;
  }
>;

/** Команда на активацию соц пакета из статуса APPOINTED */
export type GenMainSocialPackageActivationCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/** Команда на массовую активацию соц пакетов из статуса APPOINTED */
export type GenMainSocialPackagesActivationCommand = Override<
  GenMainSocialPackageActivationCommand,
  {
    /** Массив id соцпакетов */
    socialPackageIds: GenMainSocialPackageIdArray;
  }
>;

/** Команда на перевод соц пакета назад в статус APPOINTED */
export type GenMainSocialPackageBackToAppointedCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/** Команда на массовый перевод соц пакета назад в статус APPOINTED */
export type GenMainSocialPackagesBackToAppointedCommand = Override<
  GenMainSocialPackageActivationCommand,
  {
    /** Массив id соцпакетов */
    socialPackageIds: GenMainSocialPackageIdArray;
  }
>;

/**
 * Команда обновления соц. пакета от АСУТР(эмуляция)
 * * Если работника с таким табельным номером еще нет на Витрине, то нужно заполнить
 * email(необязательно), lastName, firstName, middleName(необязательно), departmentEkasutrId(это ИД из асутр места работы, например 35016676)!
 * * Если работник с таким табельным номером есть, то поля email, lastName, firstName, middleName, departmentEkasutrId
 * заполнять не надо - они игнорируются.
 */
export interface GenMainAsutrSocialPackageCommand {
  /** Табельный номер сотрудника */
  pernr: string;
  /** Код группы значимой категории (Signf) */
  code: string;
  /**
   * Назначенный общий лимит в рублях (BaseSum)
   * @min 1
   * @multipleOf 0.01
   */
  maxLimit: number;
  /**
   * Дата до которой действует пакет (SignfEnd)
   * @format date
   */
  endDate: string;
  /**
   * Статус соцпакета в АСУТР (approv)
   *   •  0 – назначено КСП;
   *   •  1 – КСП утверждено (работник согласился);
   *   •  2 – отказ по дисц. взысканию;
   *   •  5 – отказ по декрету;
   *   •  11 – отказ по причине увольнения работника (выставляется когда уже КСП назначено);
   *   •  12 – отказ по причине увольнения работника до выдачи извещения (выставляется когда на момент назначения КСП работник уже уволен, т.е, КСП сразу создается с отказом);
   *   •  21 – перевод работника в другой филиал;
   *   •  22 – перевод работника на должность, не относящуюся к КСП;
   *   •  24 – отказ по прочим причинам;
   *   •  25 – отказ работника от КСП
   * @format int32
   * @min 0
   * @default 0
   */
  asutrStatus: number;
  /**
   * email
   * @format email
   */
  email: string | null;
  /** Фамилия */
  lastName: string | null;
  /** Имя */
  firstName: string | null;
  /** Отчество */
  middleName: string | null;
  /**
   * ИД департамента работника из АСУТР
   * Реальный пример: 35016676 - Линейный участок № 3 станция Колобовка
   * @default 35016676
   */
  departmentEkasutrId: string | null;
}

/** Тип причины отклонения соц пакета РЖД */
export type GenMainSocialPackageRejectionReasonType = Override<
  UtilRequiredKeys<GenMainOption, 'id' | 'name'>,
  {
    /**
     * Статус соцпакета в АСУТР
     *   •  2 – отказ по дисц. взысканию;
     *   •  5 – отказ по декрету;
     *   •  11 – отказ по причине увольнения работника (выставляется когда уже КСП назначено);
     *   •  12 – отказ по причине увольнения работника до выдачи извещения (выставляется когда на момент назначения КСП работник уже уволен, т.е, КСП сразу создается с отказом);
     *   •  21 – перевод работника в другой филиал;
     *   •  22 – перевод работника на должность, не относящуюся к КСП;
     *   •  24 – отказ по прочим причинам;
     *   •  25 – отказ работника от КСП
     * @format int32
     * @min 0
     * @default 24
     */
    code: number | null;
  }
>;

/** Тип причины отклонения соц пакета РЖД */
export interface GenMainSocialPackageRejectionReasonTypeLink {
  /** @format uuid */
  id: string;
}

/** Архивация пакета */
export type GenMainSocialPackageArchiveCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /** Тип причины отклонения соц пакета РЖД */
    reason: GenMainSocialPackageRejectionReasonTypeLink;
    /** Комментарий */
    comment: string | null;
  }
>;

/** Команда на массовый перевод соц пакетов в статус ARCHIVE */
export type GenMainSocialPackagesArchiveCommand = Override<
  GenMainSocialPackageArchiveCommand,
  {
    /** Массив id соцпакетов */
    socialPackageIds: GenMainSocialPackageIdArray;
  }
>;

/** Базовые роли пользователя системы */
export enum GenMainUserRole {
  Customer = 'customer',
  AdminMp = 'admin_mp',
  AdminPartner = 'admin_partner',
  Manager = 'manager',
}

/** Пол */
export enum GenMainGender {
  Male = 'MALE',
  Female = 'FEMALE',
}

/** Ссылка на документ с которым должен ознакомиться пользователь */
export interface GenMainUserAgreementDocLink {
  /**
   * Ид документа для ознакомления пользователем
   * @format uuid
   */
  id: string;
}

/** Документ с которым должен ознакомиться пользователь */
export type GenMainUserAgreementDoc = Override<
  UtilRequiredKeys<GenMainUserAgreementDocLink, 'id'>,
  {
    /** Название документа */
    name: string;
    /**
     * Ссылка на документ
     * @format uri
     */
    url: string;
  }
>;

/**
 * Согласие пользователя с юр.документом
 * agreementDate - дата согласия пользователя с данным документом
 * если agreementDate не заполнено, значит пользователь еще не согласился
 * с ним
 */
export interface GenMainUserAgreement {
  /** Документ с которым должен ознакомиться пользователь */
  doc: GenMainUserAgreementDoc;
  /**
   * Дата согласия с документом(неквалифицированной электронной подписи)
   * Если дата не заполнена, пользователь не согласился с данной версией документа
   * @format date-time
   */
  agreementDate: string | null;
}

/** Согласие текущего пользователя с юр.документами */
export interface GenMainUserAgreementRequest {
  /** @minItems 1 */
  docs: GenMainUserAgreementDocLink[];
}

/** Зарос на отзыв согласий текущего пользователя с юр.документами */
export type GenMainUserRevokeAgreementRequest = UtilRequiredKeys<GenMainUserAgreementRequest, 'docs'>;

/** Покупатель */
export type GenMainCustomer = GenMainDiscriminatorObject & GenMainUser;

/** список покупателей либо их количество */
export type GenMainCustomerResponse = GenMainBaseCustomerResponse &
  (
    | GenMainBaseCustomerResponseDiscriminatorMapping<'Customer', GenMainCustomer>
    | GenMainBaseCustomerResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/**
 * ID организации
 * @format uuid
 */
export type GenMainClientOrgId = string;

/** Статус организации в ситеме */
export enum GenMainClientOrgStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

/** Команда на создание орг */
export interface GenMainClientOrgCreateCommand {
  /** Название организации */
  name: string;
  /** структура для ссылки и описания ресурса(файла) */
  logo: GenMainFileLink | null;
  /**
   * домен для SaaS
   * @format hostname
   * @pattern ^((?!-)[0-9a-zA-Z-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$
   */
  domain: string | null;
  /**
   * Код организации(== коду группы в кс)
   * После создания изменению не подлежит
   * @max 10
   */
  code: string;
  /** структура для ссылки и описания ресурса(файла) */
  favicon: GenMainFileLink | null;
  colorBrand: string | null;
  colorPrimary: string | null;
}

/** Действия с объектом - клиентом организации */
export type GenMainClientOrgAction = GenMainBaseClientOrgAction &
  (
    | GenMainBaseClientOrgActionDiscriminatorMapping<'ClientOrgUpdateCommand', GenMainClientOrgUpdateCommand>
    | GenMainBaseClientOrgActionDiscriminatorMapping<'ClientOrgBlockCommand', GenMainClientOrgBlockCommand>
    | GenMainBaseClientOrgActionDiscriminatorMapping<'ClientOrgUnblockCommand', GenMainClientOrgUnblockCommand>
  );

/** Команда на обновление орг */
export type GenMainClientOrgUpdateCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /** Название организации */
    name: string;
    /** структура для ссылки и описания ресурса(файла) */
    logo: GenMainFileLink | null;
    /** домен для SaaS */
    domain: string | null;
    /** структура для ссылки и описания ресурса(файла) */
    favicon: GenMainFileLink | null;
    colorBrand: string | null;
    colorPrimary: string | null;
  }
>;

/** Блокировка организации */
export type GenMainClientOrgBlockCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/** Разблокировка организации */
export type GenMainClientOrgUnblockCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/** Ссылка на организацию клиент */
export interface GenMainClientOrgLink {
  /** ID организации */
  id: GenMainClientOrgId;
}

/** список организаций либо их количество */
export type GenMainClientOrgsResponse = GenMainBaseClientOrgsResponse &
  (
    | GenMainBaseClientOrgsResponseDiscriminatorMapping<'ClientOrg', GenMainClientOrg>
    | GenMainBaseClientOrgsResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** Организация клиент */
export type GenMainClientOrgShort = Override<
  UtilRequiredKeys<GenMainClientOrgLink, 'id'>,
  {
    /** Название организации */
    name: string;
    /**
     * Код организации(== коду группы в кс)
     * После создания изменению не подлежит
     * @max 10
     */
    code: string;
    /** Домен для SaaS */
    domain: string | null;
    /** структура для ссылки и описания ресурса(файла) */
    logo: GenMainFileLink | null;
    /** структура для ссылки и описания ресурса(файла) */
    favicon: GenMainFileLink | null;
    colorBrand: string | null;
    colorPrimary: string | null;
  }
>;

/** Организация клиент */
export type GenMainClientOrg = Override<
  GenMainDiscriminatorObject & GenMainClientOrgShort & GenMainAuditEntity,
  {
    /** Статус организации в ситеме */
    status: GenMainClientOrgStatus;
    /** Можно ли редактировать организацию и реестр ее пользователей вручную */
    readOnly: boolean | null;
    /**
     * Число пользователей организации
     * @format int64
     * @min 0
     * @default 0
     */
    membersCount: number | null;
  }
>;

/**
 * ID партнёра
 * @format uuid
 */
export type GenMainPartnerId = string;

/**
 * ID группы пользователей
 * @minLength 1
 */
export type GenMainUserGroupId = string;

/**
 * Текст
 * @minLength 1
 */
export type GenMainString = string;

/** Тип партнёра */
export enum GenMainPartnerType {
  InnerPartner = 'INNER_PARTNER',
  ExternalPartner = 'EXTERNAL_PARTNER',
}

/**
 * Статус партнёра
 * @default "ENABLED"
 */
export enum GenMainPartnerStatus {
  Unverified = 'UNVERIFIED',
  ToVerification = 'TO_VERIFICATION',
  OnVerification = 'ON_VERIFICATION',
  Rejected = 'REJECTED',
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Deleted = 'DELETED',
}

/** Основные данные партнёра */
export interface GenMainPartnerData {
  /** Наименование партнёра */
  name: string | null;
  /** ИНН */
  inn: string | null;
  /** структура для ссылки и описания ресурса(файла) */
  innCert: GenMainFileLink | null;
  /** расчётный счёт */
  checkingAccount: string | null;
  /** БИК */
  bic: string | null;
  /** Система налогообложения */
  taxSystem: GenMainTaxSystem | null;
  /** структура для ссылки и описания ресурса(файла) */
  usnDoc: GenMainFileLink | null;
  /** ФИО представителя партнёра */
  ownerFullName: string | null;
  /** сайт компании */
  website: string | null;
  /** контактный телефон */
  phone: string | null;
  /**
   * Электронная почта
   * @format email
   */
  email: string | null;
  /** Ссылка на объект адреса */
  address: GenMainAddressObjectLink | null;
  /** Регистрационные данные партнёра */
  regInfo: GenMainPartnerRegData | null;
  agreements: GenMainFileLink[] | null;
}

/** Партнёр + данные админа партнёра */
export type GenMainPartnerAdminRequest = Override<
  GenMainPartnerData,
  {
    /** Тип партнёра */
    type: GenMainPartnerType | null;
    /** Основные данные пользователя */
    owner: GenMainUserData;
  }
>;

/** Ссылка на партнера */
export interface GenMainPartnerLink {
  /** ID партнёра */
  id: GenMainPartnerId;
}

/**
 * ключ карты по имени партнера
 * @minLength 1
 */
export type GenMainPartnerNameMapKey = string;

/** Партнёр */
export type GenMainPartnerShort = Override<
  GenMainPartnerData & GenMainDiscriminatorObject,
  {
    /** ID партнёра */
    id: GenMainPartnerId;
    /** Статус партнёра */
    status: GenMainPartnerStatus;
    /**
     * Код партнёра (автоинкремент)
     * @format int64
     */
    code: number;
    /** Структура для выдачи элемента справочника */
    creator: GenMainOption;
    /** Пользователь(сокращённая информация) */
    owner: GenMainUserShort | null;
    /** Пользователь(сокращённая информация) */
    partnerReviewer: GenMainUserShort | null;
    permissions: GenMainPartnerPermission[] | null;
    /** Комментарий к последнему статусу */
    lastStatusComment: string | null;
    /**
     * Дата выставления последнего статуса
     * @format date-time
     */
    lastStatusDate: string | null;
    /** Тип партнёра */
    type: GenMainPartnerType | null;
    /** структура для ссылки и описания ресурса(файла) */
    logo: GenMainFileLink | null;
    /** Адрес */
    address: GenMainAddress | null;
  }
>;

/** список партнёров либо их количество */
export type GenMainPartnerShortResponse = GenMainBasePartnerShortResponse &
  (
    | GenMainBasePartnerShortResponseDiscriminatorMapping<'PartnerShort', GenMainPartnerShort>
    | GenMainBasePartnerShortResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/**
 * Разрешения партнера:
 * * TRADE_OFFER_PUBLIC - использование торговых предложений с публикацией
 * * CORP_OFFER_PUBLIC -  использование корпоративных предложений с публикацией
 * * PRODUCT_OFFER_PUBLIC - использование товарных предложений с публикацией
 * * BOOKING_OFFER_PUBLIC - разрешение на доступ к разделу Досуг и Отдых
 */
export enum GenMainPartnerPermission {
  TradeOfferPublic = 'TRADE_OFFER_PUBLIC',
  CorpOfferPublic = 'CORP_OFFER_PUBLIC',
  ProductOfferPublic = 'PRODUCT_OFFER_PUBLIC',
  BookingOfferPublic = 'BOOKING_OFFER_PUBLIC',
}

/** Партнер */
export type GenMainPartnerFull = Override<
  GenMainAuditEntity & GenMainPartnerShort,
  {
    /** Менеджеры организации */
    managers: GenMainUserShort[] | null;
    /** Тип причины отклонения партнёра */
    rejectionReasonType: GenMainPartnerRejectionReasonType | null;
    /** Причина отключения партнера */
    disablingReason: string | null;
    /** Настройки маркетинговых уведомлений */
    marketingDistributionSettings: GenMainNotificationSetting[] | null;
  }
>;

/** Партнёр */
export interface GenMainPartnerView {
  /** ID партнёра */
  id: GenMainPartnerId;
  /** Тип партнёра */
  type: GenMainPartnerType;
  /**
   * Код партнёра (автоинкремент)
   * @format int64
   */
  code: number;
  /** Наименование */
  name: string;
  /** структура для ссылки и описания ресурса(файла) */
  logo: GenMainFileLink | null;
  /** Статус партнёра */
  status: GenMainPartnerStatus;
}

/** Регистрационные данные партнёра */
export type GenMainPartnerRegData = GenMainBasePartnerRegData &
  (
    | GenMainBasePartnerRegDataDiscriminatorMapping<'PartnerCompanyData', GenMainPartnerCompanyData>
    | GenMainBasePartnerRegDataDiscriminatorMapping<'PartnerIndividualData', GenMainPartnerIndividualData>
  );

/** Основные данные партнёра "ООО" */
export type GenMainPartnerCompanyData = Override<
  GenMainDiscriminatorObject,
  {
    /** Юридическое название */
    companyName: string | null;
    /** структура для ссылки и описания ресурса(файла) */
    charterDoc: GenMainFileLink | null;
    /** структура для ссылки и описания ресурса(файла) */
    ceoAppointmentProtocol: GenMainFileLink | null;
    /** КПП */
    kpp: string | null;
    /** ОГРН */
    ogrn: string | null;
    /** структура для ссылки и описания ресурса(файла) */
    ogrnCert: GenMainFileLink | null;
  }
>;

/** Основные данные партнёра "ИП" */
export type GenMainPartnerIndividualData = Override<
  GenMainDiscriminatorObject,
  {
    /** ОГРНИП */
    ogrnip: string | null;
    /** структура для ссылки и описания ресурса(файла) */
    ogrnipCert: GenMainFileLink | null;
    /** структура для ссылки и описания ресурса(файла) */
    passportCopy: GenMainFileLink | null;
  }
>;

/** Основные данные карточки партнера */
export interface GenMainPartnerDeskData {
  /** ID партнёра */
  partnerId: GenMainPartnerId;
  /** О компании */
  aboutCompany: string | null;
  /** структура для ссылки и описания ресурса(файла) */
  image: GenMainFileLink | null;
  /** Контакты партнера */
  contacts: GenMainPartnerContactData[] | null;
}

/** Карточка партнера Id карточки = id партнёра */
export type GenMainPartnerDesk = Override<
  GenMainPartnerDeskData,
  {
    /** ID партнёра */
    id: GenMainPartnerId;
    /** Партнёр */
    partner: GenMainPartnerView | null;
    /** Карточка доступна в витрине (заполнены все поля, выполнены другие условия) */
    active: boolean | null;
    /** Контакты партнера */
    contacts: GenMainPartnerContactDataResponse[] | null;
  }
>;

/** Данные контакта партнера */
export interface GenMainPartnerContactData {
  /** Название контакта */
  name: string;
  /**
   * email
   * @format email
   */
  email: string | null;
  /**
   * Ссылка на форму обратной связи
   * @format url
   */
  feedbackLink: string | null;
  /** Телефон. Указывается в формате ITU-T E.164, например 79000000000 */
  phone: string | null;
  /** Ссылка на объект адреса */
  address: GenMainAddressObjectLink | null;
}

/** Данные контакта партнера */
export type GenMainPartnerContactDataResponse = Override<
  GenMainPartnerContactData,
  {
    /** Адрес */
    address: GenMainAddress | null;
  }
>;

/**
 * ID категории
 * @format uuid
 */
export type GenMainCategoryId = string;

/**
 * ID характеристики продукта
 * @format uuid
 */
export type GenMainProductAttributeId = string;

/**
 * ID значения справочника
 * @format uuid
 */
export type GenMainDictionaryValueId = string;

/**
 * ID значения справочника
 * @format uuid
 */
export type GenMainBrandId = string;

/**
 * ID справочника
 * @format uuid
 */
export type GenMainDictionaryId = string;

/** ID категории */
export interface GenMainCategoryLink {
  /** ID категории */
  id: GenMainCategoryId;
}

/** Основные данные категории */
export interface GenMainCategoryData {
  name: string;
  /** ID категории */
  parentId: GenMainCategoryId | null;
}

/** Категория предложения */
export type GenMainOfferCategory = GenMainCategoryLink & GenMainCategoryData;

/** Минимальная и максимальная цены услуг объектов букинга */
export interface GenMainBookingPriceRange {
  /**
   * Минимальная цена
   * @format int64
   */
  minPrice: number | null;
  /**
   * Максимальная цена
   * @format int64
   */
  maxPrice: number | null;
}

/** Категория товара */
export type GenMainProductCategory = Override<
  GenMainOfferCategory,
  {
    attributes: GenMainProductCategoryAttribute[] | null;
  }
>;

/** Запрос на создание/редактирование категории товара */
export type GenMainProductCategoryRequest = Override<
  UtilRequiredKeys<GenMainCategoryData, 'name'>,
  {
    attributes: GenMainProductCategoryAttributeRequest[] | null;
  }
>;

/** Ссылка на характеристику товара */
export interface GenMainProductAttributeLink {
  /** ID характеристики продукта */
  id: GenMainProductAttributeId;
}

/**
 * Фильтр по значению характеристики товара
 * в value либо ссылка на формализованное значение справочника
 * либо значение для поиска like
 */
export type GenMainProductAttributeFilterValue = Override<
  GenMainProductAttributeLink,
  {
    /** Необязательное значение из справочника value заполнено из справочника когда есть dictionaryValueLink */
    value: GenMainOptionalDictionaryValue | null;
  }
>;

/** Ссылка на справочник */
export interface GenMainDictionaryLink {
  /** ID справочника */
  id: GenMainDictionaryId;
}

/** Зарос на создание справочника */
export interface GenMainDictionaryRequest {
  /** Название справочника */
  name: string;
  /**
   * Тип значения:
   *  file - ссылка на файл в файлсервисе
   */
  type: GenMainValueType;
}

/** Справочник */
export type GenMainDictionary = GenMainDictionaryLink & GenMainDictionaryRequest;

/** Ссылка на значение из справочника */
export interface GenMainDictionaryValueLink {
  /** ID значения справочника */
  id: GenMainDictionaryValueId;
}

/** Зарос на создание элемента справочника */
export interface GenMainDictionaryValueRequest {
  /** значение */
  value: string;
}

/** Элемент справочника */
export type GenMainDictionaryValue = GenMainDictionaryValueLink & GenMainDictionaryLink & GenMainDictionaryValueRequest;

/**
 * Тип значения:
 *  file - ссылка на файл в файлсервисе
 */
export enum GenMainValueType {
  String = 'string',
  Integer = 'integer',
  File = 'file',
}

/** Ссылка на бренд */
export interface GenMainBrandLink {
  /** ID значения справочника */
  id: GenMainBrandId;
}

/** Зарос на создание бренда */
export interface GenMainBrandRequest {
  /** Название */
  name: string | null;
  /** структура для ссылки и описания ресурса(файла) */
  image: GenMainFileLink | null;
}

/** Бренд */
export type GenMainBrand = GenMainBrandLink & GenMainBrandRequest;

/** Характеристика товара Если значение характеристики ограничено справочником, то  в dictionaryLink будет ссылка на справочник */
export interface GenMainProductAttributeRequest {
  /** Название характеристики */
  name: string;
  /** Описание характеристики. Допустим сложный контент с ссылками. */
  description: string | null;
  /** Ссылка на справочник */
  dictionary: GenMainDictionaryLink | null;
  /**
   * Тип значения:
   *  file - ссылка на файл в файлсервисе
   */
  type: GenMainValueType;
  /** Группа характеристик - связанные характеристики Например российский размер одежды и европейский */
  group: GenMainDictionaryValueLink | null;
}

/** Характеристика товара */
export type GenMainProductAttribute = Override<
  GenMainProductAttributeLink & GenMainProductAttributeRequest,
  {
    /** Элемент справочника */
    group: GenMainDictionaryValue | null;
  }
>;

/** Связка характеристика товара в контексте категории товара */
export interface GenMainProductCategoryAttributeRequest {
  /** Ссылка на характеристику товара */
  attribute: GenMainProductAttributeLink;
  /**
   * Признак, что характеристика — набор значений:
   *   - `true` — характеристика — набор значений,
   *   - `false` — характеристика состоит из одного значения.
   * @default false
   */
  collection: boolean | null;
  /**
   * Признак обязательной характеристики:
   *   - `true` — обязательная характеристика,
   *   - `false` — характеристику можно не указывать.
   * @default false
   */
  required: boolean | null;
}

/** Характеристика товара в контексте категории товара */
export interface GenMainProductCategoryAttribute {
  /** Характеристика товара */
  attribute: GenMainProductAttribute;
  /**
   * Признак, что характеристика — набор значений:
   *   - `true` — характеристика — набор значений,
   *   - `false` — характеристика состоит из одного значения.
   * @default false
   */
  collection: boolean | null;
  /**
   * Признак обязательной характеристики:
   *   - `true` — обязательная характеристика,
   *   - `false` — характеристику можно не указывать.
   * @default false
   */
  required: boolean | null;
}

/** Значение характеристики товара */
export interface GenMainProductAttributeValue {
  /** Ссылка на характеристику товара */
  attribute: GenMainProductAttributeLink;
  /** @minItems 1 */
  values: GenMainOptionalDictionaryValue[];
}

/** Необязательное значение из справочника value заполнено из справочника когда есть dictionaryValueLink */
export interface GenMainOptionalDictionaryValue {
  /** Ссылка на значение из справочника */
  dictionaryValue: GenMainDictionaryValueLink | null;
  /** Значение характеристики товара. */
  value: string;
}

/** Тип причины отклонения предложения */
export type GenMainOfferRejectionReasonType = GenMainOption;

export interface GenMainOfferRejectionReasonTypeLink {
  /**
   * Id причины отклонения
   * @format uuid
   */
  id: string;
}

/** Тип причины отклонения партнёра */
export type GenMainPartnerRejectionReasonType = GenMainOption;

/** Тип причины приостановки предложения */
export type GenMainOfferPausedReasonType = GenMainOption & {
  /** причина устанавливается внутрисистемно */
  system: boolean | null;
};

/** Причины отклонения заказа */
export interface GenMainOrderCancellationReasonRequest {
  /** Ссылка на причину отклонения заказа */
  reason: GenMainOrderCancellationReasonTypeLink | null;
  /** Комментарий к причине отклонения */
  comment: string | null;
}

/** Ссылка на причину отклонения заказа */
export interface GenMainOrderCancellationReasonTypeLink {
  /** @format uuid */
  id: string;
}

/**
 * Тип причины отклонения заказа
 * если не указан offerType, то причина для всех заказов
 */
export type GenMainOrderCancellationReasonType = Override<
  UtilRequiredKeys<GenMainOrderCancellationReasonTypeLink, 'id'>,
  {
    /** Название причины */
    name: string;
    /**
     * Тип предложения
     * * TRADE_OFFER - торговое предложение
     * * CORP_OFFER - корпоративное предложение
     * * PRODUCT_OFFER - товарное предложение
     * * BOOKING_OFFER - Объект Досуга и Отдыха
     */
    offerType: GenMainOfferType | null;
  }
>;

export interface GenMainCustomerOrderCancellationReasonTypeLink {
  /**
   * Id причины отклонения
   * @format uuid
   */
  id: string;
}

/**
 * Тип причины отклонения заказа покупателем
 * если не указан offerType то причина для всех заказов
 */
export type GenMainCustomerOrderCancellationReasonType = Override<
  UtilRequiredKeys<GenMainCustomerOrderCancellationReasonTypeLink, 'id'>,
  {
    /** название причины */
    name: string;
    /**
     * Тип предложения
     * * TRADE_OFFER - торговое предложение
     * * CORP_OFFER - корпоративное предложение
     * * PRODUCT_OFFER - товарное предложение
     * * BOOKING_OFFER - Объект Досуга и Отдыха
     */
    offerType: GenMainOfferType | null;
  }
>;

/** Основные данные предложения */
export interface GenMainOfferData {
  /** Наименование предложения */
  name: string | null;
  /**
   * Галерея изображений предложения(порядок в массиве сохраняется)
   * @maxItems 20
   */
  images: GenMainFileLink[] | null;
  /**
   * Ссылка на страницу партнёра
   * @format url
   */
  partnerLink: string | null;
  /**
   * Дата начала срока действия
   * @format date
   */
  startDate: string | null;
  /**
   * Дата окончания срока действия
   * @format date
   */
  endDate: string | null;
  /** Условия */
  conditions: string | null;
  /**
   * ИД внешней системы
   * @deprecated
   * @format uuid
   */
  externalId: string | null;
  /** Описание предложения */
  description: string | null;
}

/** Ссылка на предложение */
export interface GenMainOfferLink {
  /** ID предложения */
  id: GenMainOfferId;
}

/** Сокращенное обобщенное представление предложения */
export interface GenMainShortOfferView {
  /** ID предложения */
  id: GenMainOfferId;
  /**
   * Тип предложения
   * * TRADE_OFFER - торговое предложение
   * * CORP_OFFER - корпоративное предложение
   * * PRODUCT_OFFER - товарное предложение
   * * BOOKING_OFFER - Объект Досуга и Отдыха
   */
  type: GenMainOfferType;
}

/** Сокращенное обобщенное представление предложения */
export type GenMainOfferView = UtilRequiredKeys<GenMainShortOfferView, 'id' | 'type'> & {
  /** Наименование предложения */
  name: string;
  /** код предложения */
  code: string;
  /** Статус сущности с модерацией */
  status: GenMainOfferStatus;
  /** Ссылка на партнера */
  partner: GenMainPartnerLink;
};

/** Основная информация предложения */
export type GenMainOfferDataShort = Override<
  GenMainOfferData & GenMainOfferCountInfo,
  {
    /** ID предложения */
    id: GenMainOfferId;
    /** ID партнёра */
    partnerId: GenMainPartnerId | null;
    /** код предложения */
    code: string | null;
    /**
     * Тип предложения
     * * TRADE_OFFER - торговое предложение
     * * CORP_OFFER - корпоративное предложение
     * * PRODUCT_OFFER - товарное предложение
     * * BOOKING_OFFER - Объект Досуга и Отдыха
     */
    type: GenMainOfferType;
    /** Статус сущности с модерацией */
    status: GenMainOfferStatus;
    /** Партнёр */
    partner: GenMainPartnerView;
    /** Предложение (например, 'До 30%', '-20%' и т.п.) */
    title: string | null;
    /** Категории ТП */
    categories: GenMainOfferCategory[];
    tags: GenMainOfferTag[] | null;
  }
>;

/** Статус сущности с модерацией */
export enum GenMainOfferStatus {
  Draft = 'DRAFT',
  WaitingForModeration = 'WAITING_FOR_MODERATION',
  OnModeration = 'ON_MODERATION',
  Rejected = 'REJECTED',
  Upcoming = 'UPCOMING',
  OutOfStock = 'OUT_OF_STOCK',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Archived = 'ARCHIVED',
}

/** Информация о количестве предложений */
export interface GenMainOfferCountInfo {
  /** Количество предложений */
  offerCount: number | null;
  /** Количество неиспользуемых предложений */
  notUsedOfferCount: number | null;
}

/**
 * Тип оплаты:
 * * FREE бесплатно
 * * BENEFIT_RZD_POINT баллы АИС Льготы
 * * RUB рубли
 * * ASP_RZD_RUB рубли из АСП
 * @default "FREE"
 */
export enum GenMainPaymentType {
  BenefitRzdPoint = 'BENEFIT_RZD_POINT',
  Rub = 'RUB',
  AspRzdRub = 'ASP_RZD_RUB',
  Free = 'FREE',
}

/**
 * Причины недоступности для активации предложения
 * * INVALID_OFFER_STATUS - Предложение не доступно для создания активации из-за своего статуса
 * * PROMOTION_FRESH_OUT - В предложении нет свободных персональных кодов
 * * OFFER_ACTIVATION_ALREADY_EXIST - Предложение уже было активировано, последующие активации недоступны для данного предложения
 * * INAPPROPRIATE_TARGETING - Предложение не доступно для данного пользователя по таргетингу
 * * PROMOTION_TIME_LIMIT - С момента получения последнего кода данным клиентом не прошло достаточно времени
 * * PROMOTION_DAY_COUNT_LIMIT - Кол-во полученных данным клиентом кодов превышает суточный лимит
 * * EMAIL_NOT_VERIFIED - пользователю нужно подтвердить свою почту
 * * INVALID_USER_STATUS - профиль нужно заполнить свой профиль
 */
export enum GenMainOfferUnavailabilityReason {
  InvalidOfferStatus = 'INVALID_OFFER_STATUS',
  PromotionFreshOut = 'PROMOTION_FRESH_OUT',
  OfferActivationAlreadyExist = 'OFFER_ACTIVATION_ALREADY_EXIST',
  InappropriateTargeting = 'INAPPROPRIATE_TARGETING',
  PromotionTimeLimit = 'PROMOTION_TIME_LIMIT',
  PromotionDayCountLimit = 'PROMOTION_DAY_COUNT_LIMIT',
  InvalidUserStatus = 'INVALID_USER_STATUS',
  EmailNotVerified = 'EMAIL_NOT_VERIFIED',
}

/**
 * Причины почему нельзя создать заказ по активации
 * * ORDER_ALREADY_CREATED - по активации уже создан заказ
 * * ACTIVATION_STATUS_PENDING - активация в процессе подтверждения
 * * ACTIVATION_STATUS_REJECTED - активация отклонена
 * * ACTIVATION_EXPIRED - Срок активации данного предложения истек
 * * OTHER - другое
 */
export enum GenMainOrderByActivationUnavailabilityReason {
  OrderAlreadyCreated = 'ORDER_ALREADY_CREATED',
  ActivationStatusPending = 'ACTIVATION_STATUS_PENDING',
  ActivationStatusRejected = 'ACTIVATION_STATUS_REJECTED',
  ActivationExpired = 'ACTIVATION_EXPIRED',
  Other = 'OTHER',
}

/**
 * ID предложения
 * @format uuid
 */
export type GenMainOfferId = string;

/** Основные данные ТП */
export type GenMainTradeOfferData = Override<
  GenMainOfferData,
  {
    /** Предложение (например, 'До 30%', '-20%' и т.п.) */
    title: string | null;
    /** Способ продвижения предложений */
    promotionType: GenMainPromotionType | null;
    /** Значение publicPromotion(реф.ссылка/публичный промокод/код виджета) поле обязательно при promotionType= PUBLIC_PROMOCODE | REFERRAL_LINK | WIDGET для WIDGET возможно ссылка на сам код? */
    publicPromotionValue: string | null;
    /** Опции таргета на сайте */
    target: GenMainTarget | null;
    /**
     * Цена без скидки
     * @format int64
     * @min 1
     */
    originalPrice: number | null;
    /**
     * Цена со скидкой
     * @format int64
     * @min 1
     */
    price: number | null;
    /**
     * Тип оплаты:
     * * FREE бесплатно
     * * BENEFIT_RZD_POINT баллы АИС Льготы
     * * RUB рубли
     * * ASP_RZD_RUB рубли из АСП
     */
    paymentType: GenMainPaymentType | null;
    /**
     * Ид агрегатора кодов(Пока только Дигифт).
     * Нужно для интеграции с агрегаторами
     */
    aggregatorIntegrationId: string | null;
    /**
     * Количество дней после получения активации предложения
     * в течение которых можно получить услугу по предложению
     * @format int32
     * @min 0
     */
    activationExpiryDays: number | null;
  }
>;

/** ТП основная информация */
export type GenMainTradeOfferShort = Override<
  GenMainTradeOfferData & GenMainOfferCountInfo,
  {
    /** ID предложения */
    id: GenMainOfferId;
    /** ID партнёра */
    partnerId: GenMainPartnerId | null;
    /** Категории ТП */
    categories: GenMainOfferCategory[] | null;
    /** Статус сущности с модерацией */
    status: GenMainOfferStatus | null;
    /** код ТП (код партнера-год-номертппорядковый, например 2-21-1) */
    code: string | null;
    /** Партнёр */
    partner: GenMainPartnerView | null;
    /** Структура для выдачи элемента справочника */
    approvingAdmin: GenMainOption | null;
    /** Тип причины приостановки предложения */
    pausedReason: GenMainOfferPausedReasonType | null;
    /**
     * Дата выставления последнего статуса
     * @format date-time
     */
    lastStatusDate: string | null;
    /** Рейтинг */
    rating: number | null;
    /** Структура для выдачи элемента справочника */
    lastStatusAuthor: GenMainOption | null;
    tags: GenMainOfferTag[] | null;
  }
>;

/** Тег предложения на Витрине */
export interface GenMainOfferTag {
  /**
   * Тип тега предложения на Витрине
   * * HIT - хит
   * * NEW - новинка
   * * SALE - распродажа
   */
  type: GenMainOfferTagType | null;
  /** Название */
  name: string | null;
}

/**
 * Тип тега предложения на Витрине
 * * HIT - хит
 * * NEW - новинка
 * * SALE - распродажа
 */
export enum GenMainOfferTagType {
  Hit = 'HIT',
  New = 'NEW',
  Sale = 'SALE',
}

/** ТП представление для покупателя */
export type GenMainCustomerTradeOfferShort = UtilRequiredKeys<GenMainShortOfferView, 'id'> &
  GenMainTradeOfferShort &
  GenMainDiscriminatorObject;

/** список ТП либо их количество */
export type GenMainCustomerTradeOfferShortResponse = GenMainBaseCustomerTradeOfferShortResponse &
  (
    | GenMainBaseCustomerTradeOfferShortResponseDiscriminatorMapping<
        'CustomerTradeOfferShort',
        GenMainCustomerTradeOfferShort
      >
    | GenMainBaseCustomerTradeOfferShortResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** список предложений либо их количество */
export type GenMainCustomerOfferShortResponse = GenMainBaseCustomerOfferShortResponse &
  (
    | GenMainBaseCustomerOfferShortResponseDiscriminatorMapping<'CustomerOfferShort', GenMainCustomerOfferShort>
    | GenMainBaseCustomerOfferShortResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** ТП */
export type GenMainTradeOffer = Override<
  GenMainTradeOfferShort & GenMainAuditEntity & GenMainDiscriminatorObject,
  {
    /**
     * Ссылка на предложение
     * @deprecated
     */
    targetOffer: GenMainOfferLink | null;
    /** Тип причины отклонения предложения */
    rejectionReasonType: GenMainOfferRejectionReasonType | null;
    /** Комментарий к последнему статусу */
    lastStatusComment: string | null;
    /** Опции таргета на сайте */
    target: GenMainTarget | null;
    /** Структура для выдачи элемента справочника */
    moderator: GenMainOption | null;
  }
>;

/** список ТП либо их количество */
export type GenMainTradeOfferResponse = GenMainBaseTradeOfferResponse &
  (
    | GenMainBaseTradeOfferResponseDiscriminatorMapping<'TradeOffer', GenMainTradeOffer>
    | GenMainBaseTradeOfferResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** Предложение любого типа. Полное представление */
export type GenMainOfferFull = GenMainBaseOfferFull &
  (
    | GenMainBaseOfferFullDiscriminatorMapping<'TradeOffer', GenMainTradeOffer>
    | GenMainBaseOfferFullDiscriminatorMapping<'CorpOffer', GenMainCorpOffer>
    | GenMainBaseOfferFullDiscriminatorMapping<'BookingOffer', GenMainBookingOffer>
    | GenMainBaseOfferFullDiscriminatorMapping<'ProductOffer', GenMainProductOffer>
  );

/** ТП */
export type GenMainCustomerTradeOffer = GenMainTradeOffer;

/** запрос на создание ТП */
export type GenMainTradeOfferCreateRequest = GenMainBaseTradeOfferCreateRequest &
  (
    | GenMainBaseTradeOfferCreateRequestDiscriminatorMapping<'TradeOfferDataRequest', GenMainTradeOfferDataRequest>
    | GenMainBaseTradeOfferCreateRequestDiscriminatorMapping<
        'CreateDraftTradeOfferCommand',
        GenMainCreateDraftTradeOfferCommand
      >
  );

/** Допустимые команды к сущности */
export type GenMainTradeOfferCommand = GenMainBaseTradeOfferCommand &
  GenMainBaseTradeOfferCommandDiscriminatorMapping<'NewVersionTradeOfferCommand', GenMainNewVersionTradeOfferCommand>;

/** Запрос на создание черновика ТП */
export type GenMainTradeOfferDataRequest = Override<
  GenMainTradeOfferData & GenMainDiscriminatorObject,
  {
    /** ID партнёра */
    partnerId: GenMainPartnerId | null;
    /** Категории ТП */
    categories: GenMainCategoryLink[] | null;
    /** Опции таргета на сайте */
    target: GenMainTargetRequest | null;
  }
>;

/** Запрос на обновление черновика ТП */
export type GenMainTradeOfferUpdateRequest = GenMainTradeOfferDataRequest;

/** Запрос на создание черновика ТП как копии другого ТП */
export type GenMainCreateDraftTradeOfferCommand = GenMainOfferLink & GenMainDiscriminatorObject;

/**
 * Команда на создание новой версии уже <b>опубликованного</b> ТП для модерации.
 * Пока изменения не будут подтверждены админом МП для пользователей будет доступна прежняя
 * копия данных предложения.
 */
export type GenMainNewVersionTradeOfferCommand = GenMainTradeOfferUpdateRequest & GenMainDiscriminatorObject;

/** Элемент истории предложения по статусу */
export interface GenMainOfferState {
  /** ID элемента истории предложения */
  id: GenMainOfferStateId | null;
  /**
   * Дата установки статуса
   * @format date-time
   */
  enteredAt: string | null;
  /** Пользователь(сокращённая информация) */
  enteredBy: GenMainUserShort | null;
  /**
   * Тип актора в системе
   * * SYSTEM - система(автоматически - без участия человека)
   * * ADMIN - администратор оператора
   * * PARTNER - сотрудник партнера
   * * USER - пользователь системы в остальных случаях
   */
  actorType: GenMainActorType | null;
  /** Комментарий к статусу */
  statusComment: string | null;
  /** Статус сущности с модерацией */
  status: GenMainOfferStatus | null;
}

/** Элемент истории поля сущности */
export interface GenMainFieldAuditItem {
  /**
   * ID записи истории
   * @format uuid
   */
  id: string;
  /**
   * Дата обновления данного поля
   * @format date-time
   */
  updatedAt: string;
  /**
   * ObjectField
   * Обновленное поле сущности
   */
  field: {
    /** Путь к полю в сущности */
    path: string;
    /** Дефолтное название поля */
    name: string | null;
  };
  /** Значение до изменения */
  oldValue: string | null;
  /** Значение после изменения */
  newValue: string | null;
  /** Пользователь(сокращённая информация) */
  updatedBy: GenMainUserShort | null;
  /**
   * Тип актора в системе
   * * SYSTEM - система(автоматически - без участия человека)
   * * ADMIN - администратор оператора
   * * PARTNER - сотрудник партнера
   * * USER - пользователь системы в остальных случаях
   */
  actorType: GenMainActorType;
}

/**
 * Тип актора в системе
 * * SYSTEM - система(автоматически - без участия человека)
 * * ADMIN - администратор оператора
 * * PARTNER - сотрудник партнера
 * * USER - пользователь системы в остальных случаях
 */
export enum GenMainActorType {
  System = 'SYSTEM',
  Admin = 'ADMIN',
  Partner = 'PARTNER',
  User = 'USER',
}

/** Основные данные КП */
export type GenMainCorpOfferData = Override<
  GenMainOfferData,
  {
    /**
     * Цена без скидки
     * @format int64
     * @min 0
     */
    originalPrice: number | null;
    /**
     * Цена со скидкой
     * @format int64
     * @min 0
     */
    price: number | null;
    /**
     * Максимальная возможная стоимость в рублях
     * @format int64
     */
    costLimit: number | null;
    /** флаг НДФЛ */
    hasNdfl: boolean | null;
    /**
     * Описание начисления НДФЛ (обязательно к заполнению если проставлен флаг НДФЛ)
     * @maxLength 3000
     */
    ndflDescription: string | null;
    /**
     * Тип PersonalPromotion для КП
     *        * CORP_CERTIFICATE - Сертификат корпоративной системы(Льготы), получаемый в Витрине
     *        * EXTERNAL_CORP_CERTIFICATE - активация через внешнюю систему по ссылке externalActivationLink
     */
    promotionType: GenMainCorpOfferPersonalPromotionType | null;
    /** Ссылка на внешнюю сиcтему куда нужно снавигировать пользователя для получения КП. Обязательна к заполнению в случае promotionType = EXTERNAL_CORP_CERTIFICATE */
    externalActivationLink: string | null;
    /**
     * Количество дней после получения активации предложения
     * в течение которых можно получить услугу по предложению
     * @format int32
     * @min 0
     */
    activationExpiryDays: number | null;
  }
>;

/**
 * ID ОЛ
 * @format uuid
 */
export type GenMainResponsiblePersonId = string;

/** Ссылка на ОЛ */
export interface GenMainResponsiblePersonLink {
  /** ID ОЛ */
  id: GenMainResponsiblePersonId | null;
}

/** Запрос на создание Ответственного лица */
export interface GenMainPartnerResponsiblePersonRequest {
  /**
   * ФИО ОЛ
   * @minLength 1
   */
  fullName: string;
  /**
   * Телефон.
   * @minLength 1
   */
  phone: string;
}

/** Ответственное за КП лицо */
export type GenMainPartnerResponsiblePerson = UtilRequiredKeys<
  GenMainPartnerResponsiblePersonRequest,
  'fullName' | 'phone'
> & {
  /** ID ОЛ */
  id: GenMainResponsiblePersonId;
  /**
   * id учетной записи в kc, если ОЛ - пользователь системы
   * @format uuid
   */
  accountId: string | null;
};

/**
 * Ответственное за выдачу КП лицо
 * Если заполнен responsiblePersonLink, то fullName и phone игнорируются
 * Если не заполнен responsiblePersonLink, то fullName и phone обязательны к заполнению
 */
export interface GenMainStoreResponsiblePersonData {
  /** Ссылка на ОЛ */
  responsiblePersonLink: GenMainResponsiblePersonLink | null;
  /** ФИО ОЛ */
  fullName: string | null;
  /** Телефон */
  phone: string | null;
  /**
   * email
   * @format email
   */
  email: string | null;
  /** Расписание работы */
  schedule: string | null;
}

/** КП основная информация */
export type GenMainCorpOfferShort = Override<
  GenMainCorpOfferData & GenMainOfferCountInfo,
  {
    /** ID предложения */
    id: GenMainOfferId;
    /**
     * Тип оплаты:
     * * FREE бесплатно
     * * BENEFIT_RZD_POINT баллы АИС Льготы
     * * RUB рубли
     * * ASP_RZD_RUB рубли из АСП
     */
    paymentType: GenMainPaymentType | null;
    /** ID партнёра */
    partnerId: GenMainPartnerId | null;
    /** Категории предложения */
    categories: GenMainOfferCategory[] | null;
    /** Статус сущности с модерацией */
    status: GenMainOfferStatus | null;
    /** код предложения (код партнера-год-номертппорядковый, например 2-21-1) */
    code: string | null;
    /** Партнёр */
    partner: GenMainPartnerView | null;
    /** Структура для выдачи элемента справочника */
    approvingAdmin: GenMainOption | null;
    /** Тип причины приостановки предложения */
    pausedReason: GenMainOfferPausedReasonType | null;
    /**
     * Дата выставления последнего статуса
     * @format date-time
     */
    lastStatusDate: string | null;
    /** Рейтинг */
    rating: number | null;
    /** Структура для выдачи элемента справочника */
    lastStatusAuthor: GenMainOption | null;
    /** Приказ на допуск КП */
    approvalRegistry: GenMainApprovalOfferRegistryShort | null;
    /** Ответственное за КП лицо */
    responsiblePerson: GenMainPartnerResponsiblePerson | null;
    /**
     * Ответственное за выдачу КП лицо
     * Если заполнен responsiblePersonLink, то fullName и phone игнорируются
     * Если не заполнен responsiblePersonLink, то fullName и phone обязательны к заполнению
     */
    storeResponsiblePerson: GenMainStoreResponsiblePersonData | null;
  }
>;

/** КП представление для покупателя */
export type GenMainCustomerCorpOfferShort = UtilRequiredKeys<GenMainShortOfferView, 'id'> &
  GenMainCorpOfferShort &
  GenMainDiscriminatorObject;

/** Сокращенное представление предложения для покупателя */
export type GenMainCustomerOfferShort = GenMainBaseCustomerOfferShort &
  (
    | GenMainBaseCustomerOfferShortDiscriminatorMapping<'CustomerCorpOfferShort', GenMainCustomerCorpOfferShort>
    | GenMainBaseCustomerOfferShortDiscriminatorMapping<'CustomerProductOfferShort', GenMainCustomerProductOfferShort>
    | GenMainBaseCustomerOfferShortDiscriminatorMapping<'CustomerTradeOfferShort', GenMainCustomerTradeOfferShort>
    | GenMainBaseCustomerOfferShortDiscriminatorMapping<'CustomerBookingOfferShort', GenMainCustomerBookingOfferShort>
  );

/** список КП либо их количество */
export type GenMainCustomerCorpOfferShortResponse = GenMainBaseCustomerCorpOfferShortResponse &
  (
    | GenMainBaseCustomerCorpOfferShortResponseDiscriminatorMapping<
        'CustomerCorpOfferShort',
        GenMainCustomerCorpOfferShort
      >
    | GenMainBaseCustomerCorpOfferShortResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** Товарное предложение - представление для покупателя */
export type GenMainCustomerProductOfferShort = UtilRequiredKeys<GenMainShortOfferView, 'id'> &
  GenMainProductOfferShort &
  GenMainDiscriminatorObject;

/** КП */
export type GenMainCorpOffer = Override<
  GenMainAuditEntity & GenMainCorpOfferShort & GenMainDiscriminatorObject,
  {
    /** Тип причины отклонения предложения */
    rejectionReasonType: GenMainOfferRejectionReasonType | null;
    /** Комментарий к последнему статусу */
    lastStatusComment: string | null;
    /** Структура для выдачи элемента справочника */
    moderator: GenMainOption | null;
    /**
     * Тип КП по бизнес процессу:
     * * `SPA` - санкур
     * * `CHILD` - детский отдых
     * * `FITNES` - фитнес
     */
    externalCorpOfferType: GenMainExternalCorpOfferType | null;
    /** Опции таргета на сайте */
    target: GenMainTarget | null;
    /** Партнёр */
    partner: GenMainPartnerShort | null;
  }
>;

/** список КП либо их количество */
export type GenMainCorpOfferResponse = GenMainBaseCorpOfferResponse &
  (
    | GenMainBaseCorpOfferResponseDiscriminatorMapping<'CorpOffer', GenMainCorpOffer>
    | GenMainBaseCorpOfferResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/**
 * Тип КП по бизнес процессу:
 * * `SPA` - санкур
 * * `CHILD` - детский отдых
 * * `FITNES` - фитнес
 */
export enum GenMainExternalCorpOfferType {
  Spa = 'SPA',
  Child = 'CHILD',
  Fitnes = 'FITNES',
}

/** Товарное предложение на Витрине */
export type GenMainCustomerProductOffer = Override<
  GenMainProductOffer,
  {
    /**
     * Находится в избранном у данного покупателя
     * @deprecated
     * @default false
     */
    favorite: boolean | null;
    /**
     * Подписан на уведомления по предложению
     * @deprecated
     * @default false
     */
    subscribe: boolean | null;
  }
>;

/** Запрос на создание КП */
export type GenMainCorpOfferRequest = GenMainBaseCorpOfferRequest &
  (
    | GenMainBaseCorpOfferRequestDiscriminatorMapping<'CorpOfferDataRequest', GenMainCorpOfferDataRequest>
    | GenMainBaseCorpOfferRequestDiscriminatorMapping<'CreateDraftCorpOfferCommand', GenMainCreateDraftCorpOfferCommand>
  );

/** Запрос на создание черновика КП как копии другого КП */
export type GenMainCreateDraftCorpOfferCommand = GenMainOfferLink & GenMainDiscriminatorObject;

/** Запрос на создание КП */
export type GenMainCorpOfferDataRequest = Override<
  GenMainCorpOfferData & GenMainDiscriminatorObject,
  {
    /** ID партнёра */
    partnerId: GenMainPartnerId | null;
    /** Ссылка на ОЛ */
    responsiblePerson: GenMainResponsiblePersonLink | null;
    /**
     * Ответственное за выдачу КП лицо
     * Если заполнен responsiblePersonLink, то fullName и phone игнорируются
     * Если не заполнен responsiblePersonLink, то fullName и phone обязательны к заполнению
     */
    storeResponsiblePerson: GenMainStoreResponsiblePersonData | null;
    /** Категории предложения */
    categories: GenMainCategoryLink[] | null;
    /**
     * Тип КП по бизнес процессу:
     * * `SPA` - санкур
     * * `CHILD` - детский отдых
     * * `FITNES` - фитнес
     */
    externalCorpOfferType: GenMainExternalCorpOfferType | null;
    /**
     * Максимальная возможная стоимость в рублях
     * @format int32
     */
    costLimit: number | null;
    /** Опции таргета на сайте */
    target: GenMainTargetRequest | null;
  }
>;

/** Запрос на обновление черновика КП */
export type GenMainCorpOfferUpdateRequest = GenMainCorpOfferDataRequest;

/** Запрос на создание/изменение КП для админов c валидацией */
export type GenMainCorpOfferAdminRequest = Override<
  GenMainCorpOfferDataRequest,
  {
    /** Ссылка на приказ на допуск КП */
    approvalRegistry: GenMainApprovalOfferRegistryLink | null;
  }
>;

/** Команда на создание товарного предложения OfferLink - создание черновика на основе другого предложения */
export type GenMainProductOfferCreateRequest = GenMainBaseProductOfferCreateRequest &
  (
    | GenMainBaseProductOfferCreateRequestDiscriminatorMapping<
        'ProductOfferDataRequest',
        GenMainProductOfferDataRequest
      >
    | GenMainBaseProductOfferCreateRequestDiscriminatorMapping<
        'ProductOfferCopyCommand',
        GenMainProductOfferCopyCommand
      >
    | GenMainBaseProductOfferCreateRequestDiscriminatorMapping<
        'NewVersionProductOfferCommand',
        GenMainNewVersionProductOfferCommand
      >
  );

/** Запрос на создание черновика товарного предложения на основе другого предложения */
export type GenMainProductOfferCopyCommand = (GenMainOfferLink & GenMainDiscriminatorObject) & {
  /**
   * Флаг: Копировать изображения из исходника
   * @default true
   */
  includeImages: boolean | null;
};

/**
 * Команда на создание новой версии варианта товара через копию
 * После аппрува копия будет заархивирована или удалена
 */
export type GenMainNewVersionProductOfferCommand = GenMainOfferLink & GenMainDiscriminatorObject;

/** Запрос на создание товарного предложения */
export type GenMainProductOfferDataRequest = Override<
  GenMainProductOfferData & GenMainDiscriminatorObject,
  {
    /** ID категории */
    category: GenMainCategoryLink;
  }
>;

/** Запрос на обновление черновика товарного предложения */
export type GenMainProductOfferDraftUpdateRequest = (UtilRequiredKeys<GenMainProductOfferData, 'images'> &
  GenMainDiscriminatorObject) & {
  /**
   * @maxItems 10
   * @minItems 1
   */
  images: GenMainFileLink[];
};

/** Запрос на создание/изменение товарного предложения для админов c валидацией */
export type GenMainValidProductOfferRequest = UtilRequiredKeys<
  GenMainProductOfferData,
  'name' | 'brand' | 'partnerSKU' | 'price' | 'paymentType' | 'deliveryType'
> &
  GenMainDiscriminatorObject;

/** Команда на обновление товарного предложения */
export type GenMainProductOfferUpdateRequest = GenMainBaseProductOfferUpdateRequest &
  (
    | GenMainBaseProductOfferUpdateRequestDiscriminatorMapping<
        'ProductOfferDraftUpdateRequest',
        GenMainProductOfferDraftUpdateRequest
      >
    | GenMainBaseProductOfferUpdateRequestDiscriminatorMapping<
        'ValidProductOfferRequest',
        GenMainValidProductOfferRequest
      >
  );

/** Действия с товарным предложением */
export type GenMainProductOfferAction = GenMainBaseProductOfferAction &
  (
    | GenMainBaseProductOfferActionDiscriminatorMapping<'OfferToModerationCommand', GenMainOfferToModerationCommand>
    | GenMainBaseProductOfferActionDiscriminatorMapping<'OfferBackToDraftCommand', GenMainOfferBackToDraftCommand>
    | GenMainBaseProductOfferActionDiscriminatorMapping<
        'OfferAssignModeratorCommand',
        GenMainOfferAssignModeratorCommand
      >
    | GenMainBaseProductOfferActionDiscriminatorMapping<'OfferApproveCommand', GenMainOfferApproveCommand>
    | GenMainBaseProductOfferActionDiscriminatorMapping<'OfferRejectCommand', GenMainOfferRejectCommand>
    | GenMainBaseProductOfferActionDiscriminatorMapping<'OfferPauseCommand', GenMainOfferPauseCommand>
    | GenMainBaseProductOfferActionDiscriminatorMapping<'OfferRenewCommand', GenMainOfferRenewCommand>
    | GenMainBaseProductOfferActionDiscriminatorMapping<'OfferArchiveCommand', GenMainOfferArchiveCommand>
    | GenMainBaseProductOfferActionDiscriminatorMapping<
        'ProductOfferChangePriceCommand',
        GenMainProductOfferChangePriceCommand
      >
    | GenMainBaseProductOfferActionDiscriminatorMapping<
        'ProductOfferChangeStockCommand',
        GenMainProductOfferChangeStockCommand
      >
  );

/** Действия с объектом ДиО */
export type GenMainBookingOfferAction = GenMainBaseBookingOfferAction &
  (
    | GenMainBaseBookingOfferActionDiscriminatorMapping<'OfferToModerationCommand', GenMainOfferToModerationCommand>
    | GenMainBaseBookingOfferActionDiscriminatorMapping<'OfferBackToDraftCommand', GenMainOfferBackToDraftCommand>
    | GenMainBaseBookingOfferActionDiscriminatorMapping<
        'OfferAssignModeratorCommand',
        GenMainOfferAssignModeratorCommand
      >
    | GenMainBaseBookingOfferActionDiscriminatorMapping<'OfferApproveCommand', GenMainOfferApproveCommand>
    | GenMainBaseBookingOfferActionDiscriminatorMapping<'OfferRejectCommand', GenMainBookingOfferRejectCommand>
    | GenMainBaseBookingOfferActionDiscriminatorMapping<'OfferPauseCommand', GenMainBookingOfferPauseCommand>
    | GenMainBaseBookingOfferActionDiscriminatorMapping<'OfferRenewCommand', GenMainOfferRenewCommand>
    | GenMainBaseBookingOfferActionDiscriminatorMapping<'OfferArchiveCommand', GenMainOfferArchiveCommand>
    | GenMainBaseBookingOfferActionDiscriminatorMapping<
        'OfferBackFromArchiveCommand',
        GenMainOfferBackFromArchiveCommand
      >
    | GenMainBaseBookingOfferActionDiscriminatorMapping<
        'NewVersionBookingOfferCommand',
        GenMainNewVersionBookingOfferCommand
      >
  );

/** Действия с заказом со стороны админа/партнера */
export type GenMainOrderAction = GenMainBaseOrderAction &
  (
    | GenMainBaseOrderActionDiscriminatorMapping<'OrderChangeStatusCommand', GenMainOrderChangeStatusCommand>
    | GenMainBaseOrderActionDiscriminatorMapping<
        'PartnerUpdateProductOrderCommand',
        GenMainPartnerUpdateProductOrderCommand
      >
    | GenMainBaseOrderActionDiscriminatorMapping<'UpdateActivationOrderCommand', GenMainUpdateActivationOrderCommand>
    | GenMainBaseOrderActionDiscriminatorMapping<'UpdateBookingOrderCommand', GenMainUpdateBookingOrderCommand>
    | GenMainBaseOrderActionDiscriminatorMapping<'OrderCancelCommand', GenMainOrderCancelCommand>
    | GenMainBaseOrderActionDiscriminatorMapping<'CustomerOrderCancelCommand', GenMainCustomerOrderCancelCommand>
  );

/** Изменение статуса заказа */
export type GenMainOrderChangeStatusCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /**
     * Статус заказа:
     * актуальные для заказа по КП/ТП:
     * * NEW - новый
     * * CONFIRMED - подтвержден
     * * RETURNED - возвращен
     * * CANCELLED - отменен
     * * GIVEN - получен клиентом
     * * ACTIVE - активен
     * актуальные для заказа по ПП:
     * * NEW - создан
     * * CONFIRMED - принят
     * * PAID - оплачен
     * * SENT - отправлен
     * * RETURNED - возврат полный
     * * PARTIALLY_RETURNED - возврат частичный
     * * CANCELLED - отменен
     * * GIVEN - получен
     */
    status: GenMainOrderStatus;
    /** комментарий к статусу */
    comment: string | null;
  }
>;

/** Отмена заказа партнером/админом */
export type GenMainOrderCancelCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /** Ссылка на причину отклонения заказа */
    reason: GenMainOrderCancellationReasonTypeLink;
    /** комментарий к статусу */
    comment: string | null;
  }
>;

/** Отмена заказа партнером/админом */
export type GenMainCustomerOrderCancelCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    reason: GenMainCustomerOrderCancellationReasonTypeLink;
    /** комментарий к статусу */
    comment: string | null;
  }
>;

/** Редактирование заказа партнером - исполнителем заказа */
export type GenMainPartnerUpdateProductOrderCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /** Ссылка на объект адреса */
    deliveryAddress: GenMainAddressObjectLink | null;
    /**
     * Стоимость доставки в рублях
     * @format int32
     * @min 0
     */
    deliveryCost: number | null;
    /**
     * Статус заказа:
     * актуальные для заказа по КП/ТП:
     * * NEW - новый
     * * CONFIRMED - подтвержден
     * * RETURNED - возвращен
     * * CANCELLED - отменен
     * * GIVEN - получен клиентом
     * * ACTIVE - активен
     * актуальные для заказа по ПП:
     * * NEW - создан
     * * CONFIRMED - принят
     * * PAID - оплачен
     * * SENT - отправлен
     * * RETURNED - возврат полный
     * * PARTIALLY_RETURNED - возврат частичный
     * * CANCELLED - отменен
     * * GIVEN - получен
     */
    status: GenMainOrderStatus;
    /** комментарий к статусу */
    comment: string | null;
    /**
     * Все товары в заказе
     * @minItems 1
     */
    items: GenMainPartnerUpdateProductOrderItemCommand[];
  }
>;

/** Редактирование заказа по букингу партнером */
export type GenMainUpdateBookingOrderCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /**
     * Статус заказа:
     * актуальные для заказа по КП/ТП:
     * * NEW - новый
     * * CONFIRMED - подтвержден
     * * RETURNED - возвращен
     * * CANCELLED - отменен
     * * GIVEN - получен клиентом
     * * ACTIVE - активен
     * актуальные для заказа по ПП:
     * * NEW - создан
     * * CONFIRMED - принят
     * * PAID - оплачен
     * * SENT - отправлен
     * * RETURNED - возврат полный
     * * PARTIALLY_RETURNED - возврат частичный
     * * CANCELLED - отменен
     * * GIVEN - получен
     */
    status: GenMainOrderStatus | null;
    /** Комментарий менеджера */
    managerComment: string | null;
    /** ВСЕ пункты заказа */
    items: GenMainUpdateBookingOrderItemCommand[] | null;
  }
>;

/** Данные для редактирования пункта заказа букинга */
export interface GenMainUpdateBookingOrderItemCommand {
  /**
   * ID СУЩЕСТВУЮЩЕГО пункта заказа, для нового пункта заказа передавать id не нужно
   * @format uuid
   */
  id: string | null;
  /** Ссылка на тариф по услуге */
  priceItem: GenMainServicePriceItemLink | null;
  /**
   * Цена в рублях
   * @format int64
   * @min 0
   */
  price: number | null;
  /**
   * Статус позиции заказа:
   * * NEW - новая
   * * CONFIRMED - подтверждена
   * * RETURNED - возвращена полностью
   * * CANCELLED - отменена
   * * GIVEN - получена клиентом
   * * CHANGED - состав позиции изменен
   * * PARTIALLY_RECEIVED - частично получена клиентом
   */
  status: GenMainOrderItemStatus | null;
  /**
   * Количество единиц в позиции (для тарифа БЕЗ ВРЕМЕННЫХ СЛОТОВ)
   * @format int64
   */
  qty: number | null;
  /** Временные слоты (для тарифа С ВРЕМЕННЫМИ СЛОТАМИ) */
  slots: GenMainBookingDateSlot[] | null;
}

/** Редактирование заказа по активации партнером/админом */
export type GenMainUpdateActivationOrderCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /**
     * Общая стоимость в рублях без скидки
     * @multipleOf 0.01
     */
    originalCost: number | null;
    /**
     * Общая стоимость со скидкой в рублях
     * @multipleOf 0.01
     */
    cost: number | null;
    /** Описание заказа(для ваучера - состав заказа) */
    description: string | null;
    /** структура для ссылки и описания ресурса(файла) */
    receiptImage: GenMainFileLink | null;
  }
>;

/** Позиция заказа проверенная партнером */
export interface GenMainPartnerUpdateProductOrderItemCommand {
  /** Ссылка на предложение */
  offer: GenMainOfferLink;
  /**
   * количество
   * @format int64
   * @min 1
   * @default 1
   */
  quantity: number;
  /**
   * Стоимость со скидкой в рублях
   * если не указать цену, то цена позиции остается прежней или
   * берется из предложения(для новых позиций заказа)
   * @format int64
   */
  cost: number | null;
  /**
   * Статус позиции заказа:
   * * NEW - новая
   * * CONFIRMED - подтверждена
   * * RETURNED - возвращена полностью
   * * CANCELLED - отменена
   * * GIVEN - получена клиентом
   * * CHANGED - состав позиции изменен
   * * PARTIALLY_RECEIVED - частично получена клиентом
   */
  status: GenMainOrderItemStatus;
}

/** Действия с карточкой товара */
export type GenMainProductDeskAction = GenMainBaseProductDeskAction &
  (
    | GenMainBaseProductDeskActionDiscriminatorMapping<'ProductPauseCommand', GenMainProductPauseCommand>
    | GenMainBaseProductDeskActionDiscriminatorMapping<'ProductArchiveCommand', GenMainProductArchiveCommand>
    | GenMainBaseProductDeskActionDiscriminatorMapping<'ProductRenewCommand', GenMainProductRenewCommand>
    | GenMainBaseProductDeskActionDiscriminatorMapping<'ProductBackToDraftCommand', GenMainProductBackToDraftCommand>
  );

/** Перевод предложения партнером на модерацию */
export type GenMainOfferToModerationCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/** Снятие с модерации предложения партнёром Возврат из статуса WAITING_FOR_MODERATION в DRAFT! */
export type GenMainOfferBackToDraftCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/** Назначение модератора */
export type GenMainOfferAssignModeratorCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /** ссылка на пользователя */
    user: GenMainUserLink;
  }
>;

/** Успешное завершение модерации предложения админом переход в следующий статус */
export type GenMainOfferApproveCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/** Приостановка(перевод в статус PAUSED) */
export type GenMainOfferPauseCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /** Комментарий */
    comment: string | null;
  }
>;

/** Приостановка(перевод в статус PAUSED) */
export type GenMainBookingOfferPauseCommand = GenMainOfferPauseCommand;

/** Приостановка(перевод в статус PAUSED) всех вариантов товара */
export type GenMainProductPauseCommand = GenMainOfferPauseCommand;

/** Возобновление предложения после приостановки */
export type GenMainOfferRenewCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/** Возврат из архива */
export type GenMainOfferRetrieveFromArchiveCommand = {
  /**
   * срок окончания действия ТП
   * @format date
   */
  endDate: string;
};

/** Возобновление всех вариантов товара после приостановки */
export type GenMainProductRenewCommand = GenMainOfferRenewCommand;

/** Снятие с модерации всех вариантов товара  Возврат из статуса WAITING_FOR_MODERATION в DRAFT! */
export type GenMainProductBackToDraftCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/** Архивация предложения */
export type GenMainOfferArchiveCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/** Возврат из архива */
export type GenMainOfferBackFromArchiveCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/** Архивация всех вариантов товара */
export type GenMainProductArchiveCommand = GenMainOfferArchiveCommand;

/** Отклонение предложения модератором и отправка его на доработку */
export type GenMainOfferRejectCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    rejectionType: GenMainOfferRejectionReasonTypeLink;
    /** Комментарий */
    comment: string | null;
  }
>;

/** Отклонение предложения модератором и отправка его на доработку */
export type GenMainBookingOfferRejectCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /** Комментарий */
    comment: string;
  }
>;

/** Команда на изменение цены Допустима в любом статусе */
export type GenMainProductOfferChangePriceCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /**
     * Цена без скидки
     * @format int64
     * @min 1
     */
    originalPrice: number | null;
    /**
     * Цена со скидкой
     * @format int64
     * @min 1
     */
    price: number;
  }
>;

/** Команда на изменение количества товара в наличии */
export type GenMainProductOfferChangeStockCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /**
     * Количество товара в наличии.
     * @format int64
     * @min 0
     */
    stock: number;
  }
>;

/** Основные данные товарного предложения */
export interface GenMainProductOfferData {
  /** Наименование товара */
  name: string;
  /** Название для покупателя варианта товара(его основные характеристики без названия модели товара) */
  variantName: string | null;
  /** @maxItems 10 */
  images: GenMainFileLink[] | null;
  /** Ссылка на партнера */
  partner: GenMainPartnerLink;
  /** Ссылка на бренд */
  brand: GenMainBrandLink | null;
  /** SKU в системе партнера */
  partnerSKU: string | null;
  /** артикул производителя */
  producerSKU: string | null;
  /** Ссылка на карточку товара */
  productDesk: GenMainProductDeskLink | null;
  /** Описание товара в свободной форме */
  description: string | null;
  /** Опции таргета на сайте */
  target: GenMainTargetRequest | null;
  /**
   * Цена без скидки
   * @format int64
   * @min 1
   */
  originalPrice: number | null;
  /**
   * Цена со скидкой
   * @format int64
   * @min 1
   */
  price: number | null;
  /** Значения характеристик товаров */
  attributes: GenMainProductAttributeValue[] | null;
  /** Описание прочих характеристик товара в свободной форме */
  otherAttributes: string | null;
  /** Способ оплаты заказа товара */
  paymentType: GenMainProductOrderPaymentType | null;
  /** Условие оплаты */
  paymentConditions: string | null;
  /** Способ доставки */
  deliveryType: GenMainDeliveryType | null;
  /** Условие доставки */
  deliveryConditions: string | null;
}

/** Ссылка на карточку товара */
export interface GenMainProductDeskLink {
  /** id карточки товара */
  id: GenMainProductDeskId | null;
}

/**
 * id карточки товара
 * @format uuid
 */
export type GenMainProductDeskId = string;

/**
 * id объекта ДиО
 * @format uuid
 */
export type GenMainBookingOfferId = string;

/** список объектов ДиО либо их количество */
export type GenMainBookingOfferResponse = GenMainBaseBookingOfferResponse &
  (
    | GenMainBaseBookingOfferResponseDiscriminatorMapping<'BookingOffer', GenMainBookingOffer>
    | GenMainBaseBookingOfferResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** Объект ДиО */
export type GenMainBookingOffer = Override<
  GenMainAuditEntity & GenMainBookingOfferShort & GenMainDiscriminatorObject,
  {
    /** Тип причины отклонения предложения */
    rejectionReasonType: GenMainOfferRejectionReasonType | null;
    /** Комментарий к последнему статусу */
    lastStatusComment: string | null;
    /** Партнёр */
    partner: GenMainPartnerShort | null;
    /** Услуги в предложении */
    services: GenMainOfferService[] | null;
  }
>;

/** Объект ДиО - основная информация */
export type GenMainBookingOfferShort = Override<
  UtilRequiredKeys<GenMainBookingOfferData, 'name' | 'partner'> & GenMainDiscriminatorObject,
  {
    /**
     * Дата создания
     * @format date-time
     */
    createdAt: string | null;
    /** id объекта ДиО */
    id: GenMainBookingOfferId;
    /** Категория предложения */
    category: GenMainOfferCategory | null;
    /** Статус сущности с модерацией */
    status: GenMainOfferStatus | null;
    /** код предложения (код партнера-год-номер_порядковый, например 2-21-1) */
    code: string | null;
    /** Партнёр */
    partner: GenMainPartnerShort;
    /** Структура для выдачи элемента справочника */
    approvingAdmin: GenMainOption | null;
    /** Структура для выдачи элемента справочника */
    moderator: GenMainOption | null;
    /** Тип причины приостановки предложения */
    pausedReason: GenMainOfferPausedReasonType | null;
    /**
     * Дата выставления последнего статуса
     * @format date-time
     */
    lastStatusDate: string | null;
    /** Структура для выдачи элемента справочника */
    lastStatusAuthor: GenMainOption | null;
    /** Адрес */
    address: GenMainAddress | null;
    /** Контакты ДиО */
    contacts: GenMainBookingOfferContact[] | null;
    /**
     * Цена в рублях для отображения в карточке предложения
     * минимальная цена варианта услуги объекта в формате: от N ед.изм:
     * если есть услуга с категорией Проживание, то отображается мин цена ее варианта
     * иначе минимальная цена их всех вариантов услуг объекта
     * с учетом статуса  услуг
     */
    displayPriceItem: GenMainServicePriceItem | null;
    /** Можно ли физически удалить данный офер */
    canDelete: boolean | null;
    tags: GenMainOfferTag[] | null;
  }
>;

/** Полное представление объекта ДиО для Витрины */
export type GenMainCustomerBookingOffer = GenMainCustomerBookingOfferShort & {
  /** Категория предложения */
  category: GenMainOfferCategory | null;
  /** Статус сущности с модерацией */
  status: GenMainOfferStatus | null;
  /** код предложения (код партнера-год-номер_порядковый, например 2-21-1) */
  code: string | null;
  /** Контакты ДиО */
  contacts: GenMainBookingOfferContact[] | null;
  /** Услуги в предложении */
  services: GenMainOfferService[] | null;
};

/** Представление объекта ДиО для Витрины */
export type GenMainCustomerBookingOfferShort = Override<
  GenMainShortOfferView & GenMainBookingOfferData & GenMainDiscriminatorObject,
  {
    /**
     * Цена в рублях для отображения в карточке предложения
     * минимальная цена варианта услуги объекта в формате: от N ед.изм:
     * если есть услуга с категорией Проживание, то отображается мин цена ее варианта
     * иначе минимальная цена их всех вариантов услуг объекта
     * с учетом статуса  услуг
     */
    displayPriceItem: GenMainServicePriceItem | null;
    /** Партнёр */
    partner: GenMainPartnerView | null;
    /** Адрес */
    address: GenMainAddress | null;
  }
>;

/** Основные данные объекта ДиО */
export interface GenMainBookingOfferData {
  /** Наименование предложения */
  name: string;
  /** Ссылка на партнера */
  partner: GenMainPartnerLink;
  /** Описание предложения */
  description: string | null;
  /**
   * Изображения
   * @maxItems 30
   */
  images: GenMainFileLink[] | null;
  /** Ответственные за объект */
  responsiblePersons: GenMainBookingOfferResponsiblePersonData[] | null;
  /** Ссылка на объект адреса */
  address: GenMainAddressObjectLink | null;
  /** Описание дороги до объекта */
  routeDescription: string | null;
  /** Контакты ДиО */
  contacts: GenMainBookingOfferContactData[] | null;
  /** ID категории */
  category: GenMainCategoryLink | null;
  /**
   * Работает ли объект отдыха с соцпакетом работника РЖД
   * @default false
   */
  rzdSocialPackage: boolean | null;
}

/** Основные данные объекта ДиО для обновления */
export type GenMainBookingOfferUpdateData = Override<
  GenMainBookingOfferData,
  {
    /** Услуги в предложении */
    services: GenMainOfferServiceLink[] | null;
  }
>;

/**
 * ID услуги
 * @format uuid
 */
export type GenMainOfferServiceId = string;

/** Ссылка на услугу */
export interface GenMainOfferServiceLink {
  /** ID услуги */
  id: GenMainOfferServiceId | null;
}

/**
 * Способ заказа услуги по датам
 *  * PERIOD - Можно выбрать период
 *  * DAYS - Можно выбрать по дням
 *  * NONE - Нельзя выбрать даты
 */
export enum GenMainServiceOrderByDateType {
  Period = 'PERIOD',
  Days = 'DAYS',
  None = 'NONE',
}

/** Данные услуги в предложении */
export interface GenMainOfferServiceData {
  /** Название услуги */
  name: string | null;
  /** Описание услуги */
  description: string | null;
  /** Ссылка на категорию услуги */
  category: GenMainServiceCategoryLink | null;
  /**
   * Способ заказа услуги по датам
   *  * PERIOD - Можно выбрать период
   *  * DAYS - Можно выбрать по дням
   *  * NONE - Нельзя выбрать даты
   */
  orderByDateType: GenMainServiceOrderByDateType | null;
  /**
   * Статус услуги
   * @default "ENABLED"
   */
  status: 'ENABLED' | 'DISABLED' | null;
}

/** Запрос на обновление услуги в предложении */
export type GenMainOfferServiceUpdateRequest = GenMainOfferServiceData & {
  /** Тарифы по услуге */
  priceItems: GenMainServicePriceItemLink[] | null;
};

/** Запрос на создание услуги в предложении */
export type GenMainOfferServiceCreateRequest = GenMainOfferServiceData & {
  /** Ссылка на предложение */
  offer: GenMainOfferLink | null;
};

/** Запрос на обновление тарифа услуги */
export type GenMainServicePriceItemUpdateRequest = GenMainServicePriceItemData;

/** Запрос на создание тарифа услуги */
export type GenMainServicePriceItemCreateRequest = GenMainServicePriceItemData & {
  /** Ссылка на услугу */
  service: GenMainOfferServiceLink | null;
};

/**
 * ID категории услуги
 * @format uuid
 */
export type GenMainServiceCategoryId = string;

/** Ссылка на категорию услуги */
export interface GenMainServiceCategoryLink {
  /** ID категории услуги */
  id: GenMainServiceCategoryId | null;
}

/** Представление категории услуги с флагом наличия связи с опубликованными объектами ДиО */
export type GenMainServiceCategory = Override<
  GenMainServiceCategoryShort,
  {
    /**
     * Флаг наличия связи с опубликованными объектами ДиО
     * @default false
     */
    used: boolean | null;
  }
>;

/** Представление категории услуги */
export type GenMainServiceCategoryShort = Override<
  GenMainServiceCategoryLink & GenMainServiceCategoryData,
  {
    /** Статус категории услуги: ENABLED - активна DISABLED - заблокирована DRAFT - черновик */
    status: GenMainServiceCategoryStatus | null;
  }
>;

/** Данные категории услуги */
export interface GenMainServiceCategoryData {
  /** Название */
  name: string;
  /**
   * Способ заказа услуги по датам
   * @minItems 0
   */
  orderByDateTypes: GenMainServiceOrderByDateType[] | null;
}

/** Команды над категорией услуг */
export type GenMainServiceCategoryCommand = GenMainBaseServiceCategoryCommand &
  (
    | GenMainBaseServiceCategoryCommandDiscriminatorMapping<
        'UpdateServiceCategoryCommand',
        GenMainUpdateServiceCategoryCommand
      >
    | GenMainBaseServiceCategoryCommandDiscriminatorMapping<
        'ChangeServiceCategoryStatusCommand',
        GenMainChangeServiceCategoryStatusCommand
      >
  );

/** Команда обновления статуса категории услуги */
export type GenMainChangeServiceCategoryStatusCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /** Статус категории услуги: ENABLED - активна DISABLED - заблокирована DRAFT - черновик */
    status: GenMainServiceCategoryStatus;
  }
>;

/** Команда изменение значащих полей сущности */
export type GenMainUpdateServiceCategoryCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'> &
  UtilRequiredKeys<GenMainServiceCategoryData, 'name'>;

/** Статус категории услуги: ENABLED - активна DISABLED - заблокирована DRAFT - черновик */
export enum GenMainServiceCategoryStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Draft = 'DRAFT',
}

/** Представление услуги */
export type GenMainOfferService = (GenMainOfferServiceLink & GenMainOfferServiceData) & {
  /** Ссылка на предложение */
  offer: GenMainOfferLink | null;
  /** Представление категории услуги */
  category: GenMainServiceCategoryShort | null;
  /** Тарифы по услуге */
  priceItems: GenMainServicePriceItem[] | null;
};

/**
 * ID тарифа услуги
 * @format uuid
 */
export type GenMainServicePriceItemId = string;

/** Ссылка на тариф по услуге */
export interface GenMainServicePriceItemLink {
  /** ID услуги */
  id: GenMainOfferServiceId | null;
}

/** Данные тарифа для услуги */
export interface GenMainServicePriceItemData {
  /** Название */
  name: string | null;
  /** Единица тарификации из справочника */
  unit: GenMainServicePriceUnitLink | null;
  /**
   * Цена в рублях
   * @format int64
   * @min 0
   */
  price: number | null;
}

/** Представление - тариф по услуге */
export type GenMainServicePriceItem = (GenMainServicePriceItemLink & GenMainServicePriceItemData) & {
  /** Ссылка на услугу */
  service: GenMainOfferServiceLink | null;
  /** Единица тарификации услуги */
  unit: GenMainServicePriceUnit | null;
};

/**
 * ID единицы тарификации услуги
 * @format uuid
 */
export type GenMainServicePriceUnitId = string;

/** Ссылка на единицу тарификации услуги */
export interface GenMainServicePriceUnitLink {
  /** ID единицы тарификации услуги */
  id: GenMainServicePriceUnitId | null;
}

/** Единица тарификации услуги */
export type GenMainServicePriceUnit = Override<
  UtilRequiredKeys<GenMainServicePriceUnitLink, 'id'> &
    UtilRequiredKeys<GenMainServicePriceUnitData, 'name' | 'orderByDateType'>,
  {
    /** Статус единицы тарификации: ENABLED - активна DISABLED - заблокирована DRAFT - черновик */
    status: GenMainServicePriceUnitStatus;
  }
>;

/** Данные единицы тарификации услуги */
export interface GenMainServicePriceUnitData {
  /** Название */
  name: string;
  /**
   * Способ заказа услуги по датам
   *  * PERIOD - Можно выбрать период
   *  * DAYS - Можно выбрать по дням
   *  * NONE - Нельзя выбрать даты
   */
  orderByDateType: GenMainServiceOrderByDateType | null;
}

/** Команда обновления статуса единицы измерения */
export type GenMainChangePriceUnitStatusCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /** Статус единицы тарификации: ENABLED - активна DISABLED - заблокирована DRAFT - черновик */
    status: GenMainServicePriceUnitStatus;
  }
>;

/** Команда изменение значащих полей сущности */
export type GenMainUpdatePriceUnitCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'> &
  UtilRequiredKeys<GenMainServicePriceUnitData, 'name'>;

/** Статус единицы тарификации: ENABLED - активна DISABLED - заблокирована DRAFT - черновик */
export enum GenMainServicePriceUnitStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Draft = 'DRAFT',
}

/** Команды над конкретным типом активности */
export type GenMainServicePriceUnitCommand = GenMainBaseServicePriceUnitCommand &
  (
    | GenMainBaseServicePriceUnitCommandDiscriminatorMapping<'UpdatePriceUnitCommand', GenMainUpdatePriceUnitCommand>
    | GenMainBaseServicePriceUnitCommandDiscriminatorMapping<
        'ChangePriceUnitStatusCommand',
        GenMainChangePriceUnitStatusCommand
      >
  );

/** Ответственное за объект ДиО лицо */
export interface GenMainBookingOfferResponsiblePersonData {
  /** ФИО ОЛ */
  fullName: string | null;
  /** Телефон */
  phone: string | null;
  /**
   * email
   * @format email
   */
  email: string | null;
}

/** Контакт */
export interface GenMainBookingOfferContactData {
  /** Название контакта */
  name: string | null;
  /** Телефон */
  phone: string | null;
  /**
   * email
   * @format email
   */
  email: string | null;
  /** Ссылка на объект адреса */
  address: GenMainAddressObjectLink | null;
}

/** Контакт - представление */
export type GenMainBookingOfferContact = GenMainBookingOfferContactData & {
  /** Адрес */
  address: GenMainAddress | null;
};

/** Команда на создание нового объекта ДиО */
export type GenMainBookingOfferCreateRequest = GenMainBaseBookingOfferCreateRequest &
  (
    | GenMainBaseBookingOfferCreateRequestDiscriminatorMapping<'BookingOfferDataRequest', GenMainNewBookingOfferRequest>
    | GenMainBaseBookingOfferCreateRequestDiscriminatorMapping<
        'BookingOfferCopyCommand',
        GenMainBookingOfferCopyCommand
      >
  );

/** Команда на обновление объекта ДиО */
export type GenMainBookingOfferUpdateRequest = GenMainBaseBookingOfferUpdateRequest &
  (
    | GenMainBaseBookingOfferUpdateRequestDiscriminatorMapping<
        'BookingOfferDataRequest',
        GenMainBookingOfferDraftUpdateRequest
      >
    | GenMainBaseBookingOfferUpdateRequestDiscriminatorMapping<
        'BookingOfferCopyCommand',
        GenMainValidBookingOfferRequest
      >
  );

/** Запрос на создание черновика объекта ДиО на основе данных другого объекта ДиО */
export type GenMainBookingOfferCopyCommand = GenMainOfferLink & GenMainDiscriminatorObject;

/** Запрос на создание объекта ДиО */
export type GenMainNewBookingOfferRequest = GenMainBookingOfferData & GenMainDiscriminatorObject;

/** Запрос на обновление черновика объекта ДиО */
export type GenMainBookingOfferDraftUpdateRequest = GenMainBookingOfferUpdateData & GenMainDiscriminatorObject;

/** Запрос на изменение объекта ДиО  c валидацией */
export type GenMainValidBookingOfferRequest = GenMainBookingOfferUpdateData & GenMainDiscriminatorObject;

/**
 * Команда на создание новой версии уже <b>опубликованного</b> объекта ДиО для модерации.
 * Пока изменения не будут подтверждены админом МП для пользователей будет доступна прежняя
 * копия данных предложения.
 */
export type GenMainNewVersionBookingOfferCommand = GenMainBookingOfferUpdateData & GenMainDiscriminatorObject;

/** Запрос на создание карточки товара */
export interface GenMainProductDeskRequest {
  /** Ссылка на партнера */
  partner: GenMainPartnerLink;
  /** Код карточки товара(для объединения вариантов товара) */
  code: string;
  /** ID категории */
  category: GenMainCategoryLink;
}

/** Карточка товара (сокращенная информация) */
export type GenMainProductDeskShort = GenMainProductDeskLink & GenMainProductDeskRequest;

/** Карточка товара */
export type GenMainProductDesk = Override<
  GenMainProductDeskShort,
  {
    /** Товарное предложение - основная информация */
    templateOffer: GenMainProductOfferShort | null;
  }
>;

/** Действия с пользователя со своим заказом */
export type GenMainOrderCustomerAction = GenMainBaseOrderCustomerAction &
  GenMainBaseOrderCustomerActionDiscriminatorMapping<'CustomerCancelOrderCommand', GenMainCustomerCancelOrderCommand>;

/** Отмена заказа покупателем */
export type GenMainCustomerCancelOrderCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    cancellationType: GenMainCustomerOrderCancellationReasonTypeLink;
    /** Комментарий */
    comment: string | null;
  }
>;

/** Способ оплаты заказа товара */
export enum GenMainProductOrderPaymentType {
  Cash = 'CASH',
  Card = 'CARD',
  Invoice = 'INVOICE',
  CashOrCard = 'CASH_OR_CARD',
  Other = 'OTHER',
}

/** Способ доставки */
export enum GenMainDeliveryType {
  Self = 'SELF',
  Delivery = 'DELIVERY',
  SelfOrDelivery = 'SELF_OR_DELIVERY',
  Other = 'OTHER',
}

/** Товарное предложение - основная информация */
export type GenMainProductOfferShort = Override<
  GenMainProductOfferData,
  {
    /** ID предложения */
    id: GenMainOfferId;
    /** Категория товара */
    category: GenMainProductCategory | null;
    /** Значения характеристик товаров */
    attributes: GenMainProductAttributeValue[] | null;
    /** Список всех категорий к которым относится товар */
    categoryList: GenMainOfferCategory[] | null;
    /** Бренд */
    brand: GenMainBrand | null;
    /** Статус сущности с модерацией */
    status: GenMainOfferStatus | null;
    /** Партнёр */
    partner: GenMainPartnerView | null;
    /** Структура для выдачи элемента справочника */
    approvingAdmin: GenMainOption | null;
    /** Тип причины приостановки предложения */
    pausedReason: GenMainOfferPausedReasonType | null;
    /**
     * Дата выставления последнего статуса
     * @format date-time
     */
    lastStatusDate: string | null;
    /** Структура для выдачи элемента справочника */
    lastStatusAuthor: GenMainOption | null;
    /**
     * Тип оплаты:
     * * FREE бесплатно
     * * BENEFIT_RZD_POINT баллы АИС Льготы
     * * RUB рубли
     * * ASP_RZD_RUB рубли из АСП
     */
    currencyType: GenMainPaymentType | null;
    /**
     * процент скидки
     * @format int32
     * @min 0
     * @max 100
     */
    salePercent: number | null;
    /** Ссылка на предложение */
    newVersionFor: GenMainOfferLink | null;
    /**
     * Количество товара в наличии.
     * @format int64
     */
    stock: number | null;
    /** Карточка товара (сокращенная информация) */
    productDesk: GenMainProductDeskShort | null;
    /** Опции таргета на сайте */
    target: GenMainTarget | null;
    tags: GenMainOfferTag[] | null;
  }
>;

/** Товарное предложение */
export type GenMainProductOffer = Override<
  GenMainDiscriminatorObject & GenMainAuditEntity & GenMainProductOfferShort,
  {
    /** Тип причины отклонения предложения */
    rejectionReasonType: GenMainOfferRejectionReasonType | null;
    /** Комментарий к последнему статусу */
    lastStatusComment: string | null;
    /** Структура для выдачи элемента справочника */
    moderator: GenMainOption | null;
    /** Рейтинг популярности */
    rating: number | null;
    /** Опции таргета на сайте */
    target: GenMainTarget | null;
  }
>;

/** список товаров либо их количество */
export type GenMainProductOfferResponse = GenMainBaseProductOfferResponse &
  (
    | GenMainBaseProductOfferResponseDiscriminatorMapping<'ProductOffer', GenMainProductOffer>
    | GenMainBaseProductOfferResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** список товаров либо их количество */
export type GenMainCustomerProductOfferShortResponse = GenMainBaseCustomerProductOfferShortResponse &
  (
    | GenMainBaseCustomerProductOfferShortResponseDiscriminatorMapping<
        'CustomerProductOfferShort',
        GenMainCustomerProductOfferShort
      >
    | GenMainBaseCustomerProductOfferShortResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** список объектов букинга (Дио) либо их количество */
export type GenMainCustomerBookingOfferListResponse = GenMainBaseCustomerBookingOfferListResponse &
  (
    | GenMainBaseCustomerBookingOfferListResponseDiscriminatorMapping<'BookingOfferShort', GenMainBookingOfferShort>
    | GenMainBaseCustomerBookingOfferListResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/**
 * ID заявки на активацию предложения
 * @format uuid
 */
export type GenMainActivationId = string;

/**
 * ID приказа на допуск КП
 * @format uuid
 */
export type GenMainApprovalOfferRegistryId = string;

/** Запрос на создание приказа на допуск КП */
export interface GenMainApprovalOfferRegistryRequest {
  /** Номер приказа */
  number: string;
  /**
   * Дата приказа
   * @format date
   */
  date: string;
}

/** Ссылка на приказ на допуск КП */
export interface GenMainApprovalOfferRegistryLink {
  /** ID приказа на допуск КП */
  id: GenMainApprovalOfferRegistryId | null;
}

/** Приказ на допуск КП */
export type GenMainApprovalOfferRegistryShort = GenMainApprovalOfferRegistryRequest & GenMainApprovalOfferRegistryLink;

/** Приказ на допуск КП */
export type GenMainApprovalOfferRegistry = Override<
  GenMainApprovalOfferRegistryShort,
  {
    /** согласованные в приказе предложения */
    offers: GenMainOfferLink[] | null;
  }
>;

/**
 * ID PersonalPromotion
 * @format uuid
 */
export type GenMainPersonalPromotionId = string;

/** Способ продвижения предложений */
export enum GenMainPromotionType {
  PublicPromocode = 'PUBLIC_PROMOCODE',
  ReferralLink = 'REFERRAL_LINK',
  Widget = 'WIDGET',
  Promocode = 'PROMOCODE',
  Voucher = 'VOUCHER',
  AccessCode = 'ACCESS_CODE',
  CorpCertificate = 'CORP_CERTIFICATE',
  ExternalCorpCertificate = 'EXTERNAL_CORP_CERTIFICATE',
  Asp = 'ASP',
  Digift = 'DIGIFT',
}

/** Стаатус персонального кода */
export enum GenMainPromotionCodeStatus {
  Free = 'FREE',
  Used = 'USED',
  Disabled = 'DISABLED',
}

/**
 * Тип PersonalPromotion для КП
 *        * CORP_CERTIFICATE - Сертификат корпоративной системы(Льготы), получаемый в Витрине
 *        * EXTERNAL_CORP_CERTIFICATE - активация через внешнюю систему по ссылке externalActivationLink
 */
export enum GenMainCorpOfferPersonalPromotionType {
  CorpCertificate = 'CORP_CERTIFICATE',
  ExternalCorpCertificate = 'EXTERNAL_CORP_CERTIFICATE',
}

/** Данные для перс кода */
export interface GenMainPersonalPromotionData {
  /** перс. код */
  code: string;
  /** ID предложения */
  offerId: GenMainOfferId;
}

/** Генерация индивидуальных кодов */
export interface GenMainPersonalPromotionGenerateCommand {
  /**
   * Количество кодов
   * @format int32
   * @min 1
   * @max 9999
   */
  countCode: number;
  /** ID предложения */
  offerId: GenMainOfferId;
}

/** PersonalPromotion */
export interface GenMainPersonalPromotion {
  /** ID PersonalPromotion */
  id: GenMainPersonalPromotionId | null;
  /** перс. код */
  code: string | null;
  /** Способ продвижения предложений */
  type: GenMainPromotionType | null;
  /** Стаатус персонального кода */
  status: GenMainPromotionCodeStatus | null;
}

/** PersonalPromotionFull - для списков в админке */
export type GenMainPersonalPromotionFull = Override<
  GenMainPersonalPromotion,
  {
    /** Активация предложения */
    currentOfferActivation: GenMainOfferActivation | null;
  }
>;

/** Ссылка на активацию */
export interface GenMainOfferActivationId {
  /** ID заявки на активацию предложения */
  id: GenMainActivationId;
}

/** Заявка на активацию предложения c personalPromotion */
export type GenMainOfferActivationView = Override<
  UtilRequiredKeys<GenMainOfferActivationId, 'id'>,
  {
    /** PersonalPromotion */
    personalPromotion: GenMainPersonalPromotion;
    /** ссылка на пользователя */
    customer: GenMainUserLink;
    /** Сокращенное обобщенное представление предложения */
    offer: GenMainOfferView;
    /**
     * Статус активации предложения:
     * * PENDING - на рассмотрении
     * * APPROVED - одобрена
     * * REJECTED - отклонена
     * * GIVEN - выдано
     */
    status: GenMainOfferActivationStatus;
    /**
     * Цена без скидки
     * @format int64
     * @min 1
     */
    originalPrice: number | null;
    /**
     * Цена со скидкой
     * @format int64
     * @min 1
     */
    price: number | null;
  }
>;

/** Активация предложения */
export type GenMainBaseActivation = Override<
  GenMainAuditEntity & GenMainOfferActivationId & GenMainDiscriminatorObject,
  {
    /** Пользователь(сокращённая информация) */
    customer: GenMainUserShort | null;
    /** Основная информация предложения */
    offer: GenMainOfferDataShort | null;
    /**
     * Статус активации предложения:
     * * PENDING - на рассмотрении
     * * APPROVED - одобрена
     * * REJECTED - отклонена
     * * GIVEN - выдано
     */
    status: GenMainOfferActivationStatus | null;
    /** Комментарий к последнему статусу */
    lastStatusComment: string | null;
    /**
     * дата выдачи права на привилегию клиенту
     * @format date-time
     */
    appointmentDate: string | null;
    /**
     * Цена без скидки
     * @format int64
     * @min 1
     */
    originalPrice: number | null;
    /**
     * Цена со скидкой
     * @format int64
     * @min 1
     */
    price: number | null;
    /**
     * Дата до которой по активации можно получить привилегию
     * date-time для учета временной зоны на клиенте
     * @format date-time
     */
    expireDate: string | null;
    /**
     * дата выдачи права на привилегию клиенту
     * @format date-time
     */
    givenAt: string | null;
    /** Пользователь(сокращённая информация) */
    givenBy: GenMainUserShort | null;
  }
>;

/** Активация по персональному коду */
export type GenMainPersonalPromotionActivation = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'> & GenMainBaseActivation,
  {
    /** PersonalPromotion */
    promotion: GenMainPersonalPromotion;
    /** Транзакция бонусного счета */
    bonusTransaction: GenMainBonusTransaction | null;
  }
>;

/** Активация публичного предложения (неуникального) */
export type GenMainPublicActivation = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'> & GenMainBaseActivation,
  {
    /** PublicPromotion */
    promotion: GenMainPublicPromotion;
  }
>;

/** PublicPromotion */
export interface GenMainPublicPromotion {
  /** значение публичного предложения */
  publicPromotionValue: string | null;
  /** Способ продвижения предложений */
  type: GenMainPromotionType | null;
}

/** Активация КП */
export type GenMainCorpOfferActivation = GenMainBaseCorpOfferActivation &
  GenMainBaseCorpOfferActivationDiscriminatorMapping<'PersonalPromotionActivation', GenMainPersonalPromotionActivation>;

/** Активация ТП */
export type GenMainTradeOfferActivation = GenMainBaseTradeOfferActivation &
  (
    | GenMainBaseTradeOfferActivationDiscriminatorMapping<
        'PersonalPromotionActivation',
        GenMainPersonalPromotionActivation
      >
    | GenMainBaseTradeOfferActivationDiscriminatorMapping<'PublicActivation', GenMainPublicActivation>
  );

/** Активация предложения */
export type GenMainOfferActivation = GenMainCorpOfferActivation | GenMainTradeOfferActivation;

/** активация предложения, либо представление счетчика */
export type GenMainOfferActivationResponse = GenMainBaseOfferActivationResponse &
  (
    | GenMainBaseOfferActivationResponseDiscriminatorMapping<'OfferActivation', GenMainOfferActivation>
    | GenMainBaseOfferActivationResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** Действия с активацией */
export type GenMainActivationOfferAction = GenMainBaseActivationOfferAction &
  (
    | GenMainBaseActivationOfferActionDiscriminatorMapping<'RejectActivationCommand', GenMainRejectActivationCommand>
    | GenMainBaseActivationOfferActionDiscriminatorMapping<
        'CorpOfferActivationSetGivenCommand',
        GenMainCorpOfferActivationSetGivenCommand
      >
    | GenMainBaseActivationOfferActionDiscriminatorMapping<
        'CorpOfferActivationUnsetGivenCommand',
        GenMainCorpOfferActivationUnsetGivenCommand
      >
  );

/** Отмена активации админом */
export type GenMainRejectActivationCommand = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /**
     * комментарий
     * @minLength 1
     */
    comment: string;
  }
>;

/** Установка статуса выдано активации КП */
export type GenMainCorpOfferActivationSetGivenCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/** Снятие статуса выдано активации КП */
export type GenMainCorpOfferActivationUnsetGivenCommand = UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>;

/**
 * ID заказа
 * @format uuid
 */
export type GenMainOrderId = string;

/**
 * Статус заказа:
 * актуальные для заказа по КП/ТП:
 * * NEW - новый
 * * CONFIRMED - подтвержден
 * * RETURNED - возвращен
 * * CANCELLED - отменен
 * * GIVEN - получен клиентом
 * * ACTIVE - активен
 * актуальные для заказа по ПП:
 * * NEW - создан
 * * CONFIRMED - принят
 * * PAID - оплачен
 * * SENT - отправлен
 * * RETURNED - возврат полный
 * * PARTIALLY_RETURNED - возврат частичный
 * * CANCELLED - отменен
 * * GIVEN - получен
 */
export enum GenMainOrderStatus {
  New = 'NEW',
  Confirmed = 'CONFIRMED',
  Paid = 'PAID',
  Sent = 'SENT',
  Returned = 'RETURNED',
  PartiallyReturned = 'PARTIALLY_RETURNED',
  Cancelled = 'CANCELLED',
  Given = 'GIVEN',
  Active = 'ACTIVE',
}

/**
 * Статус позиции заказа:
 * * NEW - новая
 * * CONFIRMED - подтверждена
 * * RETURNED - возвращена полностью
 * * CANCELLED - отменена
 * * GIVEN - получена клиентом
 * * CHANGED - состав позиции изменен
 * * PARTIALLY_RECEIVED - частично получена клиентом
 */
export enum GenMainOrderItemStatus {
  New = 'NEW',
  Confirmed = 'CONFIRMED',
  Returned = 'RETURNED',
  Cancelled = 'CANCELLED',
  Given = 'GIVEN',
  Changed = 'CHANGED',
  PartiallyReceived = 'PARTIALLY_RECEIVED',
}

/** Запрос на создание заказа из админки */
export type GenMainActivationOrderRequest = {
  /**
   * Общая стоимость в рублях без скидки
   * @multipleOf 0.01
   */
  originalCost: number | null;
  /**
   * Общая стоимость со скидкой в рублях
   * @multipleOf 0.01
   */
  cost: number | null;
  /** Описание заказа(для ваучера - состав заказа) */
  description: string | null;
  /** структура для ссылки и описания ресурса(файла) */
  receiptImage: GenMainFileLink | null;
  /**
   * Статус заказа:
   * актуальные для заказа по КП/ТП:
   * * NEW - новый
   * * CONFIRMED - подтвержден
   * * RETURNED - возвращен
   * * CANCELLED - отменен
   * * GIVEN - получен клиентом
   * * ACTIVE - активен
   * актуальные для заказа по ПП:
   * * NEW - создан
   * * CONFIRMED - принят
   * * PAID - оплачен
   * * SENT - отправлен
   * * RETURNED - возврат полный
   * * PARTIALLY_RETURNED - возврат частичный
   * * CANCELLED - отменен
   * * GIVEN - получен
   */
  status: GenMainOrderStatus;
  /** Ссылка на активацию */
  offerActivation: GenMainOfferActivationId;
  /** Причины отклонения заказа */
  cancelReason: GenMainOrderCancellationReasonRequest | null;
};

/** Заказ */
export type GenMainOrderShort = GenMainBaseOrderShort &
  (
    | GenMainBaseOrderShortDiscriminatorMapping<'ProductOrderShort', GenMainProductOrderShort>
    | GenMainBaseOrderShortDiscriminatorMapping<'BookingOrderShort', GenMainBookingOrderShort>
  );

/** Заказ букинга */
export type GenMainBookingOrderShort = GenMainDiscriminatorObject & {
  /** ID заказа */
  id: GenMainOrderId;
  /**
   * Статус заказа:
   * актуальные для заказа по КП/ТП:
   * * NEW - новый
   * * CONFIRMED - подтвержден
   * * RETURNED - возвращен
   * * CANCELLED - отменен
   * * GIVEN - получен клиентом
   * * ACTIVE - активен
   * актуальные для заказа по ПП:
   * * NEW - создан
   * * CONFIRMED - принят
   * * PAID - оплачен
   * * SENT - отправлен
   * * RETURNED - возврат полный
   * * PARTIALLY_RETURNED - возврат частичный
   * * CANCELLED - отменен
   * * GIVEN - получен
   */
  status: GenMainOrderStatus;
  offer: Override<
    GenMainOfferLink,
    {
      /** Наименование объекта ДиО */
      name: string | null;
    }
  > | null;
  /** номер заказа */
  number: string | null;
  /**
   * Итоговая сумма заказа
   * @multipleOf 0.01
   */
  cost: number | null;
  /** Покупатель */
  customer: GenMainCustomer | null;
  /** Комментарий к последнему статусу */
  lastStatusComment: string | null;
  /** Структура для выдачи элемента справочника */
  cancelReason: GenMainOption | null;
  /** Структура для выдачи элемента справочника */
  customerCancelReason: GenMainOption | null;
  /** Партнёр */
  partner: GenMainPartnerView | null;
  /**
   * Заказ создан для объекта с поддержкой РЖД соцпакета
   * пользователем, который на момент создания заказа обладал
   * соцпакетом в статусе ACTIVE
   * @default false
   */
  hasRzdSocialPackage: boolean | null;
} & GenMainAuditEntity;

/** Заказ товаров */
export type GenMainProductOrderShort = GenMainDiscriminatorObject & {
  /** ID заказа */
  id: GenMainOrderId;
  /** номер заказа */
  number: string;
  /**
   * Статус заказа:
   * актуальные для заказа по КП/ТП:
   * * NEW - новый
   * * CONFIRMED - подтвержден
   * * RETURNED - возвращен
   * * CANCELLED - отменен
   * * GIVEN - получен клиентом
   * * ACTIVE - активен
   * актуальные для заказа по ПП:
   * * NEW - создан
   * * CONFIRMED - принят
   * * PAID - оплачен
   * * SENT - отправлен
   * * RETURNED - возврат полный
   * * PARTIALLY_RETURNED - возврат частичный
   * * CANCELLED - отменен
   * * GIVEN - получен
   */
  status: GenMainOrderStatus;
  /** Комментарий к последнему статусу */
  lastStatusComment: string | null;
  /** Адрес */
  deliveryAddress: GenMainAddress | null;
  /**
   * Стоимость доставки в рублях
   * @format int32
   */
  deliveryCost: number | null;
  /** Партнёр */
  partner: GenMainPartnerView | null;
  /**
   * Общая стоимость в рублях без скидки
   * @multipleOf 0.01
   */
  originalCost: number | null;
  /**
   * Общая стоимость со скидкой в рублях
   * @multipleOf 0.01
   */
  cost: number | null;
} & GenMainAuditEntity;

/** Полные данные по заказу */
export type GenMainOrderFull = GenMainBaseOrderFull &
  (
    | GenMainBaseOrderFullDiscriminatorMapping<'ProductOrder', GenMainProductOrder>
    | GenMainBaseOrderFullDiscriminatorMapping<'BookingOrder', GenMainBookingOrder>
  );

/** Полные данные по заказу товаров */
export type GenMainProductOrder = Override<
  GenMainProductOrderShort & GenMainDiscriminatorObject,
  {
    /** Покупатель */
    customer: GenMainCustomer;
    /**
     * Дата создания заказа в системе партнера
     * @format date-time
     */
    createdDate: string | null;
    /**
     * Дата обновления заказа в системе партнера
     * @format date-time
     */
    updatedDate: string | null;
    /** Структура для выдачи элемента справочника */
    cancelReason: GenMainOption | null;
    /** Структура для выдачи элемента справочника */
    customerCancelReason: GenMainOption | null;
    /** Способ оплаты заказа товара */
    paymentType: GenMainProductOrderPaymentType | null;
    /** Условие оплаты */
    paymentConditions: string | null;
    /** Способ доставки */
    deliveryType: GenMainDeliveryType | null;
    /** Условие доставки */
    deliveryConditions: string | null;
    /** Товары в заказе */
    items: GenMainProductOrderItem[] | null;
  }
>;

/** Полные данные по заказу букинга */
export type GenMainBookingOrder = Override<
  GenMainBookingOrderShort & GenMainDiscriminatorObject,
  {
    /** BookingOrderOffer */
    offer: Override<
      GenMainOfferLink,
      {
        /** Наименование объекта ДиО */
        name: string | null;
        /** код предложения */
        code: string | null;
        /**
         * Изображения
         * @maxItems 30
         */
        images: GenMainFileLink[] | null;
      }
    >;
    /**
     * Телефон для связи с клиентом
     * @minLength 1
     */
    customerPhone: string | null;
    /** Комментарий к заказу (пожелания) */
    customerComment: string | null;
    /** Текущий АКТИВНЫЙ соц. пакет покупателя из заказа на ДАННЫЙ момент */
    customerRzdSocialPackage: GenMainRzdSocialPackage | null;
    /** Комментарий менеджера */
    managerComment: string | null;
    /** Состав заказа */
    orderItems: GenMainBookingOrderItem[] | null;
  }
>;

/** список заказов либо их количество */
export type GenMainOrderFullResponse = GenMainBaseOrderFullResponse &
  (
    | GenMainBaseOrderFullResponseDiscriminatorMapping<'OrderFull', GenMainOrderFull>
    | GenMainBaseOrderFullResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** Пункт заказа общая часть */
export interface GenMainOrderItemData {
  /** Ссылка на предложение */
  offer: GenMainOfferLink | null;
  /**
   * Общая стоимость в рублях без скидки
   * @multipleOf 0.01
   */
  originalCost: number | null;
  /**
   * Общая стоимость со скидкой в рублях
   * @multipleOf 0.01
   */
  cost: number | null;
  /**
   * Значение общей скидки в рублях
   * @multipleOf 0.01
   */
  discount: number | null;
  /** Название позиции заказа */
  name: string | null;
  /** структура для ссылки и описания ресурса(файла) */
  image: GenMainFileLink | null;
  /**
   * Количество единиц в позиции
   * @format int64
   */
  qty: number | null;
  /**
   * Статус позиции заказа:
   * * NEW - новая
   * * CONFIRMED - подтверждена
   * * RETURNED - возвращена полностью
   * * CANCELLED - отменена
   * * GIVEN - получена клиентом
   * * CHANGED - состав позиции изменен
   * * PARTIALLY_RECEIVED - частично получена клиентом
   */
  status: GenMainOrderItemStatus;
}

/** Пункт заказа букинга */
export type GenMainBookingOrderItem = Override<
  GenMainOrderItemData,
  {
    /**
     * ID пункта заказа
     * @format uuid
     */
    id: string | null;
    /**
     * Общая стоимость пункта заказа
     * @multipleOf 0.01
     */
    totalCost: number | null;
    /** Справочные данные по тарифу услуги НА МОМЕНТ ЗАКАЗА */
    priceItem: GenMainOrderServicePriceItem | null;
    /** Временные слоты */
    slots: GenMainBookingDateSlot[] | null;
  }
>;

/**
 * Временной слот в заказе букинга
 * Для заказа по дням - startDate должно совпадать с endDate
 */
export interface GenMainBookingDateSlot {
  /**
   * Дата начала временного слота
   * @format date
   */
  startDate: string;
  /**
   * Дата окончания временного  слота
   * @format date
   */
  endDate: string;
  /**
   * Количество тарифицируемых единиц(места, гости)
   * @format int64
   */
  qty: number;
}

/** Справочные данные по тарифу услуги НА МОМЕНТ ЗАКАЗА */
export type GenMainOrderServicePriceItem = Override<
  GenMainServicePriceItemLink,
  {
    /** Название тарифа */
    name: string | null;
    unit: {
      /** Название единицы тарификации */
      name: string | null;
    } | null;
    /**
     * Цена в рублях
     * @format int64
     * @min 0
     */
    price: number | null;
    /**
     * ServiceSnapshot
     * Слепок данных об услуге на момент заказа
     */
    service: Override<
      GenMainOfferServiceLink,
      {
        /** Название услуги */
        name: string | null;
        /** Описание услуги */
        description: string | null;
        /** Представление категории услуги */
        category: GenMainServiceCategoryShort | null;
        /**
         * Способ заказа услуги по датам
         *  * PERIOD - Можно выбрать период
         *  * DAYS - Можно выбрать по дням
         *  * NONE - Нельзя выбрать даты
         */
        orderByDateType: GenMainServiceOrderByDateType | null;
      }
    > | null;
  }
>;

/** Пункт заказа товаров */
export type GenMainProductOrderItem = GenMainOrderItemData;

/**
 * ID настройки уведомления
 * @format uuid
 */
export type GenMainNotificationOptionId = string;

/** Настройка уведомления пользователя о событиях в системе */
export interface GenMainNotificationOption {
  /** ID настройки уведомления */
  id: GenMainNotificationOptionId;
  /** название уведомдения для пользователя */
  name: string;
  /** описание уведомдения для пользователя */
  description: string | null;
}

/** Системная настройка уведомления */
export type GenMainGlobalNotificationOption = Override<
  GenMainNotificationOption,
  {
    /** уведомление включено */
    enabled: boolean;
  }
>;

/** Настройка одного уведомления пользователя */
export interface GenMainNotificationSettingData {
  /** ID настройки уведомления */
  optionId: GenMainNotificationOptionId;
  /** уведомление включено */
  enabled: boolean;
}

/** Настройка одного уведомления пользователя */
export interface GenMainNotificationSetting {
  /** Настройка уведомления пользователя о событиях в системе */
  option: GenMainNotificationOption;
  /** уведомление включено */
  enabled: boolean;
}

/** Код уведомления */
export enum GenMainNotificationCode {
  OfferToModeration = 'OFFER_TO_MODERATION',
  OfferExpired = 'OFFER_EXPIRED',
  OfferActivated = 'OFFER_ACTIVATED',
  OfferRejected = 'OFFER_REJECTED',
  NewPromotions = 'NEW_PROMOTIONS',
  NewFavoriteOffers = 'NEW_FAVORITE_OFFERS',
}

/** Структура дороги, содержащиая только ИД */
export interface GenMainRoadLink {
  /**
   * Идентификатор дороги
   * @format uuid
   */
  id: string;
}

/** Дорога */
export type GenMainRoad = Override<
  UtilRequiredKeys<GenMainRoadLink, 'id'>,
  {
    /** Наименование дороги */
    name: string;
  }
>;

/** Структура подразделения РЖД, содержащиая только ИД */
export interface GenMainOrgUnitLink {
  /** Идентификатор подразделения */
  id: GenMainOrgUnitId;
}

/**
 * Идентификатор подразделения
 * @format uuid
 */
export type GenMainOrgUnitId = string;

/** Подразделение */
export type GenMainOrgUnit = Override<
  UtilRequiredKeys<GenMainOrgUnitLink, 'id'>,
  {
    /** Структура подразделения РЖД, содержащиая только ИД */
    parent: GenMainOrgUnitLink | null;
    /** Наименование подразделения */
    name: string;
    /** Полное наименование подразделения */
    fullName: string | null;
    /** Код ЕКАСУТР */
    ekasutrId: string | null;
    /** Тип организации */
    type: string | null;
  }
>;

/** Элемент лога пользователей */
export interface GenMainHttpLogItem {
  /**
   * Сквозной id запроса
   * @format uuid
   */
  id: string | null;
  /** Вызываемый api метод */
  requestURL: string | null;
  /** Заголовки */
  requestHeaders: string | null;
  /** Ip-адрес */
  ip: string | null;
  /**
   * Время запроса
   * @format date-time
   */
  requestDate: string | null;
  /** Текст запроса */
  requestBody: string | null;
  /**
   * Время ответа
   * @format date-time
   */
  responseDate: string | null;
  /** Текст ответа */
  responseBody: string | null;
  /** ID пользователя */
  userId: GenMainUserId | null;
  /**
   * email
   * @format email
   */
  userEmail: string | null;
  /** ФИО пользователя */
  userFullName: string | null;
  /** Подразделение */
  orgUnitName: string | null;
}

/** Элемент лога взаимодействия с СП */
export interface GenMainSpIntLogItem {
  /** Ссылка на предложение */
  offer: GenMainOfferLink | null;
  /** Вызываемый api метод */
  requestUrl: string | null;
  /** Данные запроса в json формате */
  requestBody: string | null;
  /**
   * Время запроса
   * @format date-time
   */
  requestDate: string | null;
  /**
   * Время ответа
   * @format date-time
   */
  responseDate: string | null;
  /**
   * Код ответа
   * @format int32
   */
  responseCode: number | null;
  /** Текст ответа */
  responseMessage: string | null;
}

/** Элемент лога взаимодействия с Льготами */
export type GenMainBenefitIntLogItem = GenMainSpIntLogItem;

export interface GenMainXLSXImportResult {
  /** количество строк всего */
  stringCount: number;
  /** количество импортированных сущностей */
  successCount: number;
  /** количество некорректных строк */
  errorCount: number;
  /** лог ошибок */
  errors: GenMainXLSXImportError[] | null;
}

export interface GenMainXLSXImportError {
  /** номер строки в файле */
  stringNumber: number;
  /** имя поля */
  columnName: string | null;
  /** описание ошибки */
  descr: string;
  /** код ошибки */
  errorCode: string | null;
}

/** структура для ссылки и описания ресурса(файла) */
export interface GenMainFileLink {
  /**
   * Путь к файлу в хранилище (bucket/name) Можно использовать как id файла в хранилище
   * @minLength 1
   */
  path: string;
}

/**
 * Тип организации
 * * INDIVIDUAL_ENTREPRENEUR - ИП
 * * LIMITED_LIABILITY_COMPANY - ООО
 * * STOCK_COMPANY - АО
 * * PUBLIC_JOIN_STOCK_COMPANY - ПАО
 * * PK - ПК
 * * UP - УП
 * * FGBNU - ФГБНУ
 * * NONPROFIT_ORGANIZATION - НКО
 * * CLOSED_JOIN_STOCK_COMPANY - ЗАО
 * * JOIN_STOCK_COMPANY - ОАО
 * * NAO - НАО
 * * AUTONOMOUS_NONPROFIT_ORGANIZATION - АНО
 */
export enum GenMainOrgType {
  IndividualEntrepreneur = 'INDIVIDUAL_ENTREPRENEUR',
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  StockCompany = 'STOCK_COMPANY',
  PublicJoinStockCompany = 'PUBLIC_JOIN_STOCK_COMPANY',
  Pk = 'PK',
  Up = 'UP',
  Fgbnu = 'FGBNU',
  NonprofitOrganization = 'NONPROFIT_ORGANIZATION',
  ClosedJoinStockCompany = 'CLOSED_JOIN_STOCK_COMPANY',
  JoinStockCompany = 'JOIN_STOCK_COMPANY',
  Nao = 'NAO',
  AutonomousNonprofitOrganization = 'AUTONOMOUS_NONPROFIT_ORGANIZATION',
}

/**
 * Pageable
 * структура для пагинации и сортировки(сортировка вида firstName,asc)
 */
export interface GenMainPageable {
  /**
   * необходимая страница
   * @format int32
   * @min 0
   */
  page: number | null;
  /**
   * Количество элементов на страницу
   * если указать больше maximum, то будет size=maximum
   * независимо от size в запросе
   * @format int32
   * @min 1
   * @max 1000
   * @default 100
   */
  size: number | null;
  /** сортировка вида firstName,asc */
  sort: string[] | null;
}

/** Система налогообложения */
export enum GenMainTaxSystem {
  Osno = 'OSNO',
  Usn = 'USN',
}

/**
 * Индекс для сортировки
 * @format int32
 * @min 0
 */
export type GenMainSortIndex = number;

/** Запрос на изменение сортиндекса */
export interface GenMainSortIndexRequest {
  /** Индекс для сортировки */
  sortIndex: GenMainSortIndex;
}

/** Максимальный индекс сортировки */
export interface GenMainLastSortIndex {
  /** Индекс для сортировки */
  sortIndex: GenMainSortIndex | null;
}

/** Баланс бонусного счета пользователя */
export interface GenMainAccountBalance {
  /** @format int64 */
  balance: number | null;
}

/**
 * Тип результата статистики
 * @default "query"
 */
export enum GenMainSearchQueryStatResultType {
  Query = 'query',
  Geo = 'geo',
  Counter = 'counter',
}

/** Статистика поисковых запросов */
export type GenMainSearchQueryStat = GenMainBaseSearchQueryStat &
  (
    | GenMainBaseSearchQueryStatDiscriminatorMapping<'SearchQueryKeywordStat', GenMainSearchQueryKeywordStat>
    | GenMainBaseSearchQueryStatDiscriminatorMapping<'SearchQueryGeoStat', GenMainSearchQueryGeoStat>
    | GenMainBaseSearchQueryStatDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** Поисковый зарос пользователя */
export interface GenMainSearchEvent {
  query: string;
  /** Адрес */
  geo: GenMainAddress | null;
  /** @format date-time */
  date: string;
  /** Пользователь(сокращённая информация) */
  user: GenMainUserShort | null;
}

/** Статистика поисковых запросов по ключевым словам */
export type GenMainSearchQueryKeywordStat = Override<
  GenMainDiscriminatorObject,
  {
    /** поисковый запрос */
    query: string;
    /**
     * Число запросов
     * @format int64
     * @min 1
     */
    count: number;
  }
>;

/** Статистика поисковых запросов по геообъектам */
export type GenMainSearchQueryGeoStat = Override<
  GenMainDiscriminatorObject,
  {
    /** Адрес */
    geo: GenMainAddress;
    /**
     * Число запросов
     * @format int64
     * @min 1
     */
    count: number;
  }
>;

/** Объект аудита */
export interface GenMainAuditEntity {
  /**
   * Дата создания
   * @format date-time
   */
  createdAt: string;
  /**
   * ИД автора
   * @format uuid
   */
  createdBy: string | null;
  /**
   * Дата обновления
   * @format date-time
   */
  updatedAt: string | null;
  /**
   * ИД автора
   * @format uuid
   */
  updatedBy: string | null;
}

/** Стандартный ответ при негативном сценарии */
export interface GenMainError {
  /** Код ошибки(например PRODUCT_NOT_FOUND) */
  code: string;
  /** Сообщение */
  message: string;
  /** Доп информация */
  description: string | null;
  /** атомарные ошибки(например валидация полей) */
  errors: GenMainErrorObject[] | null;
}

/** ошибка валидации */
export interface GenMainErrorObject {
  /** Код ошибки */
  code: string;
  /** Поле объекта -  для ошибок валидации полeй */
  field: string | null;
  /** Сообщение */
  message: string;
}

/** Структура для выдачи элемента справочника */
export interface GenMainOption {
  /** @format uuid */
  id: string;
  /** код справочной сущности сущности для показа в ui(может отсутствовать) */
  code: string | null;
  /** название справочной сущности для показа в ui */
  name: string;
}

/** Структура для счётчика */
export type GenMainCounter = Override<
  GenMainDiscriminatorObject,
  {
    /** @format int64 */
    count: number;
  }
>;

/**
 * Тип возвращаемого значения
 * @default "list"
 */
export enum GenMainResultType {
  List = 'list',
  Count = 'count',
}

/**
 * Структура для фильтра с dsl
 * @example "{
 *   "field": "name",
 *   "operator": "=",
 *   "value": ["example"]
 * }
 * "
 */
export interface GenMainPredicate {
  /** название поля сущности */
  field: string;
  /**
   * оператор: like - регистронезависимый like
   *        [string]% - поиск строки по началу
   *        %[string]% - поиск строки по вхождению
   */
  operator: '=' | '<' | '>' | '>=' | '<=' | 'in' | 'like' | '!=' | 'not in';
  /**
   * значение параметра фильтра
   * @minItems 1
   */
  value: string[];
}

/**
 * ИД баннера
 * @format uuid
 */
export type GenMainBannerId = string;

/** Основные данные баннера */
export interface GenMainBannerData {
  /** структура для ссылки и описания ресурса(файла) */
  desktopFormatImage: GenMainFileLink | null;
  /** структура для ссылки и описания ресурса(файла) */
  mobileFormatImage: GenMainFileLink | null;
  /**
   * Дата начала срока размещения
   * @format date
   */
  startDate: string | null;
  /**
   * Дата окончания срока размещения
   * @format date
   */
  endDate: string | null;
  /**
   * Название
   * @minLength 1
   */
  name: string;
  /**
   * Url на страницу предложения
   * @format url
   */
  url: string | null;
  /** Опции таргета на сайте */
  target: GenMainTargetRequest | null;
}

/** Баннер */
export type GenMainBanner = Override<
  GenMainBannerData & GenMainDiscriminatorObject,
  {
    /** ИД баннера */
    id: GenMainBannerId | null;
    /** Код баннера - генерируется автоматом */
    code: string | null;
    /**
     * Статус баннера
     * * DRAFT - черновик
     * * UPCOMING - предстоящий (дата размещения еще не наступила)
     * * ACTIVE - активный (дата размещения наступила и срок размещения еще не истек)
     * * PAUSED - черновик (присотановлен админом)
     * * ARCHIVED - черновик (срок размещения истек или архиврован админом)
     */
    status: GenMainBannerStatus | null;
    /** Индекс для сортировки */
    sortIndex: GenMainSortIndex | null;
    /** Список мест размещения */
    places: GenMainBannerPlace[] | null;
    /** Опции таргета на сайте */
    target: GenMainTarget | null;
    /** Блокировка пользователем */
    lock: GenMainExclusiveLock | null;
  }
>;

/** список баннеров либо их количество */
export type GenMainBannerResponse = GenMainBaseBannerResponse &
  (
    | GenMainBaseBannerResponseDiscriminatorMapping<'Banner', GenMainBanner>
    | GenMainBaseBannerResponseDiscriminatorMapping<'Counter', GenMainCounter>
  );

/** Блокировка пользователем */
export interface GenMainExclusiveLock {
  /**
   * Дата создания
   * @format date-time
   */
  lockedAt: string | null;
  /** Структура для выдачи элемента справочника */
  lockedBy: GenMainOption | null;
}

/** Запрос на создание/редактирования баннера */
export type GenMainBannerRequest = Override<
  GenMainBannerData,
  {
    /** Список кодов мест размещения */
    placeCodes: string[] | null;
  }
>;

/**
 * Статус баннера
 * * DRAFT - черновик
 * * UPCOMING - предстоящий (дата размещения еще не наступила)
 * * ACTIVE - активный (дата размещения наступила и срок размещения еще не истек)
 * * PAUSED - черновик (присотановлен админом)
 * * ARCHIVED - черновик (срок размещения истек или архиврован админом)
 */
export enum GenMainBannerStatus {
  Draft = 'DRAFT',
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Archived = 'ARCHIVED',
}

/** Код баннера */
export type GenMainBannerPlaceCode = string;

/** Место размещения баннера */
export interface GenMainBannerPlace {
  /**
   * Название
   * @minLength 1
   */
  name: string;
  /**
   * Уникальный код, используется как значение параметра запроса получения списка баннеров для определенного места на странице
   * @minLength 1
   */
  code: string;
}

/**
 * Тип таргетирования
 * * GEO - географическое
 * * CORP - корпоративное
 */
export enum GenMainTargetType {
  Geo = 'GEO',
  Corp = 'CORP',
}

/**
 * Тип предложения
 * * TRADE_OFFER - торговое предложение
 * * CORP_OFFER - корпоративное предложение
 * * PRODUCT_OFFER - товарное предложение
 * * BOOKING_OFFER - Объект Досуга и Отдыха
 */
export enum GenMainOfferType {
  TradeOffer = 'TRADE_OFFER',
  CorpOffer = 'CORP_OFFER',
  ProductOffer = 'PRODUCT_OFFER',
  BookingOffer = 'BOOKING_OFFER',
}

/**
 * Группы пользователей
 * * ALL - все пользователи
 * * RZD - сотрудники РЖД
 * * EXTERNAL - внешние пользователи
 * * CORP - корпоранты
 * * GUEST - гости
 * @default "ALL"
 */
export enum GenMainReportUserGroup {
  All = 'ALL',
  Rzd = 'RZD',
  External = 'EXTERNAL',
  Corp = 'CORP',
  Guest = 'GUEST',
}

/**
 * Тип события, инициированного пользователем
 * Преднастроенные коды типов событий:
 * * OFFER_RECEIVE_CLICKED - Осуществлен клик на получение предложения
 * * JUMP_TO_PARTNER_SITE - Осуществлен переход на сайт партнера
 * * ORDER_CREATED - Заказ по предложению создан
 * * OBJECT_ADDED_TO_FAVORITES - Объект добавлен в избранное
 * * ADMIN_OFFER_VIEW - Просмотр каточки предложения в админке(последнее по предложению)
 * * CUSTOMER_OFFER_VIEW - Просмотр каточки предложения в Витрине
 * * CUSTOMER_OFFER_SHOW - показ каточки предложения в Витрине в списке предложений
 * * CUSTOMER_OFFER_SHOW_SEARCH - Осуществлен показ предложения через поиск
 * * CUSTOMER_OFFER_SHOW_LIST - Осуществлен показ предложения через каталог
 * * CUSTOMER_OFFER_VIEW_SEARCH - Осуществлен просмотр предложения через поиск
 * * CUSTOMER_OFFER_VIEW_LIST - Осуществлен просмотр предложения через каталог
 */
export interface GenMainUserEventType {
  /**
   * Название
   * @minLength 1
   */
  name: string;
  /**
   * Уникальный код
   * @minLength 1
   */
  code: string;
  /**
   * Является ли событие данного типа уникальным
   * @default false
   */
  unique: boolean | null;
  /**
   * Может ли событие быть инициировано анонимным пользователем
   * @default false
   */
  canBeAnonymous: boolean | null;
}

/** Данные события, инициированного пользователем */
export interface GenMainUserEvent {
  /**
   * Код
   * @minLength 1
   */
  typeCode: string;
}

/** Данные события, связанные с предложением, инициированного пользователем */
export type GenMainOfferUserEvent = {
  /**
   * Код события
   * @minLength 1
   */
  typeCode: string;
  /** Ссылка на предложение */
  offer: GenMainOfferLink;
};

/** Транзакция бонусного счета */
export type GenMainBonusTransaction = Override<
  GenMainAuditEntity,
  {
    /** ID фин транзакции */
    id: GenMainTransactionId;
    /**
     * сумма в баллах
     * @format int64
     * @min 1
     */
    amount: number;
    /**
     * Статус транзакции:
     * * PENDING - ожидает исполнения
     * * COMPLETED - выполнено
     * * CANCELLED - отменено
     */
    status: GenMainTransactionStatus;
    /**
     * Тип транзакции:
     * * CREDIT - начисление на бонусный счет
     * * DEBIT - списание с бонусного счета
     */
    type: GenMainTransactionType;
  }
>;

/**
 * Статус транзакции:
 * * PENDING - ожидает исполнения
 * * COMPLETED - выполнено
 * * CANCELLED - отменено
 */
export enum GenMainTransactionStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

/**
 * Статус активации предложения:
 * * PENDING - на рассмотрении
 * * APPROVED - одобрена
 * * REJECTED - отклонена
 * * GIVEN - выдано
 */
export enum GenMainOfferActivationStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Given = 'GIVEN',
}

/**
 * Тип транзакции:
 * * CREDIT - начисление на бонусный счет
 * * DEBIT - списание с бонусного счета
 */
export enum GenMainTransactionType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
}

/**
 * ID подборки ресурсов
 * @format uuid
 */
export type GenMainCollectionId = string;

/**
 * ID элемента корзины
 * @format uuid
 */
export type GenMainBasketItemId = string;

/**
 * ID элемента истории предложения
 * @format uuid
 */
export type GenMainOfferStateId = string;

/** ссылка на элемент корзины */
export interface GenMainBasketItemLink {
  /** ID элемента корзины */
  id: GenMainBasketItemId | null;
}

/** Запрос на добавление предложения в корзину */
export interface GenMainBasketItemRequest {
  /** ID предложения */
  offer: GenMainOfferId;
  /**
   * количество
   * @format int32
   * @min 1
   * @default 1
   */
  quantity: number;
}

/** элемент корзины */
export type GenMainBasketItem = Override<
  GenMainBasketItemLink,
  {
    /** Товарное предложение - представление для покупателя */
    offer: GenMainCustomerProductOfferShort | null;
    /**
     * количество данного предложения всего
     * @format int32
     * @min 1
     * @default 1
     */
    quantity: number | null;
  }
>;

/** корзина */
export interface GenMainBasket {
  /** элементы корзины */
  items: GenMainBasketItem[] | null;
}

/** Команда на создание нового заказ покупателем */
export type GenMainCustomerOrderRequest = GenMainBaseCustomerOrderRequest &
  (
    | GenMainBaseCustomerOrderRequestDiscriminatorMapping<'ProductOrderRequest', GenMainProductOrderRequest>
    | GenMainBaseCustomerOrderRequestDiscriminatorMapping<'BookingOrderRequest', GenMainBookingOrderRequest>
  );

/**
 * Запрос на создание на создание товарного заказа
 * При недостатке товара заказ не создается и возвращается ошибка c кодом:
 *       * PRODUCT_OFFER_INSUFFICIENT_STOCK - недостаточное количество товара в наличии
 */
export type GenMainProductOrderRequest = Override<
  GenMainDiscriminatorObject,
  {
    /**
     * Пункты товарной корзины
     * @minItems 1
     */
    items: GenMainBasketItemLink[] | null;
  }
>;

/** Запрос на создание заказа букинга */
export type GenMainBookingOrderRequest = Override<
  UtilRequiredKeys<GenMainDiscriminatorObject, 'discriminator'>,
  {
    /** Комментарий к заказу (пожелания) */
    customerComment: string | null;
    /**
     * Телефон для связи с клиентом
     * @minLength 1
     */
    customerPhone: string | null;
    /**
     * Пункты заказа букинга
     * @minItems 1
     */
    items: GenMainBookingOrderRequestItem[];
  }
>;

/**
 * Запрос на заказ услуги по тарифу.
 * qty указывается если услуга не заказывается по датам
 * slots - указывается только если заказ по датам или периоду
 */
export interface GenMainBookingOrderRequestItem {
  /** Ссылка на тариф по услуге */
  priceItem: GenMainServicePriceItemLink;
  /**
   * Количество единиц в позиции (для тарифа БЕЗ ВРЕМЕННЫХ СЛОТОВ)
   * @format int64
   */
  qty: number | null;
  /** Временные слоты (для тарифа С ВРЕМЕННЫМИ СЛОТАМИ) */
  slots: GenMainBookingDateSlot[] | null;
}

export type GenMainTargetFilter = GenMainTargetRequest;

/**
 * Значение таргета для предпросмотра
 * JSON в кодировке base64url
 * JSON из   $ref: "#/components/schemas/TargetRequest"
 * @example ""
 */
export type GenMainTargetBase64 = GenMainTargetRequest;

/** Опции таргета на сайте */
export type GenMainTarget = UtilRequiredKeys<GenMainTargetRequest, 'targetType'> & {
  /** Мультиселектор - Населённые пункты для таргетирования */
  targetLocalities: GenMainLocalityMultiSelectorPredicate | null;
  targetRoads: GenMainRoadMultiSelectorPredicate | null;
  targetOrgUnits: GenMainOrgUnitMultiSelectorPredicate | null;
  targetClientOrgs: GenMainClientOrgMultiSelectorPredicate | null;
};

/** Опции таргета на сайте */
export interface GenMainTargetRequest {
  /**
   * Тип таргетирования
   * * GEO - географическое
   * * CORP - корпоративное
   */
  targetType: GenMainTargetType | null;
  targetLocalities: GenMainLocalityMultiSelectorPredicateRequest | null;
  targetRoads: GenMainRoadMultiSelectorPredicateRequest | null;
  targetOrgUnits: GenMainOrgUnitMultiSelectorPredicateRequest | null;
  targetClientOrgs: GenMainClientOrgMultiSelectorPredicateRequest | null;
  /** Внешние пользователи */
  targetExternalUsers: boolean | null;
  targetGender: GenMainGenderMultiSelectorPredicate | null;
  /** Только для членов профсоюза */
  targetTradeUnionMembersOnly: boolean | null;
  /** Только если есть ребенок до 18 лет */
  targetHavingChildFamilyMemberOnly: boolean | null;
  /** Только для членов семьи */
  targetFamilyMemberOnly: boolean | null;
  /** Признак наличия социального пакета */
  targetHasRzdSocialPackage: boolean | null;
}

/** Оператор утверждения(all - все значения перечисления, in - подмножество)) */
export enum GenMainMultiSelectorValueType {
  All = 'all',
  In = 'in',
}

/**
 * Утверждение о том, что выбраны все или  некоторые значения из множества
 * При type=all значения из поля in игнорируются
 */
export interface GenMainMultiSelectorPredicate {
  /** Оператор утверждения(all - все значения перечисления, in - подмножество)) */
  select: GenMainMultiSelectorValueType;
}

/** Мультиселектор - Населённые пункты для таргетирования */
export type GenMainLocalityMultiSelectorPredicate = GenMainMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenMainAddress[] | null;
};

export type GenMainLocalityMultiSelectorPredicateRequest = GenMainMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenMainAddressObjectLink[] | null;
};

export type GenMainRoadMultiSelectorPredicateRequest = GenMainMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenMainRoadLink[] | null;
};

export type GenMainRoadMultiSelectorPredicate = GenMainMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenMainRoad[] | null;
};

export type GenMainOrgUnitMultiSelectorPredicateRequest = GenMainMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenMainOrgUnitLink[] | null;
};

export type GenMainOrgUnitMultiSelectorPredicate = GenMainMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenMainOrgUnit[] | null;
};

export type GenMainClientOrgMultiSelectorPredicate = GenMainMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenMainClientOrgShort[] | null;
};

export type GenMainGenderMultiSelectorPredicate = GenMainMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenMainGender[] | null;
};

export type GenMainClientOrgMultiSelectorPredicateRequest = GenMainMultiSelectorPredicate & {
  /**
   * Список значения для type=in
   * @minItems 1
   */
  in: GenMainClientOrgLink[] | null;
};

/** Геопозиция(lat, lon) */
export interface GenMainPosition {
  /**
   * latitude - широта
   * @format float
   * @min -90
   * @max 90
   */
  lat: number;
  /**
   * longitude - долгота
   * @format float
   * @min -180
   * @max 180
   */
  lon: number;
}

/** Адресный объект */
export type GenMainAddressObject = Override<
  GenMainAddressObjectLink,
  {
    level: GenMainAddressLevel;
    /** Значение адресного элемента без сокращений */
    name: string;
    /** Значение адресного элемента c сокращениями */
    shortName: string;
    /** Составные части значения адресного элемента */
    values: GenMainAddressObjectValue[] | null;
    /**
     * является ли адрес - результатом ручного ввода адреса
     * @default false
     */
    manual: boolean | null;
  }
>;

/**
 * Адресный уровень элемента в виде номера(уровень в ГАР)
 * 1 -   Субъект РФ
 * 2 -   Административный район
 * 3 -   Муниципальный район
 * 4 -   Сельское/городское поселение
 * 5 -   Город
 * 6 -   Населенный пункт
 * 7 -   Элемент планировочной структуры
 * 8 -   Элемент улично-дорожной сети
 * 9 -   Земельный участок
 * 10 -  Здание
 * 11 -  Помещение
 * 12 -  Помещения в пределах помещения
 * 17 -  Машино-место
 * @format int32
 */
export enum GenMainAddressLevelId {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value17 = 17,
}

export interface GenMainAddressLevel {
  /**
   * Адресный уровень элемента в виде номера(уровень в ГАР)
   * 1 -   Субъект РФ
   * 2 -   Административный район
   * 3 -   Муниципальный район
   * 4 -   Сельское/городское поселение
   * 5 -   Город
   * 6 -   Населенный пункт
   * 7 -   Элемент планировочной структуры
   * 8 -   Элемент улично-дорожной сети
   * 9 -   Земельный участок
   * 10 -  Здание
   * 11 -  Помещение
   * 12 -  Помещения в пределах помещения
   * 17 -  Машино-место
   */
  id: GenMainAddressLevelId;
  /** Название уровня */
  name: string;
}

/** Часть значения адресного объекта. Для домов/зданий это Сооружение, Строение, Корпус */
export interface GenMainAddressObjectValue {
  /** Значение */
  value: string;
  /** Название типа без сокращений */
  typeName: string;
  /** Сокращенное Название типа */
  shortTypeName: string;
}

/** Ссылка на объект адреса */
export interface GenMainAddressObjectLink {
  /** Ид адресного объекта */
  id: GenMainAddressObjectId;
}

/**
 * Ид адресного объекта
 * @format uuid
 */
export type GenMainAddressObjectId = string;

/** Адрес */
export type GenMainAddress = Override<
  UtilRequiredKeys<GenMainAddressObjectLink, 'id'>,
  {
    /** Значение адреса без сокращений */
    name: string | null;
    /** Значение адреса c сокращениями */
    shortName: string | null;
    level: GenMainAddressLevel | null;
    /** Почтовый индекс(может быть только у зданий) */
    postalCode: string | null;
    /** Геопозиция(lat, lon) */
    position: GenMainPosition | null;
    /** Иерархия объектов адресации в составе данного адреса */
    hierarchy: GenMainAddressObject[] | null;
    /**
     * является ли адрес - результатом ручного ввода адреса
     * @default false
     */
    manual: boolean | null;
  }
>;

/** Форма обратной связи */
export interface GenMainFeedBackForm {
  /**
   * имя
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  /**
   * почта для ответа
   * @format email
   * @minLength 1
   * @maxLength 50
   */
  email: string;
  /**
   * текст формы обратной связи
   * @minLength 1
   * @maxLength 1000
   */
  message: string;
  /** структура для ссылки и описания ресурса(файла) */
  attachment: GenMainFileLink | null;
}

/** список кампаний либо их количество */
type GenMainBaseAdCampaignShortResponse = object;

type GenMainBaseAdCampaignShortResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Запрос на создание рекламной кампании */
type GenMainBaseCreateAdCampaignRequest = object;

type GenMainBaseCreateAdCampaignRequestDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Действия с объектом - рекламной кампанией */
type GenMainBaseAdCampaignCommand = object;

type GenMainBaseAdCampaignCommandDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список пользователей либо их количество */
type GenMainBaseUserResponse = object;

type GenMainBaseUserResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Список значащих категорий работников РЖД */
type GenMainBaseRzdEmployeeCategoryResponse = object;

type GenMainBaseRzdEmployeeCategoryResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список соцпакетов либо их количество */
type GenMainBaseRzdSocialPackageResponse = object;

type GenMainBaseRzdSocialPackageResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Команды на массовые действия с соцпакетами со стороны админов */
type GenMainBaseSocialPackagesAdminAction = object;

type GenMainBaseSocialPackagesAdminActionDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Действия с соцпакетом со стороны админов */
type GenMainBaseSocialPackageAdminAction = object;

type GenMainBaseSocialPackageAdminActionDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Действия со своим соцпакетом со стороны работника */
type GenMainBaseSocialPackageEmployeeAction = object;

type GenMainBaseSocialPackageEmployeeActionDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список покупателей либо их количество */
type GenMainBaseCustomerResponse = object;

type GenMainBaseCustomerResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Действия с объектом - клиентом организации */
type GenMainBaseClientOrgAction = object;

type GenMainBaseClientOrgActionDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список организаций либо их количество */
type GenMainBaseClientOrgsResponse = object;

type GenMainBaseClientOrgsResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список партнёров либо их количество */
type GenMainBasePartnerShortResponse = object;

type GenMainBasePartnerShortResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Регистрационные данные партнёра */
interface GenMainBasePartnerRegData {
  /**
   * Тип организации
   * * INDIVIDUAL_ENTREPRENEUR - ИП
   * * LIMITED_LIABILITY_COMPANY - ООО
   * * STOCK_COMPANY - АО
   * * PUBLIC_JOIN_STOCK_COMPANY - ПАО
   * * PK - ПК
   * * UP - УП
   * * FGBNU - ФГБНУ
   * * NONPROFIT_ORGANIZATION - НКО
   * * CLOSED_JOIN_STOCK_COMPANY - ЗАО
   * * JOIN_STOCK_COMPANY - ОАО
   * * NAO - НАО
   * * AUTONOMOUS_NONPROFIT_ORGANIZATION - АНО
   */
  orgType: GenMainOrgType;
}

type GenMainBasePartnerRegDataDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список ТП либо их количество */
type GenMainBaseCustomerTradeOfferShortResponse = object;

type GenMainBaseCustomerTradeOfferShortResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список предложений либо их количество */
type GenMainBaseCustomerOfferShortResponse = object;

type GenMainBaseCustomerOfferShortResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список ТП либо их количество */
type GenMainBaseTradeOfferResponse = object;

type GenMainBaseTradeOfferResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Предложение любого типа. Полное представление */
type GenMainBaseOfferFull = object;

type GenMainBaseOfferFullDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** запрос на создание ТП */
type GenMainBaseTradeOfferCreateRequest = object;

type GenMainBaseTradeOfferCreateRequestDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Допустимые команды к сущности */
type GenMainBaseTradeOfferCommand = object;

type GenMainBaseTradeOfferCommandDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Сокращенное представление предложения для покупателя */
type GenMainBaseCustomerOfferShort = object;

type GenMainBaseCustomerOfferShortDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список КП либо их количество */
type GenMainBaseCustomerCorpOfferShortResponse = object;

type GenMainBaseCustomerCorpOfferShortResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список КП либо их количество */
type GenMainBaseCorpOfferResponse = object;

type GenMainBaseCorpOfferResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Запрос на создание КП */
type GenMainBaseCorpOfferRequest = object;

type GenMainBaseCorpOfferRequestDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Команда на создание товарного предложения OfferLink - создание черновика на основе другого предложения */
type GenMainBaseProductOfferCreateRequest = object;

type GenMainBaseProductOfferCreateRequestDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Команда на обновление товарного предложения */
type GenMainBaseProductOfferUpdateRequest = object;

type GenMainBaseProductOfferUpdateRequestDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Действия с товарным предложением */
type GenMainBaseProductOfferAction = object;

type GenMainBaseProductOfferActionDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Действия с объектом ДиО */
type GenMainBaseBookingOfferAction = object;

type GenMainBaseBookingOfferActionDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Действия с заказом со стороны админа/партнера */
type GenMainBaseOrderAction = object;

type GenMainBaseOrderActionDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Действия с карточкой товара */
type GenMainBaseProductDeskAction = object;

type GenMainBaseProductDeskActionDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список объектов ДиО либо их количество */
type GenMainBaseBookingOfferResponse = object;

type GenMainBaseBookingOfferResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Команды над категорией услуг */
type GenMainBaseServiceCategoryCommand = object;

type GenMainBaseServiceCategoryCommandDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Команды над конкретным типом активности */
type GenMainBaseServicePriceUnitCommand = object;

type GenMainBaseServicePriceUnitCommandDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Команда на создание нового объекта ДиО */
type GenMainBaseBookingOfferCreateRequest = object;

type GenMainBaseBookingOfferCreateRequestDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Команда на обновление объекта ДиО */
type GenMainBaseBookingOfferUpdateRequest = object;

type GenMainBaseBookingOfferUpdateRequestDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Действия с пользователя со своим заказом */
type GenMainBaseOrderCustomerAction = object;

type GenMainBaseOrderCustomerActionDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список товаров либо их количество */
type GenMainBaseProductOfferResponse = object;

type GenMainBaseProductOfferResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список товаров либо их количество */
type GenMainBaseCustomerProductOfferShortResponse = object;

type GenMainBaseCustomerProductOfferShortResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список объектов букинга (Дио) либо их количество */
type GenMainBaseCustomerBookingOfferListResponse = object;

type GenMainBaseCustomerBookingOfferListResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Активация КП */
type GenMainBaseCorpOfferActivation = object;

type GenMainBaseCorpOfferActivationDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Активация ТП */
type GenMainBaseTradeOfferActivation = object;

type GenMainBaseTradeOfferActivationDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** активация предложения, либо представление счетчика */
type GenMainBaseOfferActivationResponse = object;

type GenMainBaseOfferActivationResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Действия с активацией */
type GenMainBaseActivationOfferAction = object;

type GenMainBaseActivationOfferActionDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Заказ */
type GenMainBaseOrderShort = object;

type GenMainBaseOrderShortDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Полные данные по заказу */
type GenMainBaseOrderFull = object;

type GenMainBaseOrderFullDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список заказов либо их количество */
type GenMainBaseOrderFullResponse = object;

type GenMainBaseOrderFullResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Статистика поисковых запросов */
type GenMainBaseSearchQueryStat = object;

type GenMainBaseSearchQueryStatDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** список баннеров либо их количество */
type GenMainBaseBannerResponse = object;

type GenMainBaseBannerResponseDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;

/** Команда на создание нового заказ покупателем */
type GenMainBaseCustomerOrderRequest = object;

type GenMainBaseCustomerOrderRequestDiscriminatorMapping<Key, Type> = {
  discriminator: Key;
} & Type;
