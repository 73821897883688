import { Typography } from '@mui/material';
import { BookingServicesItem } from 'domain/model';
import { PriceRangeFilter } from 'domain/model';
import { useAuthUser } from 'features/auth/provider/useAuthUser';
import CompensationCheckbox from 'features/offer/booking/components/compensationCheckbox';
import ServiceCheckbox from 'features/offer/booking/components/serviceCheckbox';
import useTechConfig from 'hooks/useTechConfig';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { EAppFeature } from 'presentation/types';
import { FC } from 'react';
import { OfferFilterPrice } from '../../../components/filterPrice';
import { UseBookingOfferList } from '../../types';
import { CompensationWrapper, PriceWrapper, ServicesWrapper, Wrapper } from './controls';

type BookingOfferListAsideProps = Pick<
  UseBookingOfferList,
  'onChangePriceRange' | 'onSelectService' | 'onResetFilters' | 'onChangeCompensation'
> & {
  readonly isFilterFetching: boolean;
  readonly allServices?: Nullable<BookingServicesItem[]>;
  readonly availableServices?: Nullable<BookingServicesItem[]>;
  readonly availableRange?: PriceRangeFilter;
  readonly rzdSocialPackage: Nullable<boolean>;
  readonly priceFilter: PriceRangeFilter;
  readonly filterCount: number;
  readonly services: Nullable<string[]>;
};

const BookingOfferListFilter: FC<BookingOfferListAsideProps> = ({
  isFilterFetching,
  allServices,
  availableServices,
  priceFilter,
  filterCount,
  services,
  availableRange,
  onChangePriceRange,
  onChangeCompensation,
  rzdSocialPackage,
  onSelectService,
  onResetFilters,
}) => {
  //todo когда-то надо говно это переделать, чтобы компонент не работал с инфраструктурой
  const { hasFeature } = useTechConfig();
  const { isCorpUser } = useAuthUser();
  const isCspEnabled = hasFeature(EAppFeature.Csp) && isCorpUser;

  const { minPrice: minValue, maxPrice: maxValue } = availableRange ?? {};

  const hasPrice = typeof minValue === 'number' && typeof maxValue === 'number';
  const hasServices = allServices && availableServices;
  const servicesIds = availableServices?.map(({ id }) => id) || [];
  const actualValue = {
    minPrice: priceFilter.minPrice ?? minValue ?? null,
    maxPrice: priceFilter.maxPrice ?? maxValue ?? null,
  };

  return (
    <Wrapper id='filter-container'>
      {isCspEnabled && (
        <CompensationWrapper>
          <CompensationCheckbox
            value={!!rzdSocialPackage}
            onChange={onChangeCompensation}
          />
        </CompensationWrapper>
      )}
      {hasPrice && (
        <>
          <PriceWrapper>
            <Typography
              variant='subtitle1'
              mb={2}
            >
              Цена
            </Typography>
            <OfferFilterPrice
              isDisabled={isFilterFetching}
              min={minValue}
              max={maxValue}
              value={actualValue}
              onChange={onChangePriceRange}
            />
          </PriceWrapper>
        </>
      )}
      {hasServices && (
        <>
          <Typography
            variant='subtitle1'
            mb={2}
          >
            Услуги
          </Typography>
          <ServicesWrapper>
            {allServices?.map(service => {
              const isChecked = !services || !service.id ? false : services.includes(service.id);
              return (
                <MPGrid
                  item
                  key={service.id}
                >
                  {service.id && (
                    <ServiceCheckbox
                      label={service.name}
                      checked={!services ? false : services.includes(service.id)}
                      // TODO заменить на data-item, когда (и если) станет много услуг
                      onChange={() => onSelectService(service.id!)}
                      disabled={!servicesIds.includes(service.id) && !isChecked}
                    />
                  )}
                </MPGrid>
              );
            })}
          </ServicesWrapper>
        </>
      )}
      {(hasPrice || hasServices) && (
        <MPButton
          fullWidth
          disabled={isFilterFetching || !filterCount}
          variant='outlined'
          color='brand'
          size='small'
          onClick={onResetFilters}
        >
          Сбросить фильтры
        </MPButton>
      )}
    </Wrapper>
  );
};

export default BookingOfferListFilter;
