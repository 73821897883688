import { BookingModalSlotItem } from './types';
import moment from 'moment-timezone';
import { EDateFormat } from 'domain/model';

export const sortByDate = ({ startDate: s1 }: BookingModalSlotItem, { startDate: s2 }: BookingModalSlotItem) => {
  const f1 = moment(s1, EDateFormat.Server);
  const f2 = moment(s2, EDateFormat.Server);

  if (f1.isBefore(f2)) {
    return -1;
  } else if (f1.isAfter(f2)) {
    return 1;
  }

  return 0;
};
