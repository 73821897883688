import { AxiosResponse } from 'axios';
import {
  Category,
  CategoryTree,
  EOfferStatus,
  EProductOfferSortType,
  Pageable,
  PriceRangeFilter,
  ProductOffer,
} from 'domain/model';
import { categoriesBuildTree } from 'presentation/utils/category';
import apiDefinition from '../openApi';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import {
  api,
  EProductOfferServicesTag,
  OfferDetailsRequest,
  OfferListRequest,
  OffersCountRequest,
  OffersCountResponse,
} from './index';

export type ProductOfferSearchRequest = {
  readonly search: {
    readonly name: Nullable<string>;
  };
  readonly pageSize: number;
};

export type ProductOfferCategoriesUsedRequest = {
  readonly name?: Nullable<string>;
  readonly city?: Nullable<string>;
  readonly onlyLeafCategories?: Nullable<boolean>;
  readonly partnerId?: Nullable<UUID>;
  readonly collectionId?: Nullable<UUID>;
};

export type ProductOfferCategoriesUsedTreeRequest = {
  readonly city: string;
};

export type ProductOfferVariationsRequest = {
  readonly productDeskId: UUID;
};

const defaultProductOfferListStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.OutOfStock];

export type ProductOfferListRequest = OfferListRequest & Partial<PriceRangeFilter>;

export const productApi = api.injectEndpoints({
  endpoints: builder => ({
    getProductOfferCategories: builder.query<Category[], void>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: () => {
        return apiDefinition.product.categories({});
      },
    }),
    getProductOfferCategory: builder.query<Category, UUID>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: id => {
        return apiDefinition.product.category({ id });
      },
    }),
    getProductOfferCategoriesUsed: builder.query<Category[], ProductOfferCategoriesUsedRequest>({
      transformResponse: (response: AxiosResponse) => {
        return response.data;
      },
      query: ({ name, city, onlyLeafCategories, ...other }) => {
        return apiDefinition.user.product.categoriesUsed({
          query: name,
          city,
          statuses: defaultProductOfferListStatuses,
          onlyLeafCategories,
          ...other,
        });
      },
    }),
    getProductOfferCategoriesUsedTree: builder.query<CategoryTree, ProductOfferCategoriesUsedTreeRequest>({
      transformResponse: (response: AxiosResponse) => categoriesBuildTree(response.data),
      query: ({ city }) => {
        return apiDefinition.user.product.categoriesUsed({
          city,
          statuses: defaultProductOfferListStatuses,
          onlyLeafCategories: false,
        });
      },
    }),
    getProductOfferSearch: builder.query<Pageable<ProductOffer>, ProductOfferSearchRequest>({
      keepUnusedDataFor: 0,
      transformResponse: (response: AxiosResponse): Pageable<ProductOffer> => {
        const pageable = getPageableFromResponseHeaders(response);

        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ search, pageSize }) => {
        const { name } = search;

        return apiDefinition.user.product.all({
          page: 1,
          pageSize,
          query: name,
          statuses: defaultProductOfferListStatuses,
          sort: [EProductOfferSortType.ByNovelty],
        });
      },
    }),
    getProductOfferList: builder.query<Pageable<ProductOffer>, ProductOfferListRequest>({
      transformResponse: (response: AxiosResponse): Pageable<ProductOffer> => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ search, page, pageSize, sort, ...filters }) => {
        const { name, categories, partnerId } = search;
        return apiDefinition.user.product.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          query: name,
          statuses: defaultProductOfferListStatuses,
          partnerId,
          categories,
          ...filters,
        });
      },
      providesTags: [EProductOfferServicesTag.List],
    }),
    getProductOffersCount: builder.query<number, OffersCountRequest>({
      transformResponse: (response: AxiosResponse<OffersCountResponse[]>): number => {
        return response.data[0]?.count ?? 0;
      },
      query: ({ search }) => {
        const { name, categories } = search;
        return apiDefinition.user.product.count({
          query: name,
          statuses: defaultProductOfferListStatuses,
          categories,
        });
      },
    }),
    getProductOfferDetails: builder.query<ProductOffer, OfferDetailsRequest>({
      query: ({ id }) => apiDefinition.user.product.one({ id }),
      transformResponse: (response: AxiosResponse) => response.data,
      providesTags: (result, error, args) => [
        { type: EProductOfferServicesTag.Details, id: args.id },
        EProductOfferServicesTag.Details,
      ],
    }),
    getProductOfferVariations: builder.query<ProductOffer[], ProductOfferVariationsRequest>({
      transformResponse: (response: AxiosResponse): ProductOffer[] => {
        return response.data;
      },
      query: ({ productDeskId }) => {
        return apiDefinition.user.product.variations({
          productDeskId,
        });
      },
    }),
  }),
});

export const {
  useGetProductOfferListQuery,
  useGetProductOffersCountQuery,
  useGetProductOfferCategoriesQuery,
  useGetProductOfferCategoryQuery,
  useGetProductOfferCategoriesUsedQuery,
  useGetProductOfferDetailsQuery,
  useGetProductOfferCategoriesUsedTreeQuery,
  useGetProductOfferSearchQuery,
  useGetProductOfferVariationsQuery,
} = productApi;
