import { Typography, useMediaQuery } from '@mui/material';
import { Address } from 'domain/model/address';
import AddressCitySingleSelector from 'presentation/components/common/address/singleSelector/city';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useCallback, useState } from 'react';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { AddressHelper } from 'utils/address';

type CitySelectDialogProps = {
  readonly visible: boolean;
  readonly onChangeCity: (city: Address) => void;
  readonly onClose: () => void;
};

const CitySelectDialog = (props: CitySelectDialogProps) => {
  const { visible, onChangeCity, onClose } = props;

  const [localCity, setLocalCity] = useState<Nullable<Address>>(null);
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const onChangeLocalCity = useCallback(
    (address: Nullable<Address>) => {
      if (!visible) return;

      if (!address) {
        setLocalCity(null);
      } else {
        setLocalCity(address);

        if (isSmDown) {
          onChangeCity(address);
          onClose();
        }
      }
    },
    [visible, setLocalCity, isSmDown, onChangeCity, onClose]
  );

  const onApply = useCallback(() => {
    onChangeCity(localCity ?? ({} as Address));
    onClose();
  }, [localCity, onChangeCity, onClose]);

  const getOptionLabel = (address: Address) => new AddressHelper(address).getLocalitySimpleName();

  return (
    <MPConfirmDialog
      title='Выбор города'
      open={visible}
      maxWidth='xs'
      buttons={
        <ConfirmGroupButtons>
          <MPButton
            fullWidth
            disabled={!localCity}
            color='brand'
            size='large'
            onClick={onApply}
          >
            Продолжить
          </MPButton>
          <MPButton
            color='brand'
            variant='text'
            fullWidth
            size='large'
            onClick={onClose}
          >
            Отмена
          </MPButton>
        </ConfirmGroupButtons>
      }
      onClose={onClose}
    >
      <MPGrid
        container
        spacing={2}
      >
        <MPGrid
          item
          zero={12}
        >
          <Typography variant='body2'>Укажите, где вы находитесь для правильного отображения предложений</Typography>
        </MPGrid>
        <MPGrid
          item
          zero={12}
        >
          <AddressCitySingleSelector
            value={localCity}
            label='Город'
            getOptionLabel={getOptionLabel}
            inputProps={{
              autoFocus: true,
            }}
            onChange={onChangeLocalCity}
          />
        </MPGrid>
      </MPGrid>
    </MPConfirmDialog>
  );
};

export default CitySelectDialog;
