/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GenMainActivationId,
  GenMainBanner,
  GenMainBannerPlaceCode,
  GenMainBasket,
  GenMainBasketItem,
  GenMainBasketItemId,
  GenMainBasketItemRequest,
  GenMainBonusTransaction,
  GenMainBookingOffer,
  GenMainBookingOfferId,
  GenMainBookingOfferShort,
  GenMainBookingPriceRange,
  GenMainBrandId,
  GenMainCategoryId,
  GenMainCounter,
  GenMainCustomerBookingOfferListResponse,
  GenMainCustomerCorpOfferShort,
  GenMainCustomerCorpOfferShortResponse,
  GenMainCustomerOfferShort,
  GenMainCustomerOfferShortResponse,
  GenMainCustomerOrderRequest,
  GenMainCustomerProductOffer,
  GenMainCustomerProductOfferShort,
  GenMainCustomerProductOfferShortResponse,
  GenMainCustomerTradeOffer,
  GenMainCustomerTradeOfferShort,
  GenMainCustomerTradeOfferShortResponse,
  GenMainOfferActivation,
  GenMainOfferActivationResponse,
  GenMainOfferActivationStatus,
  GenMainOfferCategory,
  GenMainOfferId,
  GenMainOfferStatus,
  GenMainOfferTagType,
  GenMainOfferType,
  GenMainOfferUnavailabilityReason,
  GenMainOrderCustomerAction,
  GenMainOrderFull,
  GenMainOrderId,
  GenMainOrderShort,
  GenMainOrderStatus,
  GenMainPartnerDesk,
  GenMainPartnerId,
  GenMainPartnerNameMapKey,
  GenMainPaymentType,
  GenMainPredicate,
  GenMainProductAttributeFilterValue,
  GenMainProductCategory,
  GenMainProductDeskId,
  GenMainPromotionType,
  GenMainResultType,
  GenMainRzdSocialPackage,
  GenMainRzdSocialPackageResponse,
  GenMainServiceCategoryShort,
  GenMainShortOfferView,
  GenMainSocialPackageEmployeeAction,
  GenMainSocialPackageId,
  GenMainSocialPackageStatus,
  GenMainString,
  GenMainTransactionId,
  GenMainUserId,
} from './data-contracts';
import { ContentType, createRequest } from './http-client';

export const CustomersApi = {
  /**
   * No description
   * * @tags Customers
   * @name LikeOffer
   * @summary Добавить оффер в избранное
   * @request POST:/customers/current/favorites/offers
   * @secure
   */
  likeOffer: (
    baseURL: string,
    data: {
      /** ID предложения */
      offerId: GenMainOfferId;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<
      void,
      {
        /** ID предложения */
        offerId: GenMainOfferId;
      }
    >({
      baseURL,
      path: `/customers/current/favorites/offers`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name DislikeOffer
   * @summary Удалить оффер из избранного
   * @request DELETE:/customers/current/favorites/offers/{offerId}
   * @secure
   */
  dislikeOffer: (baseURL: string, offerId: GenMainOfferId, signal?: AbortSignal) =>
    createRequest<void, void>({
      baseURL,
      path: `/customers/current/favorites/offers/${offerId}`,
      method: 'DELETE',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name SubscribeToOffer
   * @summary Подписаться на появление предложения в доступе
   * @request POST:/customers/current/subscriptions/offers
   * @secure
   */
  subscribeToOffer: (
    baseURL: string,
    data: {
      /** ID предложения */
      offerId: GenMainOfferId;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<
      void,
      {
        /** ID предложения */
        offerId: GenMainOfferId;
      }
    >({
      baseURL,
      path: `/customers/current/subscriptions/offers`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name UnsubscribeFromOffer
   * @summary Удалить подписку на предложение
   * @request DELETE:/customers/current/subscriptions/offers/{offerId}
   * @secure
   */
  unsubscribeFromOffer: (baseURL: string, offerId: GenMainOfferId, signal?: AbortSignal) =>
    createRequest<void, void>({
      baseURL,
      path: `/customers/current/subscriptions/offers/${offerId}`,
      method: 'DELETE',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCustomerTradeOffers
   * @summary Получение списка торговых предложений(ТП) для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/trade-offers
   * @secure
   */
  listCustomerTradeOffers: (
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: GenMainResultType | null;
      /** Фильтр по категориям */
      category?: GenMainCategoryId[] | null;
      /** Фильтр по статусам предложений */
      status?: GenMainOfferStatus[] | null;
      /**
       * Дата начала срока действия
       * @format date
       */
      startDate?: string | null;
      /**
       * Дата окончания срока действия
       * @format date
       */
      endDate?: string | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** Тип Promotion */
      promotionType?: GenMainPromotionType[] | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
      /** Фильтр по избранному */
      favorite?: boolean | null;
      /** Фильтр по способу оплаты */
      paymentType?: GenMainPaymentType[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainCustomerTradeOfferShortResponse[], void>({
      baseURL,
      path: `/customers/current/trade-offers`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name ListCustomerTradeOffers
   * @summary Получение списка торговых предложений(ТП) для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/trade-offers
   * @secure
   */
  listCustomerTradeOffersTyped: <ResultType extends GenMainResultType>(
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: ResultType | null;
      /** Фильтр по категориям */
      category?: GenMainCategoryId[] | null;
      /** Фильтр по статусам предложений */
      status?: GenMainOfferStatus[] | null;
      /**
       * Дата начала срока действия
       * @format date
       */
      startDate?: string | null;
      /**
       * Дата окончания срока действия
       * @format date
       */
      endDate?: string | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** Тип Promotion */
      promotionType?: GenMainPromotionType[] | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
      /** Фильтр по избранному */
      favorite?: boolean | null;
      /** Фильтр по способу оплаты */
      paymentType?: GenMainPaymentType[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<
      ResultType extends GenMainResultType.Count ? GenMainCounter[] : GenMainCustomerTradeOfferShort[],
      void
    >({
      baseURL,
      path: `/customers/current/trade-offers`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name GetCustomerTradeOfferById
   * @summary Получение ТП по id
   * @request GET:/customers/current/trade-offers/{tradeOfferId}
   * @secure
   */
  getCustomerTradeOfferById: (baseURL: string, tradeOfferId: GenMainOfferId, signal?: AbortSignal) =>
    createRequest<GenMainCustomerTradeOffer, void>({
      baseURL,
      path: `/customers/current/trade-offers/${tradeOfferId}`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCustomerTradeOfferCategories
   * @summary Получение списка категорий ТП для покупателя
   * @request GET:/customers/current/trade-offers/categories
   * @secure
   */
  listCustomerTradeOfferCategories: (
    baseURL: string,
    query: {
      /** Фильтр по статусам предложений */
      status?: GenMainOfferStatus[] | null;
      /**
       * Дата начала срока действия
       * @format date
       */
      startDate?: string | null;
      /**
       * Дата окончания срока действия
       * @format date
       */
      endDate?: string | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
      /**
       * true - только листовые категории, false - структура категорий в виде списка с родительскими категориями
       * @default true
       */
      onlyLeafCategories?: boolean | null;
      /** Тип Promotion */
      promotionType?: GenMainPromotionType[] | null;
      /** Фильтр по способу оплаты */
      paymentType?: GenMainPaymentType[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOfferCategory[], void>({
      baseURL,
      path: `/customers/current/trade-offers/categories`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name GetCustomerOfferActivationAvailability
   * @summary Проверка доступности активации для предложения
   * @request GET:/customers/current/offers/{offerId}/activation-availability
   * @secure
   */
  getCustomerOfferActivationAvailability: (
    baseURL: string,
    {
      offerId,
      ...query
    }: {
      /** фильтр по конкретным причинам */
      reasons?: GenMainOfferUnavailabilityReason[] | null;
      /** id предложения */
      offerId: GenMainOfferId;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOfferUnavailabilityReason[], void>({
      baseURL,
      path: `/customers/current/offers/${offerId}/activation-availability`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCurrentCustomerFavoriteOffers
   * @summary Получение списка избранных предложений текущего пользователя
   * @request GET:/customers/current/offers/favorite
   * @secure
   */
  listCurrentCustomerFavoriteOffers: (
    baseURL: string,
    query: {
      /** Фильтр по статусам предложений */
      status?: GenMainOfferStatus[] | null;
      /** Тип предложения */
      offerType?: GenMainOfferType[] | null;
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: GenMainResultType | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainCustomerOfferShortResponse[], void>({
      baseURL,
      path: `/customers/current/offers/favorite`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name ListCurrentCustomerFavoriteOffers
   * @summary Получение списка избранных предложений текущего пользователя
   * @request GET:/customers/current/offers/favorite
   * @secure
   */
  listCurrentCustomerFavoriteOffersTyped: <ResultType extends GenMainResultType>(
    baseURL: string,
    query: {
      /** Фильтр по статусам предложений */
      status?: GenMainOfferStatus[] | null;
      /** Тип предложения */
      offerType?: GenMainOfferType[] | null;
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: ResultType | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<ResultType extends GenMainResultType.Count ? GenMainCounter[] : GenMainCustomerOfferShort[], void>({
      baseURL,
      path: `/customers/current/offers/favorite`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCurrentCustomerSubscribedOffers
   * @summary Получение списка предложений, на которые подписался текущий пользователь
   * @request GET:/customers/current/offers/subscribed
   * @secure
   */
  listCurrentCustomerSubscribedOffers: (
    baseURL: string,
    query: {
      /** Фильтр по статусам предложений */
      status?: GenMainOfferStatus[] | null;
      /** Тип предложения */
      offerType?: GenMainOfferType[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainShortOfferView[], void>({
      baseURL,
      path: `/customers/current/offers/subscribed`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCustomerOffers
   * @summary Получение списка предложений с фильтром
   * @request GET:/customers/current/offers
   * @secure
   */
  listCustomerOffers: (
    baseURL: string,
    query: {
      /** поисковый запрос - строка */
      q?: string | null;
      /** Тип предложения */
      offerType?: GenMainOfferType[] | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** Тег предложения */
      offerTag?: GenMainOfferTagType[] | null;
      /** Фильтр по способу оплаты */
      paymentType?: GenMainPaymentType[] | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainCustomerOfferShort[], void>({
      baseURL,
      path: `/customers/current/offers`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCustomerCorpOffers
   * @summary Получение списка КП для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/corp-offers
   * @secure
   */
  listCustomerCorpOffers: (
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: GenMainResultType | null;
      /** Фильтр по категориям */
      category?: GenMainCategoryId[] | null;
      /** Фильтр по статусам предложений */
      status?: GenMainOfferStatus[] | null;
      /**
       * Дата начала срока действия
       * @format date
       */
      startDate?: string | null;
      /**
       * Дата окончания срока действия
       * @format date
       */
      endDate?: string | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
      /** Фильтр по избранному */
      favorite?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainCustomerCorpOfferShortResponse[], void>({
      baseURL,
      path: `/customers/current/corp-offers`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name ListCustomerCorpOffers
   * @summary Получение списка КП для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/corp-offers
   * @secure
   */
  listCustomerCorpOffersTyped: <ResultType extends GenMainResultType>(
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: ResultType | null;
      /** Фильтр по категориям */
      category?: GenMainCategoryId[] | null;
      /** Фильтр по статусам предложений */
      status?: GenMainOfferStatus[] | null;
      /**
       * Дата начала срока действия
       * @format date
       */
      startDate?: string | null;
      /**
       * Дата окончания срока действия
       * @format date
       */
      endDate?: string | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
      /** Фильтр по избранному */
      favorite?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<
      ResultType extends GenMainResultType.Count ? GenMainCounter[] : GenMainCustomerCorpOfferShort[],
      void
    >({
      baseURL,
      path: `/customers/current/corp-offers`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name GetCustomerCorpOfferById
   * @summary Получение КП по id
   * @request GET:/customers/current/corp-offers/{corpOfferId}
   * @secure
   */
  getCustomerCorpOfferById: (baseURL: string, corpOfferId: GenMainOfferId, signal?: AbortSignal) =>
    createRequest<GenMainCustomerCorpOfferShort, void>({
      baseURL,
      path: `/customers/current/corp-offers/${corpOfferId}`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCustomerCorpOfferCategories
   * @summary Получение списка категорий КП по фильтрам для покупателя
   * @request GET:/customers/current/corp-offers/categories
   * @secure
   */
  listCustomerCorpOfferCategories: (
    baseURL: string,
    query: {
      /** Фильтр по статусам предложений */
      status?: GenMainOfferStatus[] | null;
      /**
       * Дата начала срока действия
       * @format date
       */
      startDate?: string | null;
      /**
       * Дата окончания срока действия
       * @format date
       */
      endDate?: string | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
      /**
       * true - только листовые категории, false - структура категорий в виде списка с родительскими категориями
       * @default true
       */
      onlyLeafCategories?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOfferCategory[], void>({
      baseURL,
      path: `/customers/current/corp-offers/categories`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCustomerProductOffers
   * @summary Получение списка товаров для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/product-offers
   * @secure
   */
  listCustomerProductOffers: (
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: GenMainResultType | null;
      /** Фильтр по категориям */
      category?: GenMainCategoryId[] | null;
      /** Фильтр по статусам предложений */
      status?: GenMainOfferStatus[] | null;
      /** Признак наличия */
      available?: boolean | null;
      /** Фильтр по значению характеристик товара */
      attributeValues?: GenMainProductAttributeFilterValue[] | null;
      /**
       * Фильтр по минимальной цене
       * @format int64
       * @min 0
       */
      minPrice?: number | null;
      /**
       * Фильтр по максимальной цене
       * @format int64
       * @min 0
       */
      maxPrice?: number | null;
      /** Фильтр по брендам */
      brand?: GenMainBrandId[] | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** id карточки товара */
      productDeskId?: GenMainProductDeskId | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** Фильтр по избранному */
      favorite?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainCustomerProductOfferShortResponse[], void>({
      baseURL,
      path: `/customers/current/product-offers`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name ListCustomerProductOffers
   * @summary Получение списка товаров для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/product-offers
   * @secure
   */
  listCustomerProductOffersTyped: <ResultType extends GenMainResultType>(
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: ResultType | null;
      /** Фильтр по категориям */
      category?: GenMainCategoryId[] | null;
      /** Фильтр по статусам предложений */
      status?: GenMainOfferStatus[] | null;
      /** Признак наличия */
      available?: boolean | null;
      /** Фильтр по значению характеристик товара */
      attributeValues?: GenMainProductAttributeFilterValue[] | null;
      /**
       * Фильтр по минимальной цене
       * @format int64
       * @min 0
       */
      minPrice?: number | null;
      /**
       * Фильтр по максимальной цене
       * @format int64
       * @min 0
       */
      maxPrice?: number | null;
      /** Фильтр по брендам */
      brand?: GenMainBrandId[] | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** id карточки товара */
      productDeskId?: GenMainProductDeskId | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** Фильтр по избранному */
      favorite?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<
      ResultType extends GenMainResultType.Count ? GenMainCounter[] : GenMainCustomerProductOfferShort[],
      void
    >({
      baseURL,
      path: `/customers/current/product-offers`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name GetCustomerProductOfferById
   * @summary Получение товара по id
   * @request GET:/customers/current/product-offers/{productOfferId}
   * @secure
   */
  getCustomerProductOfferById: (baseURL: string, productOfferId: GenMainOfferId, signal?: AbortSignal) =>
    createRequest<GenMainCustomerProductOffer, void>({
      baseURL,
      path: `/customers/current/product-offers/${productOfferId}`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCustomerProductOfferCategories
   * @summary Получение списка категорий товаров по фильтрам для покупателя
   * @request GET:/customers/current/product-offers/categories
   * @secure
   */
  listCustomerProductOfferCategories: (
    baseURL: string,
    query: {
      /** Фильтр по статусам предложений */
      status?: GenMainOfferStatus[] | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /**
       * true - только листовые категории, false - структура категорий в виде списка с родительскими категориями
       * @default true
       */
      onlyLeafCategories?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainProductCategory[], void>({
      baseURL,
      path: `/customers/current/product-offers/categories`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListBannersForCurrentUser
   * @summary Список баннеров для текущего пользователя
   * @request GET:/customers/current/banners
   * @secure
   */
  listBannersForCurrentUser: (
    baseURL: string,
    query: {
      /** Коды баннера */
      bannerPlaceCodes?: GenMainBannerPlaceCode[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainBanner[], void>({
      baseURL,
      path: `/customers/current/banners`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListBonusTransactions
   * @summary Список транзакций бонусного счета покупателя
   * @request GET:/customers/{userId}/bonuses/transactions
   * @secure
   */
  listBonusTransactions: (
    baseURL: string,
    {
      userId,
      ...query
    }: {
      /** фильтр dsl */
      filter?: GenMainPredicate[] | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** id пользователя */
      userId: GenMainUserId;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainBonusTransaction[], void>({
      baseURL,
      path: `/customers/${userId}/bonuses/transactions`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name GetBonusTransactionById
   * @summary Транзакции бонусного счета покупателя
   * @request GET:/customers/{userId}/bonuses/transactions/{transactionId}
   * @secure
   */
  getBonusTransactionById: (
    baseURL: string,
    transactionId: GenMainTransactionId,
    userId: GenMainUserId,
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainBonusTransaction, void>({
      baseURL,
      path: `/customers/${userId}/bonuses/transactions/${transactionId}`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ActivatePersonalPromotion
   * @summary Активировать предложение
   * @request POST:/customers/current/activations
   * @secure
   */
  activatePersonalPromotion: (
    baseURL: string,
    data: {
      /** ID предложения */
      offerId: GenMainOfferId;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<
      GenMainOfferActivation,
      {
        /** ID предложения */
        offerId: GenMainOfferId;
      }
    >({
      baseURL,
      path: `/customers/current/activations`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name GetPersonalPromotionActivations
   * @summary Получить список всех своих активаций
   * @request GET:/customers/current/activations
   * @secure
   */
  getPersonalPromotionActivations: (
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: GenMainResultType | null;
      /** список id предложений */
      offerIds?: GenMainOfferId[] | null;
      /** Фильтр по статусам предложений */
      status?: GenMainOfferActivationStatus[] | null;
      /** Тип предложения */
      offerType?: GenMainOfferType[] | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOfferActivationResponse[], void>({
      baseURL,
      path: `/customers/current/activations`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name GetPersonalPromotionActivations
   * @summary Получить список всех своих активаций
   * @request GET:/customers/current/activations
   * @secure
   */
  getPersonalPromotionActivationsTyped: <ResultType extends GenMainResultType>(
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: ResultType | null;
      /** список id предложений */
      offerIds?: GenMainOfferId[] | null;
      /** Фильтр по статусам предложений */
      status?: GenMainOfferActivationStatus[] | null;
      /** Тип предложения */
      offerType?: GenMainOfferType[] | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<ResultType extends GenMainResultType.Count ? GenMainCounter[] : GenMainOfferActivation[], void>({
      baseURL,
      path: `/customers/current/activations`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name GetPersonalPromotionActivationById
   * @summary Получение заявки на активацию привилегии
   * @request GET:/customers/current/activations/{activationId}
   * @secure
   */
  getPersonalPromotionActivationById: (baseURL: string, activationId: GenMainActivationId, signal?: AbortSignal) =>
    createRequest<GenMainOfferActivation, void>({
      baseURL,
      path: `/customers/current/activations/${activationId}`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * @description Если такой ваучер выдан пользователю Разрешён только самому пользователю и
   * * @tags Customers
   * @name DownloadOfferCert
   * @summary Файл с правом на привилегию(сертификат/ваучер)
   * @request GET:/customers/current/activations/{activationId}/cert
   * @secure
   */
  downloadOfferCert: (baseURL: string, activationId: GenMainActivationId, signal?: AbortSignal) =>
    createRequest<File, void>({
      baseURL,
      path: `/customers/current/activations/${activationId}/cert`,
      method: 'GET',
      signal,
    }),

  /**
   * @description Создать заказ в Витрине
   * * @tags Customers
   * @name CreateCustomerOrders
   * @summary Сделать заказ
   * @request POST:/customers/current/orders
   * @secure
   */
  createCustomerOrders: (baseURL: string, data: GenMainCustomerOrderRequest, signal?: AbortSignal) =>
    createRequest<GenMainOrderFull[], GenMainCustomerOrderRequest>({
      baseURL,
      path: `/customers/current/orders`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCustomerOrders
   * @summary Список заказов покупателя
   * @request GET:/customers/current/orders
   * @secure
   */
  listCustomerOrders: (
    baseURL: string,
    query: {
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** статус заказа */
      status?: GenMainOrderStatus[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOrderShort[], void>({
      baseURL,
      path: `/customers/current/orders`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name GetCustomerOrderById
   * @summary Получение заказа покупателя
   * @request GET:/customers/current/orders/{orderId}
   * @secure
   */
  getCustomerOrderById: (baseURL: string, orderId: GenMainOrderId, signal?: AbortSignal) =>
    createRequest<GenMainOrderFull, void>({
      baseURL,
      path: `/customers/current/orders/${orderId}`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name PostCustomerCommandForOrder
   * @summary Допустимые команды к сущности
   * @request POST:/customers/current/orders/{orderId}
   * @secure
   */
  postCustomerCommandForOrder: (
    baseURL: string,
    orderId: GenMainOrderId,
    data: GenMainOrderCustomerAction,
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOrderFull, GenMainOrderCustomerAction>({
      baseURL,
      path: `/customers/current/orders/${orderId}`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name GetBasket
   * @summary Получение всей корзины
   * @request GET:/customers/current/basket
   * @secure
   */
  getBasket: (baseURL: string, signal?: AbortSignal) =>
    createRequest<GenMainBasket, void>({
      baseURL,
      path: `/customers/current/basket`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * @description Добавление товарного предложения в корзину
   * * @tags Customers
   * @name AddOfferToBasket
   * @summary Добавление товарного предложения в корзину
   * @request POST:/customers/current/basket/items
   * @secure
   */
  addOfferToBasket: (baseURL: string, data: GenMainBasketItemRequest, signal?: AbortSignal) =>
    createRequest<GenMainBasketItem, GenMainBasketItemRequest>({
      baseURL,
      path: `/customers/current/basket/items`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name GetBasketItemById
   * @summary Получение элемента корзины
   * @request GET:/customers/current/basket/items/{basketItem}
   * @secure
   */
  getBasketItemById: (baseURL: string, basketItem: GenMainBasketItemId, signal?: AbortSignal) =>
    createRequest<GenMainBasketItem, void>({
      baseURL,
      path: `/customers/current/basket/items/${basketItem}`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * @description удаление элемента корзины
   * * @tags Customers
   * @name DeleteBasketItem
   * @summary удаление элемента корзины
   * @request DELETE:/customers/current/basket/items/{basketItem}
   * @secure
   */
  deleteBasketItem: (baseURL: string, basketItem: GenMainBasketItemId, signal?: AbortSignal) =>
    createRequest<void, void>({
      baseURL,
      path: `/customers/current/basket/items/${basketItem}`,
      method: 'DELETE',
      signal,
    }),

  /**
   * @description изменение элемента корзины
   * * @tags Customers
   * @name UpdateBasketItem
   * @summary изменение элемента корзины
   * @request PUT:/customers/current/basket/items/{basketItem}
   * @secure
   */
  updateBasketItem: (
    baseURL: string,
    basketItem: GenMainBasketItemId,
    data: GenMainBasketItemRequest,
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainBasketItem, GenMainBasketItemRequest>({
      baseURL,
      path: `/customers/current/basket/items/${basketItem}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListPartnerFirstSymbols
   * @summary Получение списка первых символов названия партнеров из выборки партнеров, доступных на Витрине
   * @request GET:/customers/current/partner-desks/name-map
   * @secure
   */
  listPartnerFirstSymbols: (baseURL: string, signal?: AbortSignal) =>
    createRequest<GenMainPartnerNameMapKey[], void>({
      baseURL,
      path: `/customers/current/partner-desks/name-map`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * @description Карточки выдаются с предустановленными фильтрами: - партнер активен - карточка активна (заполнены все обязательные поля)
   * * @tags Customers
   * @name ListCustomerPartnerDesks
   * @summary Список карточек партнеров.
   * @request GET:/customers/current/partner-desks
   * @secure
   */
  listCustomerPartnerDesks: (
    baseURL: string,
    query: {
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** ИД партнеров */
      partnerId?: GenMainPartnerId[] | null;
      /** Часть названия партнера */
      partnerName?: GenMainString[] | null;
      /** поисковый запрос - строка */
      q?: string | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainPartnerDesk[], void>({
      baseURL,
      path: `/customers/current/partner-desks`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * @description Сортировка: * по популярности - rating(по умолчанию) * сначала дешевле - minPrice (ASC|DESC) * сначала новые   - new (ASC|DESC)
   * * @tags Customers
   * @name ListCustomerBookingOffers
   * @summary Получение списка объектов букинга для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/booking-offers
   * @secure
   */
  listCustomerBookingOffers: (
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: GenMainResultType | null;
      /** Фильтр по категориям услуг предложения */
      serviceCategory?: GenMainCategoryId[] | null;
      /** Фильтр по категориям объектов ДиО */
      bookingOfferCategory?: GenMainCategoryId[] | null;
      /**
       * Фильтр по минимальной цене
       * @format int64
       * @min 0
       */
      minPrice?: number | null;
      /**
       * Фильтр по максимальной цене
       * @format int64
       * @min 0
       */
      maxPrice?: number | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** Фильтр по избранному */
      favorite?: boolean | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** Фильтр по поддержке ресурсами соцпакета работника РЖД */
      isRzdSocialPackage?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainCustomerBookingOfferListResponse[], void>({
      baseURL,
      path: `/customers/current/booking-offers`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * @description Сортировка: * по популярности - rating(по умолчанию) * сначала дешевле - minPrice (ASC|DESC) * сначала новые   - new (ASC|DESC)
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name ListCustomerBookingOffers
   * @summary Получение списка объектов букинга для текущего покупателя c фильтром постранично
   * @request GET:/customers/current/booking-offers
   * @secure
   */
  listCustomerBookingOffersTyped: <ResultType extends GenMainResultType>(
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: ResultType | null;
      /** Фильтр по категориям услуг предложения */
      serviceCategory?: GenMainCategoryId[] | null;
      /** Фильтр по категориям объектов ДиО */
      bookingOfferCategory?: GenMainCategoryId[] | null;
      /**
       * Фильтр по минимальной цене
       * @format int64
       * @min 0
       */
      minPrice?: number | null;
      /**
       * Фильтр по максимальной цене
       * @format int64
       * @min 0
       */
      maxPrice?: number | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /**
       * необходимая страница
       * @format int32
       * @min 0
       */
      page: number;
      /**
       * Количество элементов на страницу
       * если указать больше maximum, то будет size=maximum
       * независимо от size в запросе
       * @format int32
       * @min 1
       * @max 1000
       * @default 100
       */
      size: number;
      /** сортировка вида firstName,asc */
      sort?: string[] | null;
      /** Фильтр по избранному */
      favorite?: boolean | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** Фильтр по поддержке ресурсами соцпакета работника РЖД */
      isRzdSocialPackage?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<ResultType extends GenMainResultType.Count ? GenMainCounter[] : GenMainBookingOfferShort[], void>({
      baseURL,
      path: `/customers/current/booking-offers`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name GetCustomerBookingOfferById
   * @summary Получение объекта букинга по id
   * @request GET:/customers/current/booking-offers/{bookingOfferId}
   * @secure
   */
  getCustomerBookingOfferById: (baseURL: string, bookingOfferId: GenMainBookingOfferId, signal?: AbortSignal) =>
    createRequest<GenMainBookingOffer, void>({
      baseURL,
      path: `/customers/current/booking-offers/${bookingOfferId}`,
      method: 'GET',
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCustomerBookingServiceCategories
   * @summary Получение списка категорий услуг букинга для текущего покупателя
   * @request GET:/customers/current/booking-services/categories
   * @secure
   */
  listCustomerBookingServiceCategories: (
    baseURL: string,
    query: {
      /**
       * Фильтр по минимальной цене
       * @format int64
       * @min 0
       */
      minPrice?: number | null;
      /**
       * Фильтр по максимальной цене
       * @format int64
       * @min 0
       */
      maxPrice?: number | null;
      /** Фильтр по категориям объектов ДиО */
      bookingOfferCategory?: GenMainCategoryId[] | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /** Фильтр по избранному */
      favorite?: boolean | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** Фильтр по поддержке ресурсами соцпакета работника РЖД */
      isRzdSocialPackage?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainServiceCategoryShort[], void>({
      baseURL,
      path: `/customers/current/booking-services/categories`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCustomerBookingOffersCategories
   * @summary Получение списка категорий предложений букинга для текущего покупателя
   * @request GET:/customers/current/booking-offers/categories
   * @secure
   */
  listCustomerBookingOffersCategories: (
    baseURL: string,
    query: {
      /** поисковый запрос - строка */
      q?: string | null;
      /** Фильтр по партнеру */
      partnerId?: GenMainPartnerId | null;
      /** Фильтр по поддержке ресурсами соцпакета работника РЖД */
      isRzdSocialPackage?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainOfferCategory[], void>({
      baseURL,
      path: `/customers/current/booking-offers/categories`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCustomerBookingPriceRange
   * @summary Получение минимальной и максимальной цены услуг в предложениях букинга
   * @request GET:/customers/current/booking-price-range
   * @secure
   */
  listCustomerBookingPriceRange: (
    baseURL: string,
    query: {
      /** Фильтр по категориям услуг предложения */
      serviceCategory?: GenMainCategoryId[] | null;
      /** Фильтр по категориям объектов ДиО */
      bookingOfferCategory?: GenMainCategoryId[] | null;
      /** поисковый запрос - строка */
      q?: string | null;
      /** Фильтр по избранному */
      favorite?: boolean | null;
      /** Фильтр по поддержке ресурсами соцпакета работника РЖД */
      isRzdSocialPackage?: boolean | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainBookingPriceRange, void>({
      baseURL,
      path: `/customers/current/booking-price-range`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name ListCustomerSocialPackages
   * @summary Получение списка соц пакетов текущего покупателя
   * @request GET:/customers/current/social-packages
   * @secure
   */
  listCustomerSocialPackages: (
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: GenMainResultType | null;
      /** Фильтр по статусам соцпакета */
      status?: GenMainSocialPackageStatus[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainRzdSocialPackageResponse[], void>({
      baseURL,
      path: `/customers/current/social-packages`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * @description добавлен динамически для типизации ответа по дискриминатору GenMainResultType
   * * @tags Customers
   * @name ListCustomerSocialPackages
   * @summary Получение списка соц пакетов текущего покупателя
   * @request GET:/customers/current/social-packages
   * @secure
   */
  listCustomerSocialPackagesTyped: <ResultType extends GenMainResultType>(
    baseURL: string,
    query: {
      /** тип возвращаемого значения -- список либо каунтер */
      resultType?: ResultType | null;
      /** Фильтр по статусам соцпакета */
      status?: GenMainSocialPackageStatus[] | null;
    },
    signal?: AbortSignal,
  ) =>
    createRequest<ResultType extends GenMainResultType.Count ? GenMainCounter[] : GenMainRzdSocialPackage[], void>({
      baseURL,
      path: `/customers/current/social-packages`,
      method: 'GET',
      query: query,
      format: 'json',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name GetCustomerSocialPackageById
   * @summary Получение соцПакета по id
   * @request GET:/customers/current/social-packages/{socialPackageId}
   * @secure
   */
  getCustomerSocialPackageById: (baseURL: string, socialPackageId: GenMainSocialPackageId, signal?: AbortSignal) =>
    createRequest<GenMainRzdSocialPackage, void>({
      baseURL,
      path: `/customers/current/social-packages/${socialPackageId}`,
      method: 'GET',
      signal,
    }),

  /**
   * No description
   * * @tags Customers
   * @name HandleCustomerSocialPackageCommand
   * @summary Допустимые для пользователя действия по отношению к СВОЕМУ соц пакету
   * @request POST:/customers/current/social-packages/{socialPackageId}
   * @secure
   */
  handleCustomerSocialPackageCommand: (
    baseURL: string,
    socialPackageId: GenMainSocialPackageId,
    data: GenMainSocialPackageEmployeeAction,
    signal?: AbortSignal,
  ) =>
    createRequest<GenMainRzdSocialPackage, GenMainSocialPackageEmployeeAction>({
      baseURL,
      path: `/customers/current/social-packages/${socialPackageId}`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      signal,
    }),
};
