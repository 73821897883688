import { AccountBalance } from 'domain/model';
import { EBalanceType } from 'domain/model/enums';
import { FC } from 'react';
import { UserFinanceBalanceBonusesAdapter } from './balanceBonuses';
import { UserFinanceBalanceCspAdapter } from './balanceCsp';

type UserBalanceAdapterProps = {
  readonly balance: AccountBalance;
};

export const UserFinanceBalanceAdapter: FC<UserBalanceAdapterProps> = ({ balance }) => {
  switch (balance.type) {
    case EBalanceType.Bonuses:
    case EBalanceType.ASP:
      return <UserFinanceBalanceBonusesAdapter balance={balance} />;
    case EBalanceType.CSP:
      return <UserFinanceBalanceCspAdapter balance={balance} />;
  }
};
