import { Location } from 'history';
import { v4 as uuidv4 } from 'uuid';

export const rootRouting = {
  root: '/',
  tradeOffer: '/trade-offers',
  aspOffer: '/asp-offers',
  cspOffer: '/csp-offers',
  corpOffer: '/corp-offers',
  bookingOffer: '/booking-offers',
  partner: '/partners',
  user: '/user',
  about: '/about',
  legal: '/legal',
  payment: '/payment',
  cart: '/cart',
  product: '/products',
  data: '/data',
  cms: '/cms',
  search: '/search',
};

type AddStateToLocationProps<S> = {
  readonly location: Location;
  readonly state?: S;
};

type AddSearchParamToLocationProps = {
  readonly location: Location;
  readonly param: string;
  readonly value: string;
};

type GetHomepageRouteProps = {
  readonly guid?: Nullable<UUID>;
};

export type HomepageLocationState = {
  readonly guid: UUID;
};

export const getHomepageRoute = (props?: GetHomepageRouteProps): Location<HomepageLocationState> => {
  const newGuid = props?.guid ?? uuidv4();

  return {
    pathname: rootRouting.root,
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const addStateToLocation = <S>(props: AddStateToLocationProps<S>): Location => {
  const { location, state } = props;
  const { pathname, search, state: prevState, hash, key } = location;

  const newState = typeof prevState === 'object' ? { ...prevState, ...state } : { ...state };

  return {
    pathname,
    search,
    state: newState,
    hash,
    key,
  };
};

export const addSearchParamToLocation = (props: AddSearchParamToLocationProps): Location => {
  const { location, param, value } = props;

  const newSearchParams = new URLSearchParams(location.search);
  newSearchParams.set(param, value);

  return {
    ...location,
    search: newSearchParams.toString(),
  };
};

export default rootRouting;
