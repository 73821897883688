import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import ErrorHandler from 'data/network/errorHandler';
import apiDefinition from 'data/openApi';
import { BookingOffersListCategoryId, BookingOffersListServicesProps } from 'data/openApi/booking';
import { BookingOffersRequestCompensation, BookingOffersRequestServices } from 'data/openApi/user/bookingOffer';
import { AppThunkAPIConfig } from 'data/store/store';
import { PriceRangeFilter } from 'domain/model/offer';
import { bookingOffersDefaultParams } from '../../utils';
import { BookingOfferListFilterData, EBookingOfferListRequestType } from './types';
import { getRequestTypes } from './utils';

export type BookingOfferListFetchProps = Pick<BookingOffersListServicesProps, 'query'> &
  Pick<BookingOfferListFilterData, 'requestType' | 'priceRangeRequested'> &
  BookingOffersListCategoryId &
  Partial<PriceRangeFilter> &
  BookingOffersRequestServices &
  BookingOffersRequestCompensation;

// TODO убрать обратно бизнес-логику отсюда из из слайсов в хуки useBookingOfferList, оставив запросы createAsyncThunk
/** Входная точка для выполнения набора зависимых запросов фильтра */
export const bookingOfferListFilterFetch = createAsyncThunk<
  BookingOfferListFilterData,
  BookingOfferListFetchProps,
  AppThunkAPIConfig
>(
  'booking/fetch',
  async (
    {
      requestType,
      rzdSocialPackage: rzdSocialPackageProp,
      query,
      minPrice,
      maxPrice,
      services: argsServices,
      categoryId,
    },
    { rejectWithValue }
  ) => {
    try {
      const { isAll, isReset, isServices, isPrice, isCompensation } = getRequestTypes(
        requestType as EBookingOfferListRequestType
      );

      const selectedServices = argsServices;
      const leavingServiceId = null;
      let priceRange = bookingOffersDefaultParams.priceRange;
      const rzdSocialPackage = rzdSocialPackageProp || bookingOffersDefaultParams.rzdSocialPackage;
      let priceRangeRequested = null;
      let services = null;
      let allServices = null;

      // первичный вход в категорию - получение доступных категорий услуг в ней
      if (isAll) {
        /*let servicesData;*/

        const { data: categoriesFiltered } = await apiDefinition.booking.serviceCategories({
          categoryId,
          minPrice: minPrice ?? null,
          maxPrice: maxPrice ?? null,
          query,
          isRzdSocialPackage: rzdSocialPackage,
        });

        // доступные категории с фильтром или без него
        services = categoriesFiltered;

        if (typeof minPrice !== 'number' && typeof maxPrice !== 'number' && !rzdSocialPackage) {
          allServices = categoriesFiltered;
          /*servicesData = categoriesFiltered;*/
          // все доступные категории при условии перехода по ссылке извне с установленным фильтром цен
        } else {
          const { data: categoriesFull } = await apiDefinition.booking.serviceCategories({
            categoryId,
            minPrice: null,
            maxPrice: null,
            query,
          });

          allServices = categoriesFull;
          /*servicesData = categoriesFull;*/
        }

        // Проверка на категорию Проживание и возврат её для установки в фильтр по-умолчанию
        // логика принудительного выделения категории Проживание не потребовалась
        /*const leavingServiceFound = servicesData?.find(({ displayPrice }) => displayPrice);
        if (leavingServiceFound && (argsServices === null || leavingServiceFound.id === argsServices?.[0])) {
          selectedServices = [leavingServiceFound.id];
          leavingServiceId = leavingServiceFound.id;
        }*/
      }

      // Запрос диапазона цен в случае первичного входа или при ручном изменении категорий услуг или КСП
      if (isAll || isReset || isServices || isCompensation) {
        const { data } = await apiDefinition.booking.priceRange({
          categoryId,
          // В случае уже установленных в аргументах фильтрах используем их, а не категорию Проживания
          services: argsServices || selectedServices,
          query,
          isRzdSocialPackage: rzdSocialPackage,
        });

        priceRange = data;
      }

      // Запрос услуг при ручном изменении диапазона цен или услуг или КСП
      // Либо повторный запрос при наличии категории Проживание и после готовности запроса диапазона для актуализации фильтра
      if (isPrice || isServices || isCompensation || isReset || (isAll && leavingServiceId)) {
        const { data } = await apiDefinition.booking.serviceCategories({
          categoryId,
          minPrice: (isPrice || isServices ? minPrice : priceRange.minPrice || null) ?? null,
          maxPrice: (isPrice || isServices ? maxPrice : priceRange.maxPrice || null) ?? null,
          query,
          isRzdSocialPackage: rzdSocialPackage,
        });

        services = data;
      }

      if (isAll && minPrice !== null && maxPrice !== null) {
        priceRangeRequested = { minPrice, maxPrice } as PriceRangeFilter;
      }

      return {
        services,
        allServices,
        leavingServiceId,
        priceRange,
        requestType,
        priceRangeRequested,
        rzdSocialPackage,
      };
    } catch (e) {
      const error = e as AxiosError;
      ErrorHandler.handleHttpErrorLegacy(error, error.response);
      return rejectWithValue(error.response?.data);
    }
  }
);
