import { useGetAspOfferDetailsQuery } from 'data/api/aspOffer';
import { EBalanceType, EOfferType, OfferPromotionType } from 'domain/model/enums';
import moment from 'moment-timezone';
import { normalizedLink } from 'presentation/utils';
import React, { FCC, useCallback, useEffect } from 'react';
import useOfferActivations from '../../../hooks/useOfferActivations';
import useOfferSubscription from '../../../hooks/useOfferSubscription';
import useTakeOfferActivations from '../../../hooks/useTakeOfferActivations';
import { isOfferReceiptDateSupportedByPromotionType } from '../../../utils';
import { getAspOfferCodeAssignDurationInSeconds } from '../../utils';
import AspOfferClaimContext, { AspOfferClaimContextType } from './context';

type AspOfferClaimProviderProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly promotionType: OfferPromotionType;
  readonly isActivationAllowed?: boolean;
  readonly onPartnerButtonClick: () => void;
};

const AspOfferClaimProvider: FCC<AspOfferClaimProviderProps> = props => {
  const { children, id, promotionType, onPartnerButtonClick, guid, isActivationAllowed = true } = props;

  const [timerActive, setTimerActive] = React.useState<boolean>(false);

  const { data: aspOffer = null, refetch: aspOfferRefetch } = useGetAspOfferDetailsQuery({ id });

  const { onTakeActivation, needCopy, isTaked, onNeedCopy, isTaking, onCopy } = useTakeOfferActivations();
  const activation = useOfferActivations({
    offerId: id,
    offerRefetch: aspOfferRefetch,
    balanceType: EBalanceType.ASP,
    isActivationAllowed,
    onTakeActivation,
  });

  const { lastActivation, activationError, isLoadActivationsFetching, lastReceivedActivation } = activation;

  const {
    isEnabled: isSubscriptionEnabled,
    isFetching: isSubscriptionFetching,
    isSupported: isSubscriptionSupported,
    onSubscribe,
    onUnSubscribe,
  } = useOfferSubscription({
    offer: aspOffer,
    offerType: EOfferType.TradeOffer,
    promotionType,
  });

  const isReceiptDateSupported = isOfferReceiptDateSupportedByPromotionType(promotionType);

  const isActivationAvailable = activation.isActivationAvailable;
  const partnerLink = aspOffer?.partnerLink ? normalizedLink(aspOffer.partnerLink) : '';
  const endDate = aspOffer ? moment(aspOffer.endDate).endOf('day') : null;

  const isFetching = isLoadActivationsFetching;
  const isActivationReceivedNow = !!lastReceivedActivation;

  const onActivate = useCallback(async () => {
    if (!aspOffer) {
      return null;
    }
    activation.userActivationAttempt(aspOffer);
    return await activation.activateOffer(aspOffer, { needConfirmPhone: true });
  }, [aspOffer, activation]);

  const stopTimer = useCallback(() => {
    setTimerActive(false);
  }, [setTimerActive]);

  useEffect(() => {
    const duration = lastActivation?.appointmentDate
      ? getAspOfferCodeAssignDurationInSeconds(lastActivation?.appointmentDate)
      : 0;
    setTimerActive(duration > 0);
  }, [lastActivation?.appointmentDate, activationError]);

  const value: AspOfferClaimContextType = {
    aspOffer,
    partnerLink,
    endDate,
    activation,
    isFetching,
    guid,
    isSubscriptionEnabled,
    isSubscriptionFetching,
    isSubscriptionSupported,
    isReceiptDateSupported,
    isActivationAvailable,
    isActivationReceivedNow,
    isTimerActive: timerActive,
    stopTimer,
    onTakeActivation,
    onActivate,
    onSubscribe,
    onUnSubscribe,
    onPartnerButtonClick,
    onCopy,
    isTaked,
    needCopy,
    onNeedCopy,
    isTaking,
  };

  return <AspOfferClaimContext.Provider value={value}>{children}</AspOfferClaimContext.Provider>;
};

export default AspOfferClaimProvider;
