import { TradeOfferShort } from 'domain/model/tradeOffer';
import { LogoImageView } from 'presentation/components/common/images/cover';
import { CardCaption } from 'presentation/features/offer/components/listItem/controls';
import { InfoWrapper, LogoWrapper } from 'presentation/features/offer/trade/components/listItem/controls';

type TradeOfferListItemInfoProps = Pick<TradeOfferShort, 'partner'>;

const TradeOfferListItemInfo = (props: TradeOfferListItemInfoProps) => {
  const { partner } = props;

  return (
    partner && (
      <InfoWrapper>
        <CardCaption
          variant='body2'
          color='textSecondary'
        >
          {partner.name}
        </CardCaption>
        {partner.logo && (
          <LogoWrapper>
            <LogoImageView id={partner.logo.path ?? null} />
          </LogoWrapper>
        )}
      </InfoWrapper>
    )
  );
};

export default TradeOfferListItemInfo;
