import { Text } from '../../components/controls';
import { List, ListItem } from 'presentation/features/about/components/controls';
const AboutCspWhatSizeCostInfo = () => {
  return (
    <>
      <Text>
        <List>
          <ListItem>Если Ваша должность категории «рабочий» — 48 300 руб.</ListItem>
          <ListItem>Если Ваша должность категории «специалисты и служащие» — 41 400 руб.</ListItem>
          <ListItem>Если Ваша должность категории «главные, ведущие специалисты» — 34 500 руб.</ListItem>
          <ListItem>
            Если Ваша должность категории «руководители (начальник, заместитель начальника, главный эксперт, ведущий
            эксперт, эксперт, другие)» — 27 600 руб.
          </ListItem>
          <ListItem>Стоимость компенсации на 1 члена семьи составляет — 15 180 руб.</ListItem>
        </List>
        Данные обновляются каждый год.
      </Text>
    </>
  );
};

export default AboutCspWhatSizeCostInfo;
