import { Text } from '../../components/controls';

const AboutCspHaveGettingInfo = () => {
  return (
    <>
      <Text>
        Нет. В течение года работник может получить КСП только одного вида (в рамках одной значимой категории).
      </Text>
    </>
  );
};

export default AboutCspHaveGettingInfo;
