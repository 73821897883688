import { Text } from '../../components/controls';
import { List, ListItem } from 'presentation/features/about/components/controls';

const AboutCspWhatNeedDoInfo = () => {
  return (
    <>
      <Text>
        <List as={'ol'}>
          <ListItem>
            Подать заявление на выделение путёвки в комиссию по распределению путёвок вашего подразделения;
          </ListItem>
          <ListItem>Дождаться решения комиссии по распределению путёвок;</ListItem>
          <ListItem>Если решение положительное: приобрести путёвку на отдых;</ListItem>
          <ListItem>Отдохнуть;</ListItem>
          <ListItem>
            Предоставить ответственному специалисту за предоставление КСП в вашем подразделении документы,
            подтверждающие получение услуги;
          </ListItem>
          <ListItem>Ожидать выплату в зарплату.</ListItem>
        </List>
      </Text>
    </>
  );
};

export default AboutCspWhatNeedDoInfo;
