import { paginationSizeVariant } from 'domain/model/constants';
import { EOfferType } from 'domain/model/enums';
import { ProductOfferShort } from 'domain/model/productOffer';
import { ECatalogUrlParam } from 'presentation/features/catalog/types';
import { getOffersListQueryParams } from 'presentation/features/offer/utils';

export enum EProductOfferFavoritePosition {
  Preview = 'preview',
  Details = 'details',
}

export enum EProductOfferUrlParam {
  Tab = 'tab',
  Category = ECatalogUrlParam.Category,
}

export const formatToSale = (n: number) => `-${n}%`;

export const getProductDisplayName = (productOffer: ProductOfferShort) => {
  const { name, variantName, attributes } = productOffer;

  let displayName = name;
  if (variantName) {
    displayName = displayName.concat(`, ${variantName}`);
  } else if (attributes) {
    const attributesStr = attributes
      .slice(0, 2)
      .reduce((acc, current) => {
        return current?.values?.[0]?.value ? [...acc, current?.values?.[0]?.value] : acc;
      }, [] as string[])
      .join(', ');

    displayName = displayName.concat(`, ${attributesStr}`);
  }

  return displayName;
};

export const productOffersDefaultParams = getOffersListQueryParams(
  {
    name: null,
    categories: null,
    page: 1,
    pageSize: paginationSizeVariant[0],
    sort: null,
  },
  EOfferType.ProductOffer
);
