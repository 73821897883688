import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { Category } from 'domain/model';
import { createCancelToken } from './utils';
import { ApiCancellable } from './types';

export type CategoriesProps = ApiCancellable & {
  readonly ids?: UUID[];
};

export type CategoryProps = ApiCancellable & {
  readonly id: UUID;
};

type TradeOfferApi = {
  readonly categories: (props: CategoriesProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, Category>>;
  readonly category: (props: CategoryProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, Category[]>>;
};

/**
 * АПИ по работе с торговыми предложениями
 */
const tradeOffer: TradeOfferApi = {
  categories: ({ signal, ids }) => {
    const params = new URLSearchParams();

    ids?.forEach(id => params.append('category', id));

    return {
      url: '/trade-offer-categories',
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
  category: ({ signal, id }) => {
    return {
      url: `/trade-offer-categories/${id}`,
      method: 'GET',
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
};

export default tradeOffer;
