import { Text } from '../../components/controls';
const AboutCspHowCanILoseInfo = () => {
  return (
    <>
      <Text>
        КСП можно потерять при увольнении, переводе на другую должность, не относящуюся к категории работников,
        получающих КСП, или при выявлении нарушений, связанных с предоставлением КСП.
      </Text>
    </>
  );
};

export default AboutCspHowCanILoseInfo;
