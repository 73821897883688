import { List, ListItem, Text } from '../../components/controls';

const AboutCspWhenSubmitInfo = () => {
  return (
    <Text>
      <List>
        <ListItem>
          Заявление на льготные санаторно-курортные путевки на объекты раздела «________» на следующий год формируются
          ежегодно в августе-сентябре. Заявку можно подать написав заявление в комиссию по распределению путёвок.
        </ListItem>
      </List>
    </Text>
  );
};

export default AboutCspWhenSubmitInfo;
