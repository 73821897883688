import { Text } from '../../components/controls';

const AboutCspCanReceivingKSPInfo = () => {
  return (
    <>
      <Text>
        КСП не влияет на размер государственных льгот, однако, считается доходом. Если вы получаете выплаты от
        государства, зависящие от вашего материального положения, они могут быть остановлены, если вы получите КСП.
      </Text>
      <Text>
        Перед тем как согласиться на получение КСП проконсультируйтесь с кадровиком или специалистом, ответственным за
        предоставление КСП в вашем подразделении.
      </Text>
    </>
  );
};

export default AboutCspCanReceivingKSPInfo;
