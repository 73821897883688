import { Divider, Typography, useTheme } from '@mui/material';
import { EOrderDiscriminator, EOrderStatus } from 'domain/model';
import Splitter from 'presentation/components/common/splitter';
import { getBookingOffersDetailsRoute } from 'presentation/features/offer/booking/routes';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { phoneFormatView } from 'presentation/utils/phone';
import { FC } from 'react';
import { getPersonShortName } from 'presentationUtils/index';
import { toRubCurrency } from 'presentationUtils/currency';
import { EBookingOfferDetailsTab } from '../../../../../../offer/booking/details/types';
import { DetailsWrapper } from '../../../components/details/controls';
import OrderImage from '../../../components/image';
import OrderField from '../../../components/orderField';
import BookingOrderItem from '../../components/orderItem';
import { BookingOrderProps } from '../../types';

const notConfirmedStatus = [EOrderStatus.New, EOrderStatus.Given];

const BookingOfferOrderDetailsAdapter: FC<BookingOrderProps> = ({ order }) => {
  const theme = useTheme();

  if (!order || order?.discriminator !== EOrderDiscriminator.BookingOrder) {
    return null;
  }

  const { customer, id, offer, orderItems, status } = order;

  const cost = toRubCurrency(order.cost);
  const isNotConfirmedStatus = notConfirmedStatus.includes(status);

  return (
    <DetailsWrapper>
      <Typography fontWeight={theme.typography.fontWeightBold}>{offer?.name ?? ''}</Typography>
      <Splitter size={1.25} />
      {offer?.images?.[0] && id && (
        <>
          <OrderImage
            to={getBookingOffersDetailsRoute({
              id: offer?.id,
              tab: EBookingOfferDetailsTab.Description,
            })}
            fileLink={offer?.images?.[0]}
          />
          <Splitter size={1.25} />
        </>
      )}
      {customer && (
        <OrderField
          label='Получатель'
          text={getPersonShortName(customer)}
        />
      )}
      {customer?.phone && (
        <>
          <Splitter size={2} />
          <OrderField
            label='Телефон'
            text={phoneFormatView(customer.phone)}
          />
        </>
      )}
      <Splitter size={3} />
      <Divider />
      <Splitter size={1} />
      {orderItems?.map(item => (
        <BookingOrderItem
          key={item.id}
          {...item}
        />
      ))}
      <Splitter size={2} />
      <MPGrid
        container
        justifyContent='space-between'
        alignItems='center'
      >
        <MPGrid item>
          <Typography variant='h3'>{isNotConfirmedStatus ? 'Предварительная сумма' : 'Сумма'}:</Typography>
        </MPGrid>
        <MPGrid item>
          <Typography variant='h3'>{cost}</Typography>
        </MPGrid>
      </MPGrid>
    </DetailsWrapper>
  );
};

export default BookingOfferOrderDetailsAdapter;
